import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import prevettedsj from "../../Services/prevettedGrantees";
import { toast } from "react-toastify";

const PrevettedUpdateModal = (props) => {
  const [Isloading, setIsLoading] = useState({
    visible: false,
    type: "",
  });
  const [eventModal, setEventModal] = useState(false);
  const [eventId, setEventId] = useState("");
  const [modalData, setModalData] = useState({});
  

  let prevettedUser = props.prevettedUser;
  let prevettedId = props.sjgrantee?.update_request_data[0]?.id;

  const showModalEvent = (eventId, actionType) => {
    setEventId(eventId);
    setEventModal(true);
  };

  const handleApprove = (action) => {
    let param = {};
    param["id"] = prevettedId;
    param["status"] = action;
    setIsLoading({
      visible: true,
      type: action,
    });
    prevettedsj
      .approveUser(param)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleClose();
          props.getPrevettedGrantees();
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={props.openUpateDetail}
        size={eventId ? "md" : "lg"}
        className="user_modal"
      >
        <ModalHeader toggle={() => props.handleClose()}>
          {eventId
            ? `Are you sure you want to ${eventId == 1 ? "Reject" :"Approve"} the updates request?`
            : "Prevetted Grantee Updates Confirmation"}
        </ModalHeader>
        {props.loader ? (
          <Spinner />
        ) : (
          <>
            {eventId ? (
              ""
            ) : (
              <ModalBody className="pl-4 pr-4">
                <div className="px-2 pt-2">
                  <div className="d-flex justify-content-around mb-2">
                    <strong scope="col">Current Data</strong>
                    <strong scope="col">Update Request Data</strong>
                  </div>
                  <table class="table table-striped table-bordered">
                    <tbody>
                      {prevettedUser?.updated_data.org_name && (
                        <tr>
                          <td>
                            <strong>Organization Name: </strong>
                            <br />
                            {prevettedUser?.current_data.org_name || "N/A"}
                          </td>
                          <td>
                            <strong>Organization Name: </strong>
                            <br />
                            {prevettedUser?.updated_data.org_name || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.ein_number && (
                        <tr>
                          <td>
                            <strong>EIN: </strong>
                            <br />
                            {prevettedUser?.current_data.ein_number || "N/A"}
                          </td>
                          <td>
                            <strong>EIN: </strong>
                            <br />
                            {prevettedUser?.updated_data.ein_number || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.mission && (
                        <tr>
                          <td>
                            <strong>Mission: </strong>
                            <br />
                            {prevettedUser?.current_data.mission || "N/A"}
                          </td>
                          <td>
                            <strong>Mission: </strong>
                            <br />
                            {prevettedUser?.updated_data.mission || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.street && (
                        <tr>
                          <td>
                            <strong>Street: </strong>
                            <br />
                            {prevettedUser?.current_data.street || "N/A"}
                          </td>
                          <td>
                            <strong>Street: </strong>
                            <br />
                            {prevettedUser?.updated_data.street || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.city && (
                        <tr>
                          <td>
                            <strong>City: </strong>
                            <br />
                            {prevettedUser?.current_data.city || "N/A"}
                          </td>
                          <td>
                            <strong>City: </strong>
                            <br />
                            {prevettedUser?.updated_data.city || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.state && (
                        <tr>
                          <td>
                            <strong>State: </strong>
                            <br />
                            {prevettedUser?.current_data.state || "N/A"}
                          </td>
                          <td>
                            <strong>State: </strong>
                            <br />
                            {prevettedUser?.updated_data.state || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.zip && (
                        <tr>
                          <td>
                            <strong>Zip: </strong>
                            <br />
                            {prevettedUser?.current_data.zip || "N/A"}
                          </td>
                          <td>
                            <strong>Zip: </strong>
                            <br />
                            {prevettedUser?.updated_data.zip || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.priority_population && (
                        <tr>
                          <td>
                            <strong>Priority Population: </strong> <br />
                            {prevettedUser?.current_data.priority_population || "N/A"}
                          </td>
                          <td>
                            <strong>Priority Population: </strong> <br />
                            {prevettedUser?.updated_data.priority_population || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.org_contact_name && (
                        <tr>
                          <td>
                            <strong>Org Contact Name: </strong> <br />
                            {prevettedUser?.current_data.org_contact_name || "N/A"}
                          </td>
                          <td>
                            <strong>Org Contact Name: </strong> <br />
                            {prevettedUser?.updated_data.org_contact_name} || "N/A"
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.org_contact_email && (
                        <tr>
                          <td>
                            <strong>Org Contact Email: </strong> <br />
                            {prevettedUser?.current_data.org_contact_email || "N/A"}
                          </td>
                          <td>
                            <strong>Org Contact Email: </strong> <br />
                            {prevettedUser?.updated_data.org_contact_email || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.geography && (
                        <tr>
                          <td>
                            <strong>Geography: </strong>
                            <br />
                            {prevettedUser?.current_data.geography || "N/A"}
                          </td>
                          <td>
                            <strong>Geography: </strong> <br />
                            {prevettedUser?.updated_data.geography || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.categories && (
                        <tr>
                          <td>
                            <strong>Prevetted Categories: </strong> <br />
                            {prevettedUser?.current_data.categories || "N/A"}
                          </td>
                          <td>
                            <strong>Prevetted Categories: </strong>
                            <br />
                            {prevettedUser?.updated_data.categories || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.org_website && (
                        <tr>
                          <td>
                            <strong>Organization Website: </strong> <br />
                            {prevettedUser?.current_data.org_website || "N/A"}
                          </td>
                          <td>
                            <strong>Organization Website: </strong>
                            <br />
                            {prevettedUser?.updated_data.org_website || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.logo && (
                        <tr>
                          <td>
                            <strong>Logo: </strong> <br />
                            <img
                              src={prevettedUser?.current_data.logo || "N/A"}
                              style={{ maxWidth: "75px" }}
                              className="img-thumbnail"
                            />
                          </td>
                          <td>
                            <strong>Logo: </strong> <br />
                            <img
                              src={prevettedUser?.updated_data.logo || "N/A"}
                              style={{ maxWidth: "75px" }}
                              className="img-thumbnail"
                            />
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.vendor_name && (
                        <tr>
                          <td>
                            <strong>Vendor Name: </strong>
                            <br />
                            {prevettedUser?.current_data.vendor_name || "N/A"}
                          </td>
                          <td>
                            <strong>Vendor Name: </strong>
                            <br />
                            {prevettedUser?.updated_data.vendor_name || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.account_number && (
                        <tr>
                          <td>
                            <strong>Account Number: </strong>
                            <br />
                            {prevettedUser?.current_data.account_number || "N/A"}
                          </td>
                          <td>
                            <strong>Account Number: </strong>
                            <br />
                            {prevettedUser?.updated_data.account_number || "N/A"}
                          </td>
                        </tr>
                      )}
                      {prevettedUser?.updated_data.payment_remarks && (
                        <tr>
                          <td>
                            <strong>Additional payment instructions : </strong>
                            <br />
                            {prevettedUser?.current_data.payment_remarks || "N/A"} 
                          </td>
                          <td>
                            <strong>Additional payment instructions : </strong>
                            <br />
                            {prevettedUser?.updated_data.payment_remarks || "N/A"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </ModalBody>
            )}
          </>
        )}

        <ModalFooter>
          {eventId ? (
            <>

              <Button
                onClick={() => props.handleClose()}
                size="lg"
                disabled={Isloading?.visible}
              >
                Cancel
                {Isloading?.visible && Isloading?.type === "CANCELLED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button>

              <Button
                color={eventId ==1 ? "danger":"success"}
                onClick={() => handleApprove(eventId === 1 ? "REJECTED" : "APPROVED")}
                size="lg"
                disabled={Isloading?.visible}
              >
                {eventId ===1 ? "Reject" : "Approve"}
                {Isloading?.visible && Isloading?.type === "REJECTED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button>

              {/* <Button
                onClick={() => handleApprove("APPROVED")}
                size="lg"
                color="success"
                disabled={Isloading?.visible}
              >
                Yes
                {Isloading?.visible && Isloading?.type === "APPROVED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button> */}


            </>
          ) : (
            <>
              {" "}
              <Button
                onClick={() => props.handleClose()}
                size="lg"
                disabled={Isloading?.visible}
              >
                Cancel
                {Isloading?.visible && Isloading?.type === "CANCELLED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button>
              <Button
                color="danger"
                onClick={() => showModalEvent(1)}
                size="lg"
                disabled={Isloading?.visible}
              >
                Reject
                {Isloading?.visible && Isloading?.type === "REJECTED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button>
              <Button
                // onClick={() => handleApprove("APPROVED")}
                onClick={() => showModalEvent(2)}
                size="lg"
                color="success"
                disabled={Isloading?.visible}
              >
                Approve
                {Isloading?.visible && Isloading?.type === "APPROVED" ? (
                  <Spinner size="sm" className="mx-1" />
                ) : (
                  ""
                )}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PrevettedUpdateModal;
