import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button, Card, CardBody } from "reactstrap";
import Search from "../Search";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { BsSearch } from "react-icons/bs";
import Grantee from "./Grantee";
import grantee from "../../Services/grantee";
import Export from "../Export";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const [grantees, setGrantees] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [loader, setLoader] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [sort, setSort] = useState({});
  const [searchOpen, setsearchOpen] = useState(false);
  const [exportFields, setExportFields] = useState({});
  const [searchFields, setSearchFields] = useState([
    { label: "Email", name: "email", type: "text", col: 4 },
    { label: "First Name", name: "first_name", type: "text", col: 4 },
  ]);

  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };
  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.selected + 1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`,
    });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getGrantees = (fields = {}) => {
    setLoader(true);
    let params;
    if (Object.keys(searchValue).length > 0) {
      params = {
        ...fields,
        ...searchValue,
        ...sort,
        page,
      };
    } else {
      params = { ...fields, ...sort, page };
    }
    grantee
      .list(params)
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.data.length < 1) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
          setGrantees(res.data.data.data);
          if (!searchValue) {
            setPage(res.data.data.current_page);
          }
          setTotalPages(res.data.data.total / res.data.data.per_page);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };

  const deleteGrantee = (id) => {
    if (window.confirm("Are you sure to delete this Grantee?")) {
      grantee.Delete(id).then((res) => {
        if (res.data.status) {
          const newGrantee = grantees.filter(
            (item) => parseInt(item.id) !== parseInt(id)
          );
          setGrantees(newGrantee);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const getSearchFields = (fields = {}) => {
    setExportFields(fields);
    if (
      Object.keys(searchValue).length === 0 &&
      Object.keys(fields).length === 0
    ) {
      getGrantees(fields);
    } else if (page == 1) {
      getGrantees(fields);
    } else {
      setPage(1);
    }
    setTotalPages(totalPages / 10);
  };

  useEffect(() => {
    getGrantees(page, sort);
  }, [page, sort]);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Grantees : seeding justice</title>
      </Helmet>
      <Row>
        <Col lg={8} md={8} sm={12}>
          <h4 className="mb-0 title-font">Grantees</h4>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <p className="mb-0">Grantees</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col lg={4} md={4} sm={12} className="text-end">
          <Row>
            <Col sm={12} md={6}>
              <Export
                exportExcel={true}
                service={grantee}
                fileName={"Grantee"}
                isdisabled={isdisabled}
                fields={exportFields}
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                className="mt-2 mt-md-0 search_button"
                size="md"
                block
                style={{ borderRadius: "30px" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Search
        isOpen={searchOpen}
        exportExcel={true}
        service={grantee}
        fileName={"Grantee"}
        searchFields={searchFields}
        heading="Search Grantees"
        onSubmit={getSearchFields}
        // setSearchParams={setSearchParams}
        setFields={setSearchValue}
        fields={searchValue}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <LoadingOverlay
                active={loader}
                spinner={
                  <Spinner
                    style={{ height: 60, width: 60, color: "#00344B" }}
                  />
                }
                fadeSpeed={100}
                classNamePrefix="mitiz"
              >
                <Table responsive className="newThemeTable">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) =>
                            sortRecord(event, "users.first_name")
                          }
                        >
                          Donor Full Name
                        </span>
                      </th>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "users.email")}
                        >
                          Email
                        </span>
                      </th>

                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) =>
                            sortRecord(event, "grantee_approval_date")
                          }
                        >
                          Approval Date
                        </span>
                      </th>
                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: 350 }}>
                    {grantees.length > 0
                      ? grantees.map((grant, index) => (
                          <Grantee
                            grant={grant}
                            key={`key-grant-${index}`}
                            deleteGrantee={deleteGrantee}
                          />
                        ))
                      : !loader && (
                          <tr>
                            <td key={0} colSpan="4">
                              <p className="text-center">Record not found.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </LoadingOverlay>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <tr style={{ display: "flex" }}>
        <td colSpan="4" style={{ margin: "auto" }}>
          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </td>
      </tr>
    </div>
  );
};

export default Index;
