import { CardBody, Col, Row } from "reactstrap";
import Sidebar from "./Sidebar";

const SuccessNomination = () => {
  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
            <div
              className="mt-4 mb-5 userAdminArticle"
              style={{ minHeight: "350px" }}
            >
            <div className="py-3 userAdminArticleHeading">
              <h4 className="mb-0">Recommend Grants</h4>
            </div>
            <CardBody
              style={{ height: 400 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Row className="text-center">
                <Col md={12}>
                  <div className="wrapper">
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <h2>Thank you for submitting your Recommend Grant's form!</h2>
                  <p>
                    You will receive email updates from us throughout this
                    process. If you have any questions please reach out to{" "}
                    <a href="mailto:XYZ@seedingjustice.com" style={{ color: '#e26a38' }}>
                      XYZ@seedingjustice.com
                    </a>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SuccessNomination;
