import moment from "moment";
import React, { Fragment, useState } from "react";
import common from "../../Services/common";
import ConfirmationModal from "./ConfirmationModal";
import MarkAsGrantee from "./MarkAsGrantee";
import { FcOk } from "react-icons/fc";
import { Link } from "react-router-dom";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import { BsCircle, BsXCircleFill } from "react-icons/bs";
import { AiFillCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import BoardApprovedModal from "./BoardApprovedModal";
import { Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";

const Nomination = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [eventId, setEventId] = useState("");
  const [nominationId, setNominationId] = useState("");
  const [modalData, setModalData] = useState({});
  const [granteeOpen, setGranteeOpen] = useState(false);

  const showModalEvent = (eventId, actionType) => {
    if (actionType === 2) {
      setModalData({
        modelText: "Are you sure you want to perform this operation?",
        action: "grant_yes_no_email",
        modalLabel: "Grant Email",
      });
    } else {
      setModalData({
        modelText: "Has the NGO Contract been sent?",
        action: "ngo_contract",
        modalLabel: "Ngo Contract",
        buttonNo: "NO",
        buttonYes: "YES",
      });
    }
    setEventId(eventId);
    setEventModal(true);
  };

  const boardApprovalEvent = (nominationId, actionType) => {
    if (actionType === 1) {
      setModalData({
        modelText: "Are you sure you want to mark the Board Approval?",
        action: "is_board_approved",
        modalLabel: "Board Approved",
        buttonNo: "NO",
        buttonYes: "YES",
      });
      setNominationId(nominationId);
      setShowModal(true);
    }
  };

  const GranteeModal = (grantee_id) => {
    setGranteeOpen(true);
    setNominationId(grantee_id);
  };
  const nomin = props.nomin;

  return (
    <>
      <tr key={nomin.id}>
        <td>
          <Link
            className="text-decoration-none"
            to={""}
            onClick={() => props.openEditModal(nomin?.id)}
          >
            {common.getFullName(nomin?.user)}
          </Link>
        </td>
        <td>{nomin?.user?.email}</td>
        <td>{moment(nomin.created_at).format("D MMM,  YYYY")}</td>
        <td>
          {nomin.confirmation_email === 1 ? (
             <AiFillCheckCircle style={{color:"#03CD84"}} size={25} />
          ) : (
            <BsCircle size={25} />
          )}
        </td>

        <td>
          {nomin.is_board_approved === 1 ? (
             <AiFillCheckCircle style={{color:"#03CD84"}} size={25} />
          ) : nomin.is_board_approved === 2 ? (
            <AiOutlineCloseCircle className="text-danger" size={25} />
          ) : (
            <BsCircle
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => boardApprovalEvent(nomin.id, 1)}
            />
          )}
        </td>

        <td>
          {nomin.is_board_approved > 0 ? (
            <>
              {nomin.grant_yes_no_email === 1 ? (
                 <AiFillCheckCircle style={{color:"#03CD84"}} size={25} />
              ) : (
                <BsCircle
                  onClick={() => showModalEvent(nomin.id, 2)}
                  size={25}
                  style={{ cursor: "pointer" }}
                />
              )}
            </>
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>

        <td>
          {nomin.is_board_approved > 0 && nomin.grant_yes_no_email > 0 ? (
            <>
              {nomin.ngo_contract === 1 ? (
                 <AiFillCheckCircle style={{color:"#03CD84",cursor: "pointer" }}
                  size={25}
                 
                  onClick={() => showModalEvent(nomin.id, 3)}
                />
              ) : nomin.ngo_contract === 2 ? (
                <AiOutlineCloseCircle
                  className="text-danger"
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalEvent(nomin.id, 3)}
                />
              ) : (
                <BsCircle
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalEvent(nomin.id, 3)}
                />
              )}
            </>
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>
        <td>
          {nomin.is_board_approved > 0 &&
          nomin.grant_yes_no_email > 0 &&
          nomin.ngo_contract > 0 ? (
            <Fragment>
              {nomin.is_approved_to_grantee === 1 ? (
                 <AiFillCheckCircle style={{color:"#03CD84"}} size={25} />
              ) : (
                <BsCircle
                  onClick={() => GranteeModal(nomin.id)}
                  size={25}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Fragment>
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>
        <td className="text-center">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            color="primary"
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
                <span   onClick={() => props.openEditModal(nomin.id)}>
              <DropdownItem>
                  <FaEye
                    className="me-3 details-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  /> Details
              </DropdownItem>
                </span>
                <span  onClick={() => props.deleteNomination(nomin.id)}>
              <DropdownItem>
                  <BsXCircleFill
                    className="me-3 remove-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  /> Delete
              </DropdownItem>
                </span>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      {eventModal && (
        <ConfirmationModal
          open={eventModal}
          handleClose={() => {
            setEventModal();
            setEventId(null);
          }}
          eventId={eventId}
          modalData={modalData}
          getNominations={props.getNominations}
          emailLayout={props.emailLayout}
          nomin={props.nomin}
        />
      )}
      {showModal && (
        <BoardApprovedModal
          open={showModal}
          handleClose={() => {
            setShowModal();
            setNominationId(null);
          }}
          nominationId={nominationId}
          modalData={modalData}
          getNominations={props.getNominations}
          nomin={props.nomin}
        />
      )}
      {granteeOpen && (
        <MarkAsGrantee
          granteeOpen={granteeOpen}
          handleClose={() => {
            setGranteeOpen();
            setNominationId(null);
          }}
          nominationId={nominationId}
          getNominations={props.getNominations}
          id={nomin.id}
        />
      )}
    </>
  );
};

export default Nomination;
