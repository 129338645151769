import http from "./http";

const emailtemplate = {
  add: (data) => http.post("/admin/email-template", data),
  list: (param) => http.get("/admin/email-template", { params: param }),
  email_layout: (param) => http.get("admin/email-template/layout", { params: param }),
  delete: (param) => http.delete(`/admin/email-template/${param}`),
  getOne: (param) => http.get(`/admin/email-template/${param}`),
  update: (data) => http.patch(`/admin/email-template/${data.id}`, data),
};

export default emailtemplate;
