import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  Table,
  Spinner,
  Button,
  CardBody,
  Nav,
  NavItem,
  Badge,
  TabContent,
  TabPane,
} from "reactstrap";
import Search from "../Search";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { BsSearch } from "react-icons/bs";
import AddEditPrevetted from "../PrevettedSjGrantees/AddEditPrevetted";
import prevettedsj from "../../Services/prevettedGrantees";
import Export from "../Export";
import InvitationModule from "./InvitationModule";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PrevettedUpdateRequest from "./PrevettedUpdateRequest";

const Index = () => {
  const [prevetted, setPrevetted] = useState({});
  const [prevettedUpdate, setPrevettedUpdate] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [prevettedId, setPrevettedId] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});
  const [totalPages, setTotalPages] = useState([]);
  const [totalUpdatePages, setTotalUpdatePages] = useState([]);
  const [searchOpen, setsearchOpen] = useState(false);
  const [tabChange, setTabChange] = useState(true);
  const [exportFields, setExportFields] = useState({});
  const [searchFields, setSearchFields] = useState([
    {
      label: "Organization Name",
      name: "org_name",
      type: "text",
      col: 4,
    },
    { label: "EIN", name: "ein_number", type: "text", col: 4 },
    { label: "Status", name: "status", value: [], type: "select", col: 2 },
  ]);
  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const openEditModal = (prevetted_id) => {
    setOpen(true);
    setPrevettedId(prevetted_id);
  };

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.selected + 1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`,
    });
  };

  const getPrevettedGrantees = (fields = {}) => {
    setLoader(true);
    let params;
    if (Object.keys(searchValue).length > 0) {
      params = {
        ...fields,
        ...searchValue,
        ...sort,
        page,
      };
    } else {
      params = { ...fields, ...sort, page };
    }
    prevettedsj
      .list(params)
      .then(([res, res1]) => {
        if (res?.data?.status) {
          setPrevetted(res?.data?.data?.data);
          setPrevettedUpdate(res1?.data?.data);
          if (!searchValue) {
            setPage(res.data.data.current_page);
          }
          setTotalPages(
            Math.ceil(res?.data?.data?.total / res.data.data.per_page)
          );
          setTotalUpdatePages(Math.ceil(prevettedUpdate[1] / 10));
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const deletePrevetted = (id) => {
    if (window.confirm("Are you sure to delete this prevetted sj grantee?")) {
      prevettedsj.delete(id).then((res) => {
        if (res.data.status) {
          const newUser = prevetted.filter((item) => item.id !== id);
          setPrevetted(newUser);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const getSearchFields = (fields = {}) => {
    setExportFields(fields);
    if (
      (Object.keys(searchValue).length === 0 &&
        Object.keys(fields).length === 0) ||
      page == 1
    ) {
      getPrevettedGrantees(fields);
    } else {
      setPage(1);
    }
    setTotalPages(totalPages / 10);
  };

  const handleTabChange = (action) => {
    setPage(1);
    setSearchValue({});
    if (action === 1) {
      setTabChange(true);
    }
    if (action === 2) {
      setTabChange(false);
    }
  };

  useEffect(() => {
    getPrevettedGrantees(page, sort);
  }, [page, sort]);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Recommended Grantees : seeding justice</title>
      </Helmet>
      <Row>
        <Col lg={6} md={5} sm={12}>
          <h4 className="mb-0 title-font">Recommended Grantees</h4>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <p className="mb-0">Recommended Grantees</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col lg={6} md={7} sm={12} className="text-right">
          <Row>
            <Col sm={12} md={4}>
              <Export
                service={prevettedsj}
                fileName={"RecommendedGrantee"}
                exportExcel={true}
                fields={exportFields}
              />
            </Col>
            <Col sm={12} md={4}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                size="md"
                block
                className="mt-md-0 mt-2 search_button"
                style={{ borderRadius: "30px" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
            <Col sm={12} md={4}>
              <Button
                type="button"
                onClick={() => setOpen(true)}
                size="md"
                block
                className="mt-md-0 mt-2 add_button"
                style={{ borderRadius: "30px" }}
              >
                Add New
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="mt-4">
        <CardBody>
          <div className="defalut-overview">
            <Nav tabs className="border-0">
              <Col md={8} sm={12}>
                <Row className="m-0 nomination_module">
                  <Col
                    md={5}
                    sm={6}
                    xs={6}
                    className={
                      tabChange == 1 ? "active_grant_tab" : "grant_tab"
                    }
                    onClick={() => handleTabChange(1)}
                    style={{
                      marginBottom: tabChange ? "-3px" : 0,
                      width: "280px",
                    }}
                  >
                    <NavItem>
                      <h5>Recommended Grantees</h5>
                    </NavItem>
                  </Col>
                  <Col
                    md={5}
                    sm={6}
                    xs={6}
                    className={
                      tabChange != 1 ? "active_grant_tab" : "grant_tab"
                    }
                    onClick={() => handleTabChange(2)}
                    style={{ marginBottom: tabChange ? 0 : "-3px" }}
                  >
                    <NavItem>
                      <h5>
                        Grantee Updates{" "}
                        <Badge color="danger" className="notification_badge">
                          {prevettedUpdate.total_request}
                        </Badge>{" "}
                      </h5>
                    </NavItem>
                  </Col>
                </Row>
              </Col>
            </Nav>
            <div
              className={
                tabChange == 1
                  ? "customise_table grant_customise_table "
                  : "customise_table border-r6"
              }
            >
              <Search
                isOpen={searchOpen}
                searchFields={searchFields}
                service={prevettedsj}
                fileName={"RecommendedGrantee"}
                exportExcel={true}
                heading="Search Recommended Grantee"
                onSubmit={getSearchFields}
                setFields={setSearchValue}
                fields={searchValue}
              />

              <TabContent className="">
                {tabChange ? (
                  <TabPane className="mt-3 nomination_table">
                    <>
                      <LoadingOverlay
                        active={loader}
                        spinner={
                          <Spinner
                            style={{ height: 60, width: 60, color: "#00344B" }}
                          />
                        }
                        fadeSpeed={100}
                        classNamePrefix="mitiz"
                      >
                        <Table
                          responsive
                          className="newThemeTable table_data"
                          style={{ minWidth: "1500px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="border-top-0"
                                width={7 + "%"}
                              >
                                S.No.
                              </th>
                              <th scope="col">
                                <span
                                  className="border-top-0 sortable sort"
                                  onClick={(event) =>
                                    sortRecord(event, "org_name")
                                  }
                                >
                                  Organization Name
                                </span>
                              </th>

                              <th scope="col" className="border-top-0">
                                EIN Number
                              </th>
                              <th scope="col">
                                <span
                                  className="border-top-0 sortable sort"
                                  onClick={(event) =>
                                    sortRecord(event, "created_at")
                                  }
                                >
                                  Added On
                                </span>
                              </th>
                              <th scope="col">Send Invitation</th>
                              <th scope="col">Onboarding Status</th>
                              <th scope="col">Grantee Update Status</th>
                              <th scope="col">
                                <span
                                  className="border-top-0 sortable sort"
                                  onClick={(event) =>
                                    sortRecord(event, "status")
                                  }
                                >
                                  Status
                                </span>
                              </th>
                              <th
                                scope="col"
                                className="border-top-0 text-center"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {prevetted && prevetted?.length > 0
                              ? prevetted.map((sjgrantee, index) => (
                                  <InvitationModule
                                    index={index}
                                    tabChange={tabChange}
                                    sjgrantee={sjgrantee}
                                    key={`key-sjgrantee-${index}`}
                                    openEditModal={openEditModal}
                                    deletePrevetted={deletePrevetted}
                                    page={page}
                                    sort={sort}
                                    getPrevettedGrantees={getPrevettedGrantees}
                                  />
                                ))
                              : !loader && (
                                  <tr>
                                    <td key={0} colSpan="6">
                                      <p className="text-center">
                                        Record not found.
                                      </p>
                                    </td>
                                  </tr>
                                )}
                          </tbody>
                          <tfoot className="border_bottom">
                            <tr>
                              <td colSpan="8" style={{ margin: "auto" }}>
                                {totalPages > 1 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageChange}
                                    containerClassName={
                                      "pagination justify-content-center mb-0"
                                    }
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                  />
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </LoadingOverlay>
                    </>
                  </TabPane>
                ) : (
                  <TabPane className="nomination_table">
                    <>
                      <LoadingOverlay
                        active={loader}
                        spinner={
                          <Spinner
                            style={{ height: 60, width: 60, color: "#00344B" }}
                          />
                        }
                        fadeSpeed={100}
                        classNamePrefix="mitiz"
                      >
                        <Table
                          responsive
                          className="newThemeTable table_data"
                          // style={{ minWidth: "1400px" }}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="border-top-0"
                                width={7 + "%"}
                              >
                                S.No.
                              </th>
                              <th scope="col">
                                <span
                                  className="border-top-0 sortable sort"
                                  onClick={(event) =>
                                    sortRecord(event, "org_name")
                                  }
                                >
                                  Organization Name
                                </span>
                              </th>
                              <th scope="col">Ein Number</th>
                              <th scope="col">
                                <span
                                  className="border-top-0 sortable sort"
                                  onClick={(event) =>
                                    sortRecord(event, "created_at")
                                  }
                                >
                                  Added on
                                </span>
                              </th>
                              <th scope="col" className="text-center">
                                Updates
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {prevettedUpdate &&
                            prevettedUpdate?.list?.data.length > 0
                              ? prevettedUpdate?.list?.data.map(
                                  (sjGrantee, index) => (
                                    <PrevettedUpdateRequest
                                      index={index}
                                      sjGrantee={sjGrantee}
                                      key={`key-sjgrantee-${index}`}
                                      openEditModal={openEditModal}
                                      deletePrevetted={deletePrevetted}
                                      page={page}
                                      sort={sort}
                                      getPrevettedGrantees={
                                        getPrevettedGrantees
                                      }
                                    />
                                  )
                                )
                              : !loader && (
                                  <tr>
                                    <td key={0} colSpan="6">
                                      <p className="text-center">
                                        Record not found.
                                      </p>
                                    </td>
                                  </tr>
                                )}
                            <tfoot className="border_bottom">
                              <tr>
                                <td colSpan="6" style={{ margin: "auto" }}>
                                  {totalUpdatePages > 1 && (
                                    <ReactPaginate
                                      previousLabel={"<"}
                                      nextLabel={">"}
                                      breakLabel={"..."}
                                      pageCount={totalUpdatePages}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={3}
                                      onPageChange={handlePageChange}
                                      containerClassName={
                                        "pagination justify-content-center mb-0"
                                      }
                                      pageClassName={"page-item"}
                                      pageLinkClassName={"page-link"}
                                      previousClassName={"page-item"}
                                      previousLinkClassName={"page-link"}
                                      nextClassName={"page-item"}
                                      nextLinkClassName={"page-link"}
                                      breakClassName={"page-item"}
                                      breakLinkClassName={"page-link"}
                                      activeClassName={"active"}
                                    />
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </tbody>
                        </Table>
                      </LoadingOverlay>
                    </>
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </CardBody>
      </div>
      {open && (
        <AddEditPrevetted
          open={open}
          handleClose={() => {
            setOpen();
            setPrevettedId(null);
          }}
          prevettedId={prevettedId}
          getPrevettedGrantees={getPrevettedGrantees}
        />
      )}
    </div>
  );
};

export default Index;
