import moment from "moment";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import dmfexcel from "../../Services/dmfsubmission";
import common from "../../Services/common";
import CreateDonor from "./CreateDonor";
import DmfDetailsBody from "./DmfDetailsBody";
import { Link } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";
import HelloSignConfirmation from "./HelloSignConfirmation";
import { BsCircle, BsFillSendDashFill, BsXCircleFill } from "react-icons/bs";
import { FaEye, FaPeopleArrows, FaEllipsisV } from "react-icons/fa";
import { TbAlarm } from "react-icons/tb";
import { MdDownload, MdModeEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

const Dmf = (props) => {
  const [open, setOpen] = useState(false);
  const [opendetail, setOpenDetail] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [openContract, setOpenContract] = useState(false);
  const [modalData, setModalData] = useState({});
  const [donorId, setDonorId] = useState("");
  const [contractId, setContractId] = useState("");
  const [openSignRequest, setOpenSignRequest] = useState(false);
  const [SignRequestId, setSignRequestId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const openDonorModal = (donor_id) => {
    setOpen([true, false]);
    setDonorId(donor_id);
  };

  const dmfDetailsModal = (detail_id) => {
    setOpenDetail(true);
    setDetailId(detail_id);
  };

  const downloadPDF = (id) => {
    setLoading(true);
    let params = {
      signature_id: id,
    };
    dmfexcel.PdfDownload(params).then((res) => {
      setLoading(false);
      if (res.data) {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `HelloSignDocument.pdf`);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    });
  };

  const SendContract = (contractId) => {
    setOpenContract(true);
    setContractId(contractId);
  };

  const sendSignRequest = (sign_id, actionType) => {
    if (actionType === 1) {
      setModalData({
        modelText:
          "Are you sure you want to send a contract Sign request to the donor?",
        action: "sign_contract",
        modalLabel: "Confirmation",
      });
    } else {
      setModalData({
        modelText:
          "Are you sure you want to send a reminder to the donor to sign the contract?",
        action: "send_reminder",
        modalLabel: "Reminder Confirmation",
      });
    }

    setOpenSignRequest(true);
    setSignRequestId(sign_id);
  };

  const dmf = props.dmf;
  console.log(dmf);
  return (
    <>
      <tr key={dmf.id}>
        <td>{dmf.id}</td>
        <td style={{ maxWidth: "250px" }}>
          <Link
            to={""}
            onClick={() => dmfDetailsModal(dmf.id)}
            className="text-decoration-none"
          >
            <span className="recent_donor_font">{common.getFullName(dmf)}</span>
            <p>{dmf.email}</p>
          </Link>
        </td>
        <td>
          {dmf?.initial_funding_amount
            ? `$${dmf?.initial_funding_amount}`
            : "N/A"}
        </td>
        <td>{moment(dmf.created_at).format("D MMM,  YYYY")}</td>

        <td className="text-center">
          {dmf.is_contract_sent === 1 ? (
            <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
          ) : (
            <BsCircle
              style={{ cursor: "pointer" }}
              onClick={() => SendContract(dmf.id)}
              size={22}
            />
          )}
        </td>
        <td className="text-center">
          {dmf.legal_info_status === 1 ? (
            <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>

        <td className="text-center">
          {dmf.dmf_contract_sent === "SENT" ? (
            <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
          ) : dmf.legal_info_status === 1 ? (
            <BsCircle
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => sendSignRequest(dmf.id, 1)}
            />
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>
        <td>
          <Badge
            pill
            color={common.getSignstatuscolor(dmf?.dmf_contract_status)}
          >
            {dmf?.dmf_contract_status.replace("_", " ")}
          </Badge>
        </td>
        <td className="text-center">
          {dmf.is_contract_sent === 1 &&
          dmf.legal_info_status === 1 &&
          dmf.dmf_contract_status === "SIGNED" ? (
            <FaPeopleArrows
              className="text-center donor_icon"
              onClick={() => openDonorModal(dmf.id)}
            />
          ) : (
            <BsCircle
              size={25}
              style={{
                backgroundColor: "#b3aeae",
                borderRadius: "50%",
                color: "#878787",
              }}
            />
          )}
        </td>
        <td className="text-center">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
              <span onClick={() => dmfDetailsModal(dmf.id)}>
                <DropdownItem>
                  <FaEye
                    className="me-3 details-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
                  Details
                </DropdownItem>
              </span>
              <span onClick={() => props.openEditModal(dmf.id)}>
                <DropdownItem>
                  <MdModeEdit
                    className="me-3 edit-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
                  Edit
                </DropdownItem>
              </span>
              {dmf.dmf_contract_sent == "SENT" &&
                dmf.dmf_contract_status !== "SIGNED" && (
                  <span onClick={() => sendSignRequest(dmf.id, 2)}>
                    <DropdownItem>
                      <TbAlarm
                        className="me-3"
                        size={20}
                        style={{ cursor: "pointer" }}
                      />
                      Sign Reminder
                    </DropdownItem>
                  </span>
                )}
              {dmf.is_contract_sent === 1 && dmf.legal_info_status !== 1 && (
                <span onClick={() => SendContract(dmf.id)}>
                  <DropdownItem>
                    <BsFillSendDashFill
                      className="me-3 edit-icon"
                      size={20}
                      style={{ cursor: "pointer" }}
                    />
                    Re-send Initial Email
                  </DropdownItem>
                </span>
              )}

              <span onClick={() => props.deleteDmfSubmission(dmf.id)}>
                <DropdownItem>
                  <BsXCircleFill
                    className="remove-icon me-3"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
                  Remove
                </DropdownItem>
              </span>

              {dmf?.dmf_contract_sent == "SENT" && (
                <span onClick={() => downloadPDF(dmf.signature_request_id)}>
                  <DropdownItem>
                    <MdDownload
                      className="me-3"
                      size={20}
                      style={{ cursor: "pointer", color: "green" }}
                    />
                    Download
                  </DropdownItem>
                </span>
              )}
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      {open && (
        <CreateDonor
          open={open}
          handleClose={() => {
            setOpen();
            setDonorId(null);
          }}
          donorId={donorId}
          getDmfSubmission={props.getDmfSubmission}
        />
      )}
      {opendetail && (
        <DmfDetailsBody
          opendetail={opendetail}
          handleClose={() => {
            setOpenDetail();
            setDetailId(null);
          }}
          dmfId={detailId}
          close={setOpen}
          getDmfSubmission={props.getDmfSubmission}
        />
      )}
      {openContract && (
        <ConfirmationModal
          openContract={openContract}
          handleClose={() => {
            setOpenContract();
            setContractId(null);
          }}
          contractId={contractId}
          getDmfSubmission={props.getDmfSubmission}
        />
      )}

      {openSignRequest && (
        <HelloSignConfirmation
          openSignRequest={openSignRequest}
          handleClose={() => {
            setOpenSignRequest();
            setSignRequestId(null);
          }}
          modalData={modalData}
          SignRequestId={SignRequestId}
          getDmfSubmission={props.getDmfSubmission}
        />
      )}
    </>
  );
};

export default Dmf;
