import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Spinner, Table } from "reactstrap";
import Sidebar from "../Sidebar";
import nominee from "../../../Services/nominee";
import common from "../../../Services/common";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from "react-paginate";
import { FaEye } from "react-icons/fa";
import GrantRecommendedDetailsBody from "./GrantRecommendedDetailsBody";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "reactstrap";

const Index = () => {
  const [grantHistory, setGrantHistory] = useState({});
  const [loader, setLoader] = useState(false);
  const [opendetail, setOpenDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [detailId, setDetailId] = useState("");
  const [sort, setSort] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [orgTooltip, setOrgTooltip] = useState(null);

  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const detailsModal = (detail_id) => {
    setOpenDetail(true);
    setDetailId(detail_id);
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getRecomendationHistory = () => {
    setLoader(true);
    nominee
      .list_nomination({ page: page, ...sort })
      .then((res) => {
        setGrantHistory(res.data.data.data);
        setPage(res.data.data.current_page);
        setTotalPages(Math.ceil(res.data.data.total / res.data.data.per_page));
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleMouseEnter = (itemNo) => {
    setOrgTooltip(itemNo);
  };

  const handleMouseLeave = () => {
    setOrgTooltip(null);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.selected + 1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`,
    });
  };

  const getTotalAmount = (arr = []) => {
    let totalAmount = 0;
    if (arr.length > 0) {
      arr.forEach((record) => {
        totalAmount += parseFloat(record.grant_amount);
      });
    }
    return totalAmount.toFixed(2);
  };

  useEffect(() => {
    getRecomendationHistory(page, sort);
  }, [page, sort]);

  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <div
            className="mt-4 mb-5 userAdminArticle"
            style={{ minHeight: "350px" }}
          >
            <div className="py-3 userAdminArticleHeading">
              <h4 className="mb-0"> Grant Nomination History</h4>
            </div>
            <Fragment>
              <LoadingOverlay
                active={loader}
                spinner={
                  <Spinner
                    style={{ height: 60, width: 60, color: "#00344B" }}
                  />
                }
                fadeSpeed={100}
                classNamePrefix="mitiz"
              >
                <Table responsive className="newThemeTable table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "org_name")}
                        >
                          Organization Name
                        </span>
                      </th>
                      <th scope="col" className="border-top-0">
                        EIN
                      </th>
                      <th scope="col" className="border-top-0">
                        Amount
                      </th>

                      <th scope="col" className="border-top-0">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "created_at")}
                        >
                          Date
                        </span>
                      </th>
                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {grantHistory?.length > 0
                      ? grantHistory?.map((history, index) => (
                          <tr key={`key-history-${index}`}>
                            <td
                              id={`tooltip-${index}`}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {history?.nomination_records[0]?.org_name ? (
                                <Link
                                  onClick={() => detailsModal(history.id)}
                                  className="text-decoration-none"
                                  id="OrgName"
                                >
                                  {history?.nomination_records[0]?.org_name
                                    ?.length > 30
                                    ? history?.nomination_records[0]?.org_name?.slice(
                                        0,
                                        30
                                      ) + "..."
                                    : history?.nomination_records[0]?.org_name}
                                </Link>
                              ) : (
                                "--"
                              )}
                              <Tooltip
                                isOpen={orgTooltip === index}
                                placement="top"
                                target={`tooltip-${index}`}
                              >
                                {`${history?.nomination_records[0]?.org_name}`}
                              </Tooltip>
                            </td>
                            <td>
                              {history?.nomination_records[0]?.ein_number ? (
                                <Link
                                  onClick={() => detailsModal(history.id)}
                                  className="text-decoration-none"
                                >
                                  {history?.nomination_records[0]?.ein_number}
                                </Link>
                              ) : (
                                "--"
                              )}
                            </td>

                            {history?.has_ein === 0 &&
                            history?.nomination_records?.length > 0 ? (
                              <td>
                                $
                                {common.numberFormat(
                                  getTotalAmount(history?.nomination_records)
                                )}
                              </td>
                            ) : (
                              ""
                            )}
                            {history?.has_ein === 1 &&
                            history?.nomination_records?.length > 0 ? (
                              <td>
                                $
                                {common.numberFormat(
                                  getTotalAmount(history?.nomination_records)
                                )}
                              </td>
                            ) : (
                              ""
                            )}
                            <td>
                              {moment(history.created_at).format(
                                "MMM Do,  YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              <FaEye
                                onClick={() => detailsModal(history.id)}
                                size={22}
                                id="ViewdDetails"
                                style={{ cursor: "pointer" }}
                                color="#893974"
                              />
                              <Tooltip
                                isOpen={tooltipOpen}
                                placement="right"
                                target="ViewdDetails"
                                toggle={() => {
                                  setTooltipOpen(!tooltipOpen);
                                }}
                              >
                                View detail's
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      : !loader && (
                          <tr>
                            <td key={0} colSpan="5">
                              <p className="text-center">No Record Found.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </LoadingOverlay>
            </Fragment>
            <Fragment>
              {totalPages > 1 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              )}
            </Fragment>
          </div>
          {opendetail && (
            <GrantRecommendedDetailsBody
              opendetail={opendetail}
              handleClose={() => {
                setOpenDetail();
                setDetailId(null);
              }}
              recommendedId={detailId}
              grantHistory={grantHistory}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Index;
