import DmfForm from "../Frontend/DmfForm";
import Faqs from "../Frontend/Role/Faqs";
// import MyInformation from "../Frontend/Role/MyInformation";
import PrevettedGrantee from "../Frontend/Role/PrevettedGrantee";



const PrevettedRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    element: PrevettedGrantee,
    authentication: true,
  },
  {
    path: "dmf-form",
    name: "Dmf Form",
    element: DmfForm,
    authentication: true,
  },

  {
    path: "my-information",
    name: "My Information",
    element: PrevettedGrantee,
    authentication: true,
  },

  {
    path: "faqs",
    name: "Faqs",
    element: Faqs,
    authentication: true,
  },

];

export default PrevettedRoutes;
