// import React from "react";
import Dashboard from "../Backend/Dashboard";
import Users from "../Backend/Users";
import Dmfsubmision from "../Backend/Dmfsubmission";
import EmailTemplates from "../Backend/EmailTemplates";
import AddEditTemplate from "../Backend/EmailTemplates/AddEditTemplate";
import Error from "../Frontend/Error";
import Nominations from "../Backend/Nominations";
// import Setting from "../Backend/Setting"
import Grantees from "../Backend/Grantees";
// import PrevettedSjGrantees from "../Backend/PrevettedSjGrantees";
import PrevettedNew from "../Backend/PrevettedNew";
import EditEmail from "../Backend/Setting/EditEmail";
import ChangePassword from "../Backend/Setting/ChangePassword";
import GlobalConfig from "../Backend/Setting/GlobalConfig";
import Faq from "../Backend/Setting/Faq";
import Public from "../Backend/Setting/Faq/Public";
import Donor from "../Backend/Setting/Faq/Donor";
import Grantee from "../Backend/Setting/Faq/Grantee";

const AdminRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    element: Dashboard,
    authentication: true,
  },
  {
    path: "users",
    name: "Users",
    element: Users,
    authentication: true,
  },
  {
    path: "dmf-submission",
    name: "Dmf Submision",
    element: Dmfsubmision,
    authentication: true,
  },
  {
    path: "nominations",
    name: "nominations",
    element: Nominations,
    authentication: true,
  },
  {
    path: "email-templates",
    name: "Email Templates",
    element: EmailTemplates,
    authentication: true,
  },
  {
    path: "email-templates/add",
    name: "Add Email Template",
    element: AddEditTemplate,
    authentication: true,
  },
  {
    path: "email-templates/edit/:id",
    name: "Update Email Template",
    element: AddEditTemplate,
    authentication: true,
  },
  // {
  //   path: "settings",
  //   name: "settings",
  //   element: Setting,
  //   authentication: true
  // },
  {
    path: "grantees",
    name: "grantees",
    element: Grantees,
    authentication: true,
  },
  // {
  //   path: "prevetted-sjgrantees",
  //   name: "grantees",
  //   element: PrevettedSjGrantees,
  //   authentication: true
  // },
  {
    path: "prevetted-sjgrantees",
    name: "grantees",
    element: PrevettedNew,
    authentication: true,
  },
  {
    path: "settings/profile",
    name: "profile",
    element: EditEmail,
    authentication: true,
  },
  {
    path: "settings/change-password",
    name: "change password",
    element: ChangePassword,
    authentication: true,
  },
  {
    path: "settings/global-configs",
    name: "global configs",
    element: GlobalConfig,
    authentication: true,
  },
  {
    path: "settings/faqs",
    name: "faqs",
    element: Faq,
    authentication: true,
  },
  {
    path: "settings/faqs/public",
    name: "faqs",
    element: Faq,
    authentication: true,
  },
  {
    path: "settings/faqs/donors",
    name: "faqs",
    element: Faq,
    authentication: true,
  },
  {
    path: "settings/faqs/grantee",
    name: "faqs",
    element: Faq,
    authentication: true,
  },
  {
    path: "error",
    name: "Error",
    element: Error,
    authentication: true,
  },
];

export default AdminRoutes;
