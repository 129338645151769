import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Title,
  Legend,
} from "chart.js";

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Title,
  Legend
);

const DashboardChart = ({ chartData, fields }) => {
  const data = {
    labels: chartData.length > 0 && chartData?.map((data) => data.date),
    datasets: [
      {
        label: "DMF Submissions",
        data: chartData.map((data) => data.total),

        fill: true,
        backgroundColor: ["#e26a38"],
        borderColor: "#e26a38",
        borderWidth: 2,
        lineTension: 0.4,
        radius: 0,
        
      },
      
    ],
  };

  const option = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        // text: "DMF Submissions",
      },
      legend: {
        display: false,
      },
    },
    
  };

  return (
    <div className="chart">
      <h6 className="title-font">DMF Submissions</h6>
      <h4 className="title-font fw-bold mx-2">
        {fields?.dmf_submissions}
      </h4>
      <div className="chart_data">
        <Line data={data} options={option}/>
      </div>
    </div>
  );
};

export default DashboardChart;
