import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import faq from "../../../Services/faq";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdModeEdit } from "react-icons/md";
import { BsXCircleFill } from "react-icons/bs";

const Grantee = ({ granteefaq, setGranteeFaq, loader, openFaqEditModal }) => {
  const [recordOrder, setOrder] = useState([]);
  LoadingOverlay.propTypes = undefined;

  const openEditModal = (faq_id) => {
    openFaqEditModal(faq_id);
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    if (destination.index == source.index) return;
    const t = granteefaq.filter(
      (ele) => parseInt(ele.id) === parseInt(draggableId)
    )[0];

    const newList = [...granteefaq];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, t);
    setGranteeFaq(newList);
    let recordOrder = newList.map((faq, index) => {
      return {
        id: faq.id,
        sequence: index,
      };
    });
    setOrder(recordOrder);
  };

  const deleteFaq = (id) => {
    if (window.confirm("Are you sure to delete this FAQ?")) {
      faq.delete(id).then((res) => {
        if (res.data.status) {
          const newFaq = granteefaq.filter((item) => item.id !== id);
          setGranteeFaq(newFaq);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleSequence = () => {
    let params = {
      data: recordOrder,
    };
    faq
      .sequence(params)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (recordOrder && recordOrder?.length > 0) {
      handleSequence();
    }
  }, [recordOrder]);

  return (
    <Row>
      <Col>
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner
              style={{
                height: 60,
                width: 60,
                color: "#00344B",
              }}
            />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <Table responsive className="newThemeTable" id="faq_table">
            <thead>
              <tr>
                <th scope="col" className="border-top-0">
                  S.No.
                </th>
                <th scope="col" className="border-top-0">
                  Question
                </th>
                <th scope="col" className="border-top-0 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="grantee">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {granteefaq.length > 0
                      ? granteefaq.map((faq, index) => (
                          <Draggable
                            key={`draggable-3-${faq.id}`}
                            draggableId={`${faq.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                key={`faq-${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <td style={{ width: "100px" }}>{index + 1}</td>
                                <td style={{ width: "300px" }}>
                                  {faq.question}
                                </td>

                                <td
                                  className="text-center"
                                  style={{ width: "200px" }}
                                >
                                  <MdModeEdit
                                    onClick={() => openEditModal(faq.id)}
                                    className="me-2 edit-icon"
                                    size={20}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                  <BsXCircleFill
                                    onClick={() => deleteFaq(faq.id)}
                                    className="remove-icon"
                                    size={20}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))
                      : !loader &&
                        granteefaq.length < 1 && (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No record found
                            </td>
                          </tr>
                        )}

                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </LoadingOverlay>
      </Col>
    </Row>
  );
};

export default Grantee;
