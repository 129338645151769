import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import DmfIntakeFormSubmission from "../../Common/DmfIntakeFormSubmission";
import dmf from "../../Services/dmfsubmission";

const Index = (props) => {
  const [validToken, setValidToken] = useState(false);
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const getToken = () => {
    let params = { token: token };
    dmf
      .dmfToken(params)
      .then((res) => {
        if (res.data.status) {
          setValidToken(true);
        } else {
          navigate("/login");
          setValidToken(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      <Helmet>
        <title>Login : seeding-Justice</title>
      </Helmet>
      <section className="homepage_banner">
        <Container style={{ minHeight: "500px" }}>
          <Row>
            {validToken ? (
              <Col className="col-md-6 offset-md-3">
                <Card className="login login_bg">
                  <CardBody>
                    <div className="text-center">
                      <h1>DMF Account</h1>
                      <h6>Update Donor-Movement Fund Account</h6>
                    </div>
                    <DmfIntakeFormSubmission />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <div className="vh-100 d-flex justify-content-center align-items-center">
                <Spinner
                  size="sm"
                  className="mx-1"
                  style={{ height: "80px", width: "80px", color: "#00344B" }}
                />
              </div>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Index;
