import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Form,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
  Modal,
} from "reactstrap";
import userGrantee from "../../../Services/userGrantee";

const ConfirmationModal = (props) => {
  const [isloader, setIsloader] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      id: props.prevettedId,
    };
    setIsloader(true);
    userGrantee
      .updateRequest(params)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsloader(false);
          props.getPrevettedGrantee();
          props.handleClose();
        } else {
          let errors = {};
          for (let key in res.data.message) {
            errors[key] = res.data.message[key];
          }
          setErrors(errors);
          setIsloader(false);
        }
      })
      .catch(function (error) {
        setIsloader(false);
        console.log("error", error);
      });
  };


  return (
    <div>

      <Modal isOpen={props.modalOpen} size="md" className="details_modal">
        
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            Confirmation
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            Are you sure you want to cancel the update request?
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              No
            </Button>
            <Button
              color="success"
              className="submit-Id-button"
              size="md"
              type="submit"
              disabled={isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              Yes
            </Button>
          </ModalFooter>
        </Form>
        
      </Modal>

    </div>
  );
};

export default ConfirmationModal;
