import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import common from "../../../Services/common";
import userdashboard from "../../../Services/userdashboard";
const GrantRecommendedDetailsBody = (props) => {
  const [grantHistory, setGrantHistory] = useState({});
  const [users, setUsers] = useState([]);
  const [nomination, setNomination] = useState({});
  const [loader, setLoader] = useState(false);

  const getRecomendationHistory = () => {
    let params = props.recommendedId;
    setLoader(true);
    userdashboard
      .getOne(params)
      .then((res) => {
        if (res?.data?.status) {
          setGrantHistory(res?.data?.data);
          setUsers(res?.data?.data?.user);
          setNomination(res?.data?.data?.nomination_records);

          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.recommendedId) {
      getRecomendationHistory(props.recommendedId);
    }
  }, []);

  return (
    <Modal isOpen={props.opendetail} size="lg" className="user_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        Grant Recommended Details
      </ModalHeader>

      <ModalBody className="pl-4 pr-4">
        {loader ? (
          <div className="text-center">
            <Spinner style={{ width: 50, height: 50 }} />
          </div>
        ) : (
          <div>
            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">Donor Details</div>
              <div className="pt-2">
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>First Name :</strong> {users?.first_name}
                      </div>
                    </Col>
                    <Col col={6}>
                      <div className="nomination-box">
                        <strong>Last Name :</strong> {users?.last_name}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Email :</strong> {users?.email}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Phone : </strong> {users?.phone}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </div>
            {nomination.length > 0 &&
              nomination.map((ele, index) => (
                
                <div className="nomination-card">
                  <div className="nomination-title py-2 px-2">
                    {nomination.length > 1
                      ? `Nomination Details ${index + 1}`
                      : "Nomination Details"}
                  </div>
                  <div className="pt-2">
                    <FormGroup className="ms-2 me-2">
                      <Row key={`key-ele-${index}`}>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Name of Organization : </strong>
                            {ele?.org_name ? ele?.org_name : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>City : </strong>{" "}
                            {ele?.city ? ele.city : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>State : </strong>
                            {ele?.state?.name ? ele.state.name : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Street :</strong>{" "}
                            {ele?.street ? ele.street : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    {grantHistory?.is_prevetted ? (
                      <FormGroup className="ms-2 me-2">
                        <Row>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Zip : </strong>{" "}
                              {ele?.zip ? ele.zip : "N/A"}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Added on : </strong>{" "}
                              {moment(ele["created_at"]).format("D MMM,  YYYY")}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Grant Amount : </strong>$
                            {common.numberFormat(ele?.grant_amount)}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>EIN Number :</strong>{" "}
                            {ele?.ein_number !== null ? ele.ein_number : "N/A"}
                          </div>
                        </Col>
                        {/* <Col md={6}>
                          <div className="nomination-box">
                            <strong>Added on : </strong>{" "}
                            {moment(ele["created_at"]).format("D MMM,  YYYY")}
                          </div>
                        </Col> */}
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Organization's Website :</strong>{" "}
                            <a
                              href={ele?.org_website}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              {ele?.org_website ? ele?.org_website : "N/A"}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Geography :</strong>{" "}
                            {ele?.geography ? ele?.geography : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Priority Population : </strong>{" "}
                            {ele?.priority_population
                              ? ele?.priority_population
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Website :</strong>{" "}
                            <a
                              href={ele?.org_website ? ele.org_website : "#"}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              {ele?.org_website ? ele.org_website : "N/A"}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Mission :</strong>{" "}
                            {ele?.mission !== null ? ele?.mission : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>
                              Would you like Donor Recognition or Anonymity? :
                            </strong>{" "}
                            {ele?.recognition !== null
                              ? ele?.recognition.category_name
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Organization Contact Name : </strong>{" "}
                            {ele?.org_contact_name
                              ? ele?.org_contact_name
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Organization Email : </strong>
                            {ele?.org_contact_email
                              ? ele?.org_contact_email
                              : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Organization's Phone Number : </strong>{" "}
                            {ele?.org_phone ? ele?.org_phone : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>
                              {ele?.support_queries &&
                              ele?.support_queries?.length > 0
                                ? "Is this for general operating support or project specific support?"
                                : "Is this for general operating support or project specific support?"}{" "}
                              :{" "}
                            </strong>

                            {ele?.support_queries
                              ? ele?.support_queries
                              : "Operating Support"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </div>
              ))}

            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">Remarks</div>

              <div className="p-2 nomination-box">
                <div className="nomination-box">
                  {grantHistory?.additional_queries
                    ? grantHistory?.additional_queries
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className="cancel_modal"
          onClick={props.handleClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GrantRecommendedDetailsBody;
