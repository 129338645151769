import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import { Link } from "react-router-dom";

const EmailVerification = () => {
  const [isloader, setIsLoader] = useState(false);
  const [errors, setErrors] = useState("");
  const email = useSelector((state) => state.email);

  const ResendEmail = (e) => {
    e.preventDefault();
    setIsLoader(true);
    let params = { email: email };
    user
      .resendEmail(params)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setErrors(res.data.message);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoader(false);
      });
  };

  return (
    <>
      <div className="login_section homepage_banner">
        <Container>
          <Row>
            <Col className="col-md-6 offset-md-3">
              <Card className="login login_bg">
                <CardBody>
                  <div className="text-center">
                    <h1>Our Community Garden</h1>
                    <p>
                      Welcome to Our Community Garden, the secure portal to
                      manage your Seeding Justice Donor-in-Movement Fund. Please
                      note: password and security questions are case sensitive.
                    </p>
                    <p>
                      If you have not set up a Community Garden account yet,
                      please contact us at{" "}
                      <a
                        href="mailto:XYZ@seedingjustice.com"
                        className="orange-text"
                      >
                        XYZ@seedingjustice.com{" "}
                      </a>
                      . If you are interested in opening a Donor-in-Movement
                      Fund, please fill out this{" "}
                      <Link to="/dmf-form" className="orange-text">
                        {" "}
                        form{" "}
                      </Link>
                      with the new donor advised fund account.
                    </p>
                  </div>
                  <Form className="login-form mt-2">
                    <Row>
                      <Col md={12} className="text-center">
                        <h4 className="orange-text">Please Verify Your Email!</h4>
                      </Col>
                      <Col md={12} className="text-center">
                        <p>Verification email has sent to your email.</p>
                      </Col>
                      <Col md={12} className="text-center">
                        <p className="text-danger">{errors}</p>
                      </Col> 
                      <Col md={12}>
                        <Button
                          color="success"
                          type="submit"
                          onClick={ResendEmail}
                          disabled={isloader}
                          className="submit-button"
                        >
                          {isloader ? (
                            <Spinner size="sm" className="mx-1" />
                          ) : (
                            ""
                          )}
                          Resend Verification Email
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default EmailVerification;
