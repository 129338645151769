import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button, Card, CardBody } from "reactstrap";
import Search from "../Search";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import Template from "./Template";
import { Link,  useNavigate, useLocation  } from "react-router-dom";
import emailtemplate from "../../Services/emailtemplate";
import { BsSearch } from "react-icons/bs";

const Index = () => {
  const [templates, setTemplates] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [searchOpen, setsearchOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({})
  const [searchFields, setSearchFields] = useState([
    { label: "Template Name", name: "template_name", type: "text", col: 4 },
  ]);
  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSearch = () => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.selected+1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`
    });
  };

  const getSearchFields = (fields = {}) => {
    if (searchParams) {
      setPage(1)
      getTemplates(fields);
    }
  }

  const getTemplates = (fields = {}) => {
    setLoader(true);
    let params = { ...fields, page: page };
    emailtemplate
      .list(params)
      .then((res) => {
        if (res.data.status) {
          let response = res?.data?.data;
          setTemplates(response.data);
          if (!searchParams) {
            setPage(response.current_page);
          }
          setTotalPages(response.total/response.per_page);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  
  const deleteTemplate = (id) => {
    if (window.confirm("Are you sure to delete this template?")) {
      emailtemplate.delete(id).then((res) => {
        if (res.data.status) {
          const newUser = templates.filter((item) => parseInt(item.id) !== parseInt(id));
          setTemplates(newUser);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  useEffect(() => {
    getTemplates(page);
  }, [page]);

  return (
    <div>
      <Helmet>
        <title>Email Template : seeding justice</title>
      </Helmet>

      <Row className="mb-4">
        <Col md={6} sm={12}>
          <h4 className="mb-0 title-font">Email Template</h4>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: '#e26a38' }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <p className="mb-0">Email Template</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col md={6} sm={12} className="text-end">
          
          <Link
            className="btn btn-success m-1 add_button"
            size="md"
            block
            to={"/admin/email-templates/add"}
            style={{ borderRadius: '30px' }}
          >
            Add New Template
          </Link>
          <Button
               color="light"
            type="button"
            onClick={toggleSearch}
            className="m-1 search_button"
            style={{ borderRadius: '30px' }}
          >
            <BsSearch className="me-1" />  Search
          </Button>
        </Col>
        <Col>
          <Search
            isOpen={searchOpen}
            searchFields={searchFields}
            heading="Search Email-template"
            onSubmit={getSearchFields}
            setFields={setSearchValue}
            fields={searchValue}
            setSearchParams={setSearchParams}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <LoadingOverlay
            active={loader}
            spinner={
              <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
            }
            fadeSpeed={100}
            classNamePrefix="mitiz"
          >
            <Table responsive className="newThemeTable">
              <thead>
                <tr>
                  <th scope="col" className="border-top-0" width={7 + "%"}>
                  S.No.
                  </th>
                  <th scope="col" className="border-top-0">
                    Template Name
                  </th>
                  <th scope="col" className="border-top-0">
                    From Email
                  </th>
                  <th scope="col" className="border-top-0">
                    Reply To Email
                  </th>

                  <th scope="col" className="border-top-0 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {templates.length > 0
                  ? templates.map((template, index) => (
                    <Template
                      template={template}
                      key={`key-template-${index}`}
                      deleteTemplate={deleteTemplate}
                    />
                  ))
                  : !loader && (
                    <tr>
                      <td key={0} colSpan="5">
                        <p className="text-center">Template not found.</p>
                      </td>
                    </tr>
                  )}
              </tbody>
              <tr>
                <td colSpan="5">
                  {totalPages > 1 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  )}
                </td>
              </tr>
            </Table>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
