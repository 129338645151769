import moment from "moment";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import common from "../../Services/common";
import GranteeDetailsbody from "./GranteeDetailsbody";

const Grantee = (props) => {
  const [open, setOpen] = useState(false);
  const [grantId, setGrantId] = useState("");

  const GrantModal = (grantee_id) => {
    setOpen(true);
    setGrantId(grantee_id);
  };
  const grant = props.grant;
  return (
    <>
      <tr key={grant.id}>
        <td>
          <Link
            className="text-decoration-none"
            to={""}
            onClick={() => GrantModal(grant.id)}
          >
            {common.getFullName(grant?.user)}
          </Link>
        </td>
        <td>{grant?.user?.email}</td>
        <td>{moment(grant.grantee_approval_date).format("D MMM,  YYYY")}</td>
        <td className="text-center">
          {" "}
          <FaEye
            onClick={() => GrantModal(grant.id)}
            className="details-icon"
            size={22}
            style={{ cursor: "pointer"}}
          />
        </td>
      </tr>
      {open && (
        <GranteeDetailsbody
          open={open}
          handleClose={() => {
            setOpen();
            setGrantId(null);
          }}
          grantId={grantId}
        />
      )}
    </>
  );
};

export default Grantee;
