import React, { useState, useEffect } from "react";
import RecentDonors from "./RecentDonors";
import { Helmet } from "react-helmet";
import { Col, Row, Table, Spinner, Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import dmf from "../../../Services/dmfsubmission";
import { Link } from "react-router-dom";

const Index = () => {
  const [fields, setFields] = useState({});
  const [users, setUsers] = useState({});
  const [loader, setLoader] = useState(false);

  const getUsers = () => {
    setLoader(true);
    let params = { ...fields };
    dmf
      .recent_donors(params)
      .then((res) => {
        if (res.data.status) {
          setUsers(res.data?.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Dashboard : seeding justice</title>
      </Helmet>

      <Card className="px-3 recent_donors">
        <CardBody className="mt-2">
          <Row>
            <Col md={6} sm={12}>
              <h4 className="mb-0 title-font" style={{ fontSize: "18px" }}>
                Recent DMF Donors
              </h4>
            </Col>
            <Col sm={12} md={6} className="text-end">
              <Link
                to="/admin/users"
                color="light"
                type="button"
                className="mt-2 mt-md-0 btn btn-outline-secondary view-more-btn"
                size="md"
                style={{ borderRadius: "30px" }}
              >
                View More
              </Link>
            </Col>
          </Row>
          <LoadingOverlay
            active={loader}
            spinner={
              <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
            }
            fadeSpeed={100}
            classNamePrefix="mitiz"
          >
            <Table
              responsive
              className="newThemeTable recentDonorTable px-3 mt-4"
            >
              <tbody>
                {users.length > 0
                  ? users.map((user, index) => (
                      <RecentDonors user={user} key={`key-user-${index}`} />
                    ))
                  : !loader && (
                      <tr>
                        <td key={0} colSpan="6">
                          <p className="text-center">Recent donor not found.</p>
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
