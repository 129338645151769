import React from "react";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from "react-paginate";
import { Col, Spinner, Table } from "reactstrap";
import Submissions from "../Submissions";

const GrantSubmissions = (props) => {
  const {
    openEditModal,
    deleteNomination,
    sortRecord,
    handlePageChange,
    loader,
    totalPages,
    sort,
    prevetted,
    nominations,
  } = props;

  LoadingOverlay.propTypes = undefined;
  return (
    <>
      <Col>
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <Table
            responsive
            className="newThemeTable"
            style={{ minWidth: "1565px" }}
          >
            <thead>
              <tr>
                <th scope="col">
                  <span className="border-top-0 sortable sort" onClick={(event) => sortRecord(event, "users.first_name")}>Donor Full Name</span>
                </th>
                <th scope="col" className="border-top-0 sortable sort" onClick={(event) => sortRecord(event, "users.email")}>
                  Email
                </th>
                <th
                  scope="col"
                  className="border-top-0 sortable sort"
                  onClick={(event) => sortRecord(event, "created_at")}
                >
                  Grant Created
                </th>
                <th scope="col" className="border-top-0">
                  Confirmation Email
                </th>
                <th scope="col" className="border-top-0">
                  NGO Contract
                </th>
                <th scope="col" className="border-top-0">
                  Distributed
                </th>
                <th scope="col" className="border-top-0 text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody style={{ minHeight: 350 }}>
              {prevetted.length > 0
                ? prevetted.map((prevettedSj, index) => (
                    <Submissions
                      prevettedSj={prevettedSj}
                      nominations={nominations}
                      key={`key-prevettedSj-${index}`}
                      openEditModal={openEditModal}
                      prevetted={prevetted}
                      deleteNomination={deleteNomination}
                      getNominations={props.getNominations}
                    />
                  ))
                : !loader &&
                  prevetted.length < 1 && (
                    <tr key={0}>
                      <td colSpan="9">
                        <p className="text-center">Record not found.</p>
                      </td>
                    </tr>
                  )}
            </tbody>
            <tfoot className="border_bottom">
              <tr>
                <td colSpan="3" style={{ margin: "auto" }}>
                  {totalPages > 1 && (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  )}
                </td>
              </tr>
            </tfoot>
          </Table>
        </LoadingOverlay>
      </Col>
    </>
  );
};

export default GrantSubmissions;
