import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import common from "../../Services/common";
const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const email = query.get("email");

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };


  const toggle = (action) => {
    if(action == 1){
      setVisible(!isVisible);
    }if(action == 2){
      setIsPasswordVisible(!isPasswordVisible);
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
  
    if (!fields?.password) {
      errors["password"] = "Password cannot be empty";
      formIsValid = false;
    } else if (fields?.password.length < 8) {
      errors["password"] = "Password must be at least 8 characters";
      formIsValid = false;
    } else if (fields?.password.length > 8) {
      errors["password"] = "Password cannot be greater than 8 characters";
      formIsValid = false;
    }
  
    if (fields?.password_confirmation && fields.password_confirmation !== fields.password) {
      errors["password_confirmation"] = "Confirm password did not match";
      formIsValid = false;
    } else if (fields?.password_confirmation && fields.password_confirmation.length > 8) {
      errors["password_confirmation"] = "Confirm password cannot be greater than 8 characters";
      formIsValid = false;
    }
  
    setErrors(errors);
    return formIsValid;
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      params["token"] = token;
      params["email"] = email;
      setLoader(true);
      user.resetpassword(params).then((res) => {
        setLoader(true);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          navigate("/login");
          setFields({});
        } else {
          if (common.isJson(res.data.message)) {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
          } else {
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoader(false);
        }
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>Reset Password : seeding-Justice</title>
      </Helmet>

      <section className="homepage_banner secondary-homepage_banner">
        <Container>
          <div className="col-md-6 offset-md-3" style={{ minHeight: 500 }}>
            <Card className="login login_bg">
              <CardBody>
              <div className="text-center">
                    <h1>Our Community Garden</h1>
                    {/* <p>
                      Welcome to Our Community Garden, the secure portal to
                      manage your Seeding Justice Donor-in-Movement Fund. Please
                      note: password and security questions are case sensitive.
                    </p> */}
                    <p>
                      If you have not set up a Community Garden account yet,
                      please contact us at{" "}
                      <a
                        href="mailto:XYZ@seedingjustice.com"
                        className="orange-text"
                      >
                        XYZ@seedingjustice.com.{" "}
                      </a>
                      If you are interested in opening a Donor-in-Movement
                      Fund, please fill out this{" "}
                      <Link to="/dmf-form" className="orange-text">
                        {" "}
                        form{" "}
                      </Link> 
                      with the new donor advised fund account.
                    </p>
                    <h4  className="orange-text">Set Password</h4>
                  </div>
          
                <Form onSubmit={handleSubmit} className="signup-form mt-4">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-group">
                        <Input
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          type={!isVisible ? "password" : "text"}
                          value={fields["password"] ? fields["password"] : ""}
                          onChange={(event) => handleChange(event, "password")}
                          invalid={errors["password"] ? true : false}
                        />
                        <span onClick={()=>toggle(1)}>
                            {isVisible ? (
                              <RiEyeFill className="text-dark field-icon"  style={{cursor:"pointer" }} />
                            ) : (
                              <RiEyeOffFill className="text-dark field-icon"  style={{cursor:"pointer" }} />
                            )}
                          </span>
                        <FormFeedback>{errors["password"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-group">
                        <Input
                          id="password_confirmation"
                          name="password_confirmation"
                          placeholder="Enter your confirm password"
                          type={!isPasswordVisible ? "password" : "text"}
                          value={
                            fields["password_confirmation"]
                              ? fields["password_confirmation"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "password_confirmation")
                          }
                          invalid={
                            errors["password_confirmation"] ? true : false
                          }
                        />
                         <span onClick={()=>toggle(2)}>
                            {isPasswordVisible ? (
                              <RiEyeFill className="text-dark field-icon"  style={{cursor:"pointer" }} />
                            ) : (
                              <RiEyeOffFill className="text-dark field-icon"  style={{cursor:"pointer" }} />
                            )}
                          </span>
                        <FormFeedback>
                          {errors["password_confirmation"]}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="text-end">
                      <Button
                        color="success"
                        size="lg"
                        type="submit"
                        disabled={loader}
                        className="submit-button"
                      >
                        {loader ? <Spinner size="sm" className="mx-1" /> : ""}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Index;
