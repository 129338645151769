import { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Tooltip } from "bootstrap";
import moment from "moment";

function MyCalendar({ dmfData, setCurrentMonth }) {
  console.log(dmfData);
  const [view, setView] = useState("dayGridMonth");
  const calendarRef = useRef(null);
  let tooltipInstance = null;

  const handleDatesSet = (info) => {
    const start = info.view.currentStart;
    const monthName = start.toLocaleString("default", { month: "long" });
    const monthCount = moment().month(monthName).format("M");
    const calender_date = `${moment().year()}-${monthCount}-01`;
    setCurrentMonth(calender_date);
  };

  // const eventContent = (arg) => {
  //   if (dmfData.length === 0) {
  //     return <div>No events to display</div>;
  //   }
  // };

  const handleChangeView = (event) => {
    setView(event.target.value);
    calendarRef.current.getApi().changeView(event.target.value);
  };

  function handleEventClick(eventClickInfo) {
  }

  const handleMouseEnter = (info) => {
    if (info.event._def.extendedProps.NewTitle) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event._def.extendedProps.NewTitle,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });
      tooltipInstance.show();
    }
  };

  const handleMouseLeave = () => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  return (
    <div className="calendar">
      <div className="calender_sorting">
        <select
          id="calendar-view-select"
          value={view}
          onChange={handleChangeView}
        >
          <option value="dayGridMonth">Month</option>
          <option value="dayGridWeek">Week</option>
          <option value="dayGridDay">Day</option>
          <option value="listMonth">List</option>
        </select>
      </div>
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          ref={calendarRef}
          events={dmfData}
          initialView={view}
          eventClick={handleEventClick}
          eventContent={dmfData.length === 0 && "No events to display"}
          headerToolbar={{
            start: "prev,next",
            center: "title",
            end: ``,
          }}
          datesSet={handleDatesSet}
          eventMouseEnter={handleMouseEnter}
          eventMouseLeave={handleMouseLeave}
          height="510px"
          weekends={true}
        />
      </div>
    </div>
  );
}

export default MyCalendar;
