import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button, Card, CardBody } from "reactstrap";
import Search from "../Search";
import DmfSubmission from "./Dmf";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import EditDmf from "./EditDmf";
import { BsSearch } from "react-icons/bs";
import Export from "../Export";
import dmf from "../../Services/dmfsubmission";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const [dmfsubmission, setDmfSubmission] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [dmfId, setDmfId] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState({});
  const [searchOpen, setsearchOpen] = useState(false);
  const [exportFields, setExportFields] = useState({});
  // const [searchParams, setSearchParams] = useState({});
  const [searchFields, setSearchFields] = useState([
    { label: "First Name", name: "first_name", type: "text", col: 4 },
    { label: "Last Name", name: "last_name", type: "text", col: 4 },
    { label: "Email", name: "email", type: "text", col: 4 },
  ]);
  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const openEditModal = (dmf_id) => {
    setOpen(true);
    setDmfId(dmf_id);
  };

  const toggleSearch = () => {
    setsearchOpen(!searchOpen);
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction, 
    });
  };

  const getDmfSubmission = (fields = {}) => {
    setLoader(true);
    let params;
    if (Object.keys(searchValue).length > 0) {
      params = {
        ...fields,
        ...searchValue,
        ...sort,
        page,
      };
    } else {
      params = { ...fields, ...sort, page };
    }
    if (typeof window !== undefined) {
      dmf
        .list(params)
        .then((res) => {
          if (res?.data?.status) {
            if (res?.data?.data?.data?.length < 1) {
              setIsDisabled(true);
            } else {
              setIsDisabled(false);
            }
            setDmfSubmission(res.data.data.data);
            if (searchValue == null) {
              setPage(res.data.data.current_page);
            }
            setTotalPages(
              Math.ceil(res.data.data.total / res.data.data.per_page)
            );
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  const deleteDmfSubmission = (id) => {
    if (window.confirm("Are you sure to delete this DMF Submission?")) {
      dmf.delete(id).then((res) => {
        if (res.data.status) {
          const newDmf = dmfsubmission.filter(
            (item) => parseInt(item.id) !== parseInt(id)
          );
          setDmfSubmission(newDmf);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const getSearchFields = (fields = {}) => {
    setExportFields(fields);
    if (
      Object.keys(searchValue).length === 0 &&
      Object.keys(fields).length === 0 || page == 1
    ) {
      getDmfSubmission(fields);
    } else {
      setPage(1);
    }
    setTotalPages(totalPages / 10);
  };


  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.selected+1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`
    });
  };

  useEffect(() => {
    getDmfSubmission(page, sort);
  }, [page, sort]);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>DMF : seeding justice</title>
      </Helmet>
      <Row>
        <Col lg={8} md={8} sm={12}>
          <h4 className="mb-0 title-font">DMF Submissions</h4>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <p className="mb-0">DMF Submissions</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col lg={4} md={4} sm={12} className="text-end ms-auto">
          <Row>
            <Col sm={12} md={6}>
              <Export
                isdisabled={isdisabled}
                service={dmf}
                fileName={"DMF-Submissions"}
                exportExcel={true}
                fields={exportFields}
                // className="button-top-action"
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                className="mt-2 mt-md-0 search_button"
                size="md"
                block
                style={{ borderRadius: "30px" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Search
        isOpen={searchOpen}
        isdisabled={isdisabled}
        searchFields={searchFields}
        service={dmf}
        fileName={"DMF-Submissions"}
        exportExcel={true}
        heading="Search DMF Submission"
        onSubmit={getSearchFields}
        setFields={setSearchValue}
        fields={searchValue}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <LoadingOverlay
                active={loader}
                spinner={
                  <Spinner
                    style={{ height: 60, width: 60, color: "#00344B" }}
                  />
                }
                fadeSpeed={100}
                classNamePrefix="mitiz"
              >
                <Table responsive className="newThemeTable" style={{minWidth:"1400px"}}>
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0">
                        S.No.
                      </th>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "first_name")}
                        >
                          Name
                        </span>
                      </th>
                      {/* <th scope="col">
                        <span
                          onClick={(event) => sortRecord(event, "email")}
                          className="border-top-0 sortable"
                        >
                          Email
                        </span>
                      </th> */}

                      <th scope="col">Amount</th>
                      <th scope="col">
                        <span
                          className="border-top-0 sortable sort"
                          onClick={(event) => sortRecord(event, "created_at")}
                        >
                          Added On
                        </span>
                      </th>
                      <th scope="col">Initial Email</th>
                      <th scope="col">Intake Form</th>
                      <th scope="col">Request Sign</th>
                      <th scope="col">Contract Sign </th>

                      <th scope="col" className="border-top-0">
                        Move to Donor
                      </th>
                      <th scope="col" className="border-top-0 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: 350 }}>
                    {dmfsubmission.length > 0
                      ? dmfsubmission.map((dmf, index) => (
                          <DmfSubmission
                            dmf={dmf}
                            key={`key-dmf-${index}`}
                            openEditModal={openEditModal}
                            deleteDmfSubmission={deleteDmfSubmission}
                            getDmfSubmission={getDmfSubmission}
                            // downloadPDF={downloadPDF}
                          />
                        ))
                      : !loader && (
                          <tr>
                            <td key={0} colSpan="8">
                              <p className="text-center">Record not found.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </LoadingOverlay>
            </Col>
          </Row>
        </CardBody>
      </Card>
          <div className="d-flex justify-content-center">
          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
          </div>
      {open && (
        <EditDmf
          open={open}
          handleClose={() => {
            setOpen();
            setDmfId(null);
          }}
          dmfId={dmfId}
          getDmfSubmission={getDmfSubmission}
        />
      )}
    </div>
  );
};

export default Index;
