import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  Button,
  NavItem,
  TabContent,
  Nav,
  TabPane,
  CardBody,
} from "reactstrap";
import Search from "../Search";
import NominationDetailsBody from "./NominationDetailsBody";
import { BsSearch } from "react-icons/bs";
import nominee from "../../Services/nominee";
import emailtemplate from "../../Services/emailtemplate";
import Export from "../Export";
import GrantNomination from "./GrantLayout/GrantNomination";
import GrantSubmissions from "./GrantLayout/GrantSubmissions";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const [nominations, setNominatios] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [prevetted, setPrevetted] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tabChange, setTabChange] = useState(1);
  const [isdisabled, setIsDisabled] = useState(false);
  const [nominationId, setNominationId] = useState("");
  const [emailLayout, setEmailLayout] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [sort, setSort] = useState({});
  const [searchOpen, setsearchOpen] = useState(false);
  const [exportFields, setExportFields] = useState({});
  const [searchFields, setSearchFields] = useState([
    { label: "Email", name: "email", type: "text", col: 4 },
    { label: "First Name", name: "first_name", type: "text", col: 4 },
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const openEditModal = (nominee_id) => {
    setOpen(true);
    setNominationId(nominee_id);
  };

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.selected + 1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`,
    });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getNominations = (fields = {}) => {
    setLoader(true);
    let params;
    if (tabChange == 1) {
      params = { ...fields, ...searchValue, ...sort, page: page };
    } else if (tabChange == 2) {
      params = {
        ...fields,
        is_prevetted: 1,
        ...searchValue,
        ...sort,
        page: page,
      };
    }
    nominee
      .list(params)
      .then((res) => {
        if (res?.data?.status) {
          if (res.data.data.data.length < 1) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
          tabChange == 1
            ? setNominatios(res.data.data.data)
            : setPrevetted(res.data.data.data);
          if (!searchValue) {
            setPage(res.data.data.current_page);
          }
          setTotalPages(
            Math.ceil(res.data.data.total / res.data.data.per_page)
          );
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };

  const deleteNomination = (id) => {
    if (
      window.confirm(
        `Are you sure to delete this ${
          tabChange ? "Nominations" : "Submissions"
        }?`
      )
    ) {
      nominee.delete(id).then((res) => {
        if (res.data.status) {
          const newNomination = nominations.filter(
            (item) => parseInt(item.id) !== parseInt(id)
          );
          setNominatios(newNomination);
          setPrevetted(newNomination);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const layoutEmail = () => {
    emailtemplate
      .email_layout()
      .then((res) => {
        if (res.data.status) {
          setEmailLayout(res.data.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  const getSearchFields = (
    fields = tabChange == 2 ? { is_prevetted: 1 } : {}
  ) => {
    setExportFields(fields);
    if (page == 1) {
      getNominations(page, sort, tabChange);
    }
    setPage(1);
    setTotalPages(totalPages / 10);
  };

  const handleTabChange = (action) => {
    setPage(1);
    setSearchValue({});
    if (action === 1) {
      setTabChange(1);
    }
    if (action === 2) {
      setTabChange(2);
    }
  };

  useEffect(() => { 
    layoutEmail();
    getNominations(page, sort, tabChange);
  }, [page, sort,]);

  useEffect(() => {
    tabChange == 2 &&
      Object.keys(prevetted).length == 0 &&
      getNominations(page, sort, tabChange);
  }, [tabChange]);

  const layOutProps = {
    openEditModal,
    deleteNomination,
    sortRecord,
    handlePageChange,
    emailLayout,
    loader,
    totalPages,
    sort,
    page,
    nominations,
    getNominations,
    prevetted,
  };

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Grant Nomination : seeding justice</title>
      </Helmet>

      <Row>
        <Col md={8} sm={12}>
          <h4 className="mb-0 title-font">
            {tabChange == 1 ? "Grant Nominations" : "Grant Submissions"}
          </h4>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <p className="mb-0">
                  {tabChange == 1 ? "Grant Nominations" : "Grant Submissions"}
                </p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col md={4} sm={12} className="text-end">
          <Row>
            <Col sm={12} md={6}>
              <Export
                exportExcel={true}
                isdisabled={isdisabled}
                service={nominee}
                tabChange={tabChange}
                searchFields={searchFields}
                fileName={tabChange == 1 ? "Nominations" : "Submissions"}
                fields={exportFields}
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                size="md"
                block
                className="mt-md-0 mt-2 search_button"
                style={{ borderRadius: "30px" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="mt-4">
        <CardBody>
          <div className="defalut-overview">
            <Nav tabs className="border-0">
              <Col md={8} sm={12}>
                <Row className="m-0 nomination_module">
                  <Col
                    md={5}
                    sm={6}
                    xs={6}
                    className={
                      tabChange == 1 ? "active_grant_tab" : "grant_tab"
                    }
                    onClick={() => handleTabChange(1)}
                    style={{ width: "270px" }}
                  >
                    <NavItem>
                      <h5>Grant Nominations</h5>
                    </NavItem>
                  </Col>
                  <Col
                    md={5}
                    sm={6}
                    xs={6}
                    className={
                      tabChange != 1 ? "active_grant_tab" : "grant_tab"
                    }
                    onClick={() => handleTabChange(2)}
                  >
                    <NavItem>
                      <h5>Grant Submissions</h5>
                    </NavItem>
                  </Col>
                </Row>
              </Col>
            </Nav>

            <div
              className={
                tabChange == 1
                  ? "customise_table grant_customise_table "
                  : "customise_table border-r6"
              }
            >
              <Search
                isOpen={searchOpen}
                exportExcel={true}
                isdisabled={isdisabled}
                nominatios={nominations}
                service={nominee}
                fileName={"Nominations"}
                tabChange={tabChange}
                searchFields={searchFields}
                heading={
                  tabChange == 1 ? "Search Nominations" : "Search Submissions"
                }
                onSubmit={getSearchFields}
                setFields={setSearchValue}
                fields={searchValue}
              />

              <TabContent className="">
                {tabChange == 1 ? (
                  <TabPane className="nomination_table">
                    <GrantNomination {...layOutProps} />
                  </TabPane>
                ) : (
                  <TabPane className="submission_table">
                    <GrantSubmissions {...layOutProps} />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </CardBody>
      </div>
      {open && (
        <NominationDetailsBody
          open={open}
          handleClose={() => {
            setOpen();
            setNominationId(null);
          }}
          tabChange={tabChange}
          nominationId={nominationId}
          getNominations={getNominations}
        />
      )}
    </div>
  );
};

export default Index;
