import http from "./http";

const grantee = {
  list: (param) => http.get("/admin/grantees", { params: param }),
  getOne: (param) => http.get(`/admin/grantees/${param}`),
  send: (param) => http.post(`/admin/nomination/move-to-grantee/${param}`),
  getExcel: (param) =>
    http.get("/admin/grantees/export", { params: param, responseType: "blob" }),
};

export default grantee;
