import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import grantee from "../../Services/grantee";
const MarkAsGrantee = (props) => {
  const [isloader, setIsloader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = props.id;
    setIsloader(true);
    grantee
      .send(params)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleClose();
          props.getNominations();
          setIsloader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
  };

  return (
    <div>
      <Modal isOpen={props.granteeOpen} size="md" className="details_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          Confirmation
        </ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <p>Are you sure you want to approve it to make Grantee?</p>

                <ModalFooter>
                  <Button
                    type="button"
                    size="md"
                    className="me-1 cancel_modal"
                    onClick={props.handleClose}
                    disabled={isloader}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    className="me-1 submit-Id-button"
                    disabled={isloader}
                  >
                    {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
                    Approve
                  </Button>
                </ModalFooter>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MarkAsGrantee;
