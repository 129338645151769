import React from "react";
import { Col, Row } from "reactstrap";
import Sidebar from "../Sidebar";
import PrevettedGrantee from "./PrevettedGrantee";

const Index = () => {

  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <PrevettedGrantee />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
