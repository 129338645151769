import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Spinner,
  Label,
  Container,
  FormFeedback,
  Button,
} from "reactstrap";
import { Helmet } from "react-helmet";
import "react-phone-input-2/lib/style.css";
import common from "../../Services/common";
import prevettedsj from "../../Services/prevettedGrantees";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiArrowBack } from "react-icons/bi";

const Index = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const email = query.get("email");
  const first_name = query.get("first_name");
  const last_name = query.get("last_name");
  const org_name = query.get("org_name");
  const [fields, setFields] = useState({ email, first_name, last_name });
  const [errors, setErrors] = useState({});
  const [validToken, setValidToken] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isloader, setIsLoader] = useState(false);
  const [isotpSent, setIsOtpSent] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [emailVerifyMessage, setEmailVerifyMessage] = useState("");
  const [invaliOTP, setInvalidOTP] = useState("");
  const[resend,setResend] = useState(0)

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First name can not be empty";
      formIsValid = false;
    }
    if (
      fields.first_name &&
      !common.isValidName(fields["first_name"])
    ) {
      errors["first_name"] = "Enter valid first name!";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last name can not be empty";
      formIsValid = false;
    }
    if (
      fields.last_name &&
      !common.isValidName(fields["last_name"])
    ) {
      errors["last_name"] = "Enter valid last name!";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (!fields.email && !common.isValidEmail(fields.email)) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.password) {
      errors["password"] = "Password can not be empty";
      formIsValid = false;
    } else if (fields.password.length < 8) {
      errors["password"] = "Password must be 8 character";
      formIsValid = false;
    } else if (fields.password_confirmation !== fields.password) {
      errors["password_confirmation"] = "Confirm password did not match";
      formIsValid = false;
    }
    if (isotpSent && !fields.email_verification_code) {
      errors["email_verification_code"] = "Email verification can not be empty";
      setInvalidOTP("")
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  console.log(invaliOTP, errors);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
    if (field === "email") {
      setEmailVerifyMessage("");
    }
  };

  const getToken = () => {
    let params = { token: token };
    prevettedsj
      .prevettedToken(params)
      .then((res) => {
        if (res.data.status) {
          setValidToken(true);
        } else {
          navigate("/login");
          setValidToken(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      params["token"] = token;
      setLoader(true);
      prevettedsj
        .createprevetted(params)
        .then((res) => {
          setLoader(true);
          if (res?.data?.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoader(false);
            navigate("/success-prevetted");
            setFields({});
          }
           else if (res?.data?.data?.email_verification == true) {
            setEmailVerifyMessage(res?.data); //email verify msg
            setLoader(false);
          } 
          
          else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setInvalidOTP(res?.data?.message);
            setErrors(errors);
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  const handleSendVerification = (e) => {
    e.preventDefault();
    let params = fields;
    params["token"] = token;
    params["email"] = fields.email;
    params["resend"] = resend;
    setIsLoader(true);
    prevettedsj
      .sendVerification(params)
      .then((res) => {
        if (res?.data?.status) {
          fields.email_verification_code = "";
          setInvalidOTP("");
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setResend(1);
          setIsOtpSent(true);
          setIsLoader(false);
          setTimeRemaining(30);
        } else {
          let errors = {};
          for (let key in res.data.message) {
            errors[key] = res.data.message[key];
          }
          setErrors(errors);
          setIsLoader(false);
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoader(false);
        
      });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9\b]+$/.test(keyValue);
    if (!isValidInput) {
      event.preventDefault();
    }
  };
  
  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (isotpSent && timeRemaining >= 0) {
      const timer = setTimeout(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isotpSent, timeRemaining]);

  return (
    <>
      <Helmet>
        <title>prevetted : seeding-Justice</title>
      </Helmet>
      <section className="homepage_banner">
        <Container style={{ minHeight: "500px" }}>
          <Row>
            {validToken ? (
              <Col className="col-md-6 offset-md-3">
                <Card className="login login_bg">
                  <CardBody>
                    <div className="text-center">
                      <h1>Prevetted SJ Grantee</h1>
                      <h6>
                        {isotpSent
                          ? "Verify OTP"
                          : `Create Prevetted SJ Grantee for ${org_name}`}
                      </h6>
                    </div>
                    <Form onSubmit={handleSubmit} className="signup-form mt-4">
                      {isotpSent ? (
                        <Row className="d-flex justify-content-center">
                          <Col md={8}>
                            <FormGroup className="form-group">
                              <Label
                                for="email_verification_code"
                                className="prevettedtext"
                              >
                                Email Verification Code
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="email_verification_code"
                                maxLength={6}
                                onKeyPress={(event) =>
                                  handleKeyPress(
                                    event,
                                    "email_verification_code"
                                  )
                                }
                                value={
                                  fields["email_verification_code"]
                                    ? fields["email_verification_code"]
                                    : ""
                                }
                                onChange={(event) =>
                                  handleChange(event, "email_verification_code")
                                }
                                invalid={
                                  errors["email_verification_code"]
                                    ? true
                                    : false
                                }
                              />
                              {isotpSent && timeRemaining > 0 ? (
                                <>
                                  <p>Resend OTP in {timeRemaining} seconds</p>
                                </>
                              ) : (
                                <Link onClick={handleSendVerification}>
                                  Resend OTP
                                </Link>
                              )}
                              {errors["email_verification_code"] ? <p className="text-danger">
                                {errors["email_verification_code"]}
                              </p> : 
                              <p className="text-danger">
                                {typeof invaliOTP === "object"
                                  ? ""
                                  : invaliOTP}
                              </p>}
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row>
                            <Col md={6}>
                              <FormGroup className="form-group">
                                <Label
                                  for="first_name"
                                  className="prevettedtext"
                                >
                                  First Name
                                </Label>
                                <Input
                                  id="first_name"
                                  name="first_name"
                                  placeholder="First Name"
                                  type="text"
                                  invalid={errors["first_name"] ? true : false}
                                  value={
                                    fields["first_name"]
                                      ? fields["first_name"]
                                      : ""
                                  }
                                  onChange={(event) =>
                                    handleChange(event, "first_name")
                                  }
                                />
                                <FormFeedback>
                                  {errors["first_name"]}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="form-group">
                                <Label
                                  for="last_name"
                                  className="prevettedtext"
                                >
                                  Last Name{" "}
                                </Label>
                                <Input
                                  id="last_name"
                                  name="last_name"
                                  placeholder="Last Name"
                                  type="text"
                                  invalid={errors["last_name"] ? true : false}
                                  value={
                                    fields["last_name"]
                                      ? fields["last_name"]
                                      : ""
                                  }
                                  onChange={(event) =>
                                    handleChange(event, "last_name")
                                  }
                                />
                                <FormFeedback>
                                  {errors["last_name"]}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormGroup className="form-group">
                                <Label for="email" className="prevettedtext">
                                  Email
                                </Label>
                                <Input
                                  id="email"
                                  name="email"
                                  placeholder="Email"
                                  type="text"
                                  invalid={errors["email"] ? true : false}
                                  value={fields["email"] ? fields["email"] : ""}
                                  onChange={(event) =>
                                    handleChange(event, "email")
                                  }
                                />
                                <FormFeedback>{errors["email"]}</FormFeedback>
                                {emailVerifyMessage?.data?.email_verification ==
                                  true &&
                                  emailVerifyMessage?.message &&
                                  !errors["email"] && (
                                    <p className="text-danger mb-2">
                                      <Fragment>
                                        {emailVerifyMessage?.message}{" "}
                                      </Fragment>
                                      {isloader ? (
                                        <Spinner size="sm" className="mx-1" />
                                      ) : (
                                        <Link onClick={handleSendVerification}>
                                          Click here{" "}
                                        </Link>
                                      )}
                                      to verify
                                    </p>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <FormGroup className="form-group">
                                <Label for="password" className="prevettedtext">
                                  Password
                                </Label>
                                <Input
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  type="password"
                                  invalid={errors["password"] ? true : false}
                                  value={
                                    fields["password"] ? fields["password"] : ""
                                  }
                                  onChange={(event) =>
                                    handleChange(event, "password")
                                  }
                                />
                                <FormFeedback>
                                  {errors["password"]}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup className="initial_fund">
                                <Label
                                  for="password_confirmation"
                                  className="prevettedtext"
                                >
                                  Password Confirmation
                                </Label>

                                <Input
                                  id="password_confirmation"
                                  name="password_confirmation"
                                  placeholder="Password Confirmation"
                                  type="password"
                                  invalid={
                                    errors["password_confirmation"]
                                      ? true
                                      : false
                                  }
                                  value={
                                    fields["password_confirmation"]
                                      ? fields["password_confirmation"]
                                      : ""
                                  }
                                  onChange={(event) =>
                                    handleChange(event, "password_confirmation")
                                  }
                                />
                                {errors["password_confirmation"] && (
                                  <small className="fa-1x text-danger">
                                    {errors["password_confirmation"]}
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className="otp_form">
                        {isotpSent ? (
                          <div>
                            <Button
                              color="secondary"
                              size="lg"
                              type="button"
                              onClick={() => setIsOtpSent(false)}
                            >
                              <BiArrowBack />
                              Go Back
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <Button
                            color="success"
                            size="lg"
                            type="submit"
                            disabled={loader}
                            style={{
                              backgroundColor: "#E26A38",
                              borderColor: "#E26A38",
                            }}
                          >
                            {loader ? (
                              <Spinner size="sm" className="mx-1" />
                            ) : (
                              ""
                            )}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <div className="vh-100 d-flex justify-content-center align-items-center">
                <Spinner
                  size="sm"
                  className="mx-1"
                  style={{ height: "80px", width: "80px", color: "#00344B" }}
                />
              </div>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Index;
