import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import adminsetting from "../../../Services/adminsettings";
import common from "../../../Services/common";
import SettingSidebar from "../SettingSidebar";
const Index = () => {
  const email = useSelector((state) => state.email);
  const [fields, setFields] = useState({ email });
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      setIsloader(true);
      adminsetting.updateEmail(params).then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsloader(false);
          setErrors({});
          localStorage.setItem("email", fields.email);
          let payload = { email: fields.email };
          dispatch({ type: "UPDATE-EMAIL", ...payload });
        } else {
          let errors = {};
          // for (let key in res.data.errors) {
          //   errors[key] = res.data.errors[key];
          // }
          setErrors(res.data.message.email[0]);
          setIsloader(false);
        }
      });
    }
  };
  return (
    <div className="mt-4" style={{ minHeight: 300 }}>
      <Row>
        <Col md={4} lg={3}>
          <SettingSidebar />
        </Col>
        <Col md={8} className="mt-2" lg={9}>
          <Card className="shadow support_aside mb-5" style={{ width: "100%",minHeight:"400px" }}>
            <CardHeader className="py-3">Update Email</CardHeader>

            <CardBody>
              <Form onSubmit={handleEmailSubmit}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder=""
                    type="text"
                    value={fields["email"] ? fields["email"] : ""}
                    onChange={(event) => handleChange(event, "email")}
                    invalid={errors["email"] ? true : false}
                  />
                  <FormFeedback>{errors["email"]}</FormFeedback>
                </FormGroup>
                <Col className="d-flex justify-content-start">
                  <Button
                    color="success"
                    className="submit-Id-button"
                    type="submit"
                    disabled={isloader}
                  >
                    {isloader && <Spinner size="sm" className="mx-1" />}
                    Submit
                  </Button>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
