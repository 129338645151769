import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Card,
  CardBody,
  CardFooter,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
} from "reactstrap";
import report from "../../Services/quarterReport";
import {
  FaFilePdf,
  FaFileWord,
  FaFileImage,
  FaFileExcel,
  FaFileCsv,
} from "react-icons/fa";
import user from "../../Services/user";
import { BsTrashFill } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import { BsFiletypeDoc } from "react-icons/bs";
import classnames from "classnames";

const QuarterlyReports = (props) => {
  const [fields, setFields] = useState({});
  const [file, setFile] = useState(null);
  const [reports, setReports] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState("1");

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];

  const year = new Date().getFullYear();
  const years = Array.from(new Array(5), (val, index) => year - index);
  const quarters = ["1", "2", "3", "4"];

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleTabChange = (tabs) => {
    if (activeTabs !== tabs) {
      setActiveTabs(tabs);
      setFields({});
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.quarter) {
      errors["quarter"] = "Please Choose quarter";
      formIsValid = false;
    }
    if (!fields.report_year) {
      errors["report_year"] = "Please Choose Year";
      formIsValid = false;
    }
    if (!file) {
      errors["file"] = "The file cannot be empty";
    } else if (file && !allowedFileTypes.includes(file.type)) {
      errors["file"] =
        "The file must be a file of type: jpg, jpeg, png, xlsx,xls,csv,doc,docx,pdf";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleFileUpload = (event) => {
    const tempFile = event.target.files[0];
    if (tempFile) {
      if (allowedFileTypes.includes(tempFile.type)) {
        try {
          const objectURL = URL.createObjectURL(tempFile);
          setFile(tempFile);
          setErrors({});
        } catch (error) {
          console.error("Error creating object URL:", error);
          setFile(null);
          setErrors({ file: "An error occurred while processing the file." });
        }
      } else {
        setFile(null);
        setErrors({
          file: "The file must be a file of type: jpg, jpeg, png, xlsx, xls, csv,doc,docx,pdf",
        });
      }
    } else {
      setFile(null);
      setErrors({ file: "The file cannot be empty" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("quarter", fields.quarter);
      formData.append("report_year", fields.report_year);
      formData.append("user_id", props.id);
      setIsloader(true);
      report
        .add(formData)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({ ...fields, quarter: fields.quarter });
            setFile(null);
            document.getElementById("file").value = "";
            getQuarterReports();
            setIsloader(false);
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };

  const getQuarterReports = () => {
    setLoader(true);
    let params =
      activeTabs == "1"
        ? {
            id: props.id,
            quarter: fields.quarter,
            report_year: fields.report_year,
          }
        : {
            id: props.id,
          };
    report
      .list(params)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          setReports(res.data.data);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const deleteReport = (id) => {
    if (window.confirm("Are you sure to delete this Quarter-Report?")) {
      report.delete(id).then((res) => {
        if (res.data.status) {
          const newReport = reports.filter((item) => item.id !== id);
          setReports(newReport);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const downloadFile = (id, file_ext, index) => {
    setLoading(id);
    let params = id;
    user.filedownload(params).then((res) => {
      setLoader(false);
      if (res.data) {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Quarterly-${reports[index]?.quarter}-Report-File-${reports[index]?.report_year}.${file_ext}`
          );
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    });
  };

  useEffect(
    () => {
      getQuarterReports();
    },
    activeTabs == "1" ? [fields] : []
  );

  return (
    <div>
      <Modal
        isOpen={props.reportOpen}
        size="lg"
        className="details_modal user_modal"
      >
        <ModalHeader toggle={() => props.handleClose()}>
          Quarterly Reports
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col md={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                    style={{cursor:"pointer"}}
                      className={classnames({
                        active: activeTabs === "1",
                      })}
                      onClick={() => {
                        handleTabChange("1");
                      }}
                    >
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                    style={{cursor:"pointer"}}
                      className={classnames({
                        active: activeTabs === "2",
                      })}
                      onClick={() => {
                        handleTabChange("2");
                      }}
                    >
                      Add
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              {errors.quarter && (
                <Col md={12} className="text-danger">
                  {errors.quarter}
                </Col>
              )}

              <TabContent activeTab={activeTabs}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Label for="report_year">Choose Year</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          name="report_year"
                          value={
                            fields["report_year"] ? fields["report_year"] : ""
                          }
                          onChange={(event) => {
                            handleChange(event, "report_year");
                          }}
                          invalid={errors["report_year"] ? true : false}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">
                            {years.length === 0 ? "Loading..." : "-Select-"}
                          </option>
                          {years.map((year, index) => {
                            return (
                              <option key={`key-year${index}`} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors["report_year"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Label for="quarter">Choose Quarter</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          name="quarter"
                          value={fields["quarter"] ? fields["quarter"] : ""}
                          onChange={(event) => handleChange(event, "quarter")}
                          invalid={errors["quarter"] ? true : false}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">
                            {quarters.length === 0 ? "Loading..." : "-Select-"}
                          </option>
                          {quarters.map((quarter, index) => {
                            return (
                              <option
                                key={`key-quarter${index}`}
                                value={quarter}
                              >
                                {`Quarter ${quarter}`}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors["quarter"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Label for="report_year">Choose Years</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          name="report_year"
                          value={
                            fields["report_year"] ? fields["report_year"] : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "report_year")
                          }
                          invalid={errors["report_year"] ? true : false}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">
                            {years.length === 0 ? "Loading..." : "-Select-"}
                          </option>
                          {years.map((year, index) => {
                            return (
                              <option key={`key-year${index}`} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors["report_year"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Label for="quarter">Choose Quarter</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          name="quarter"
                          value={fields["quarter"] ? fields["quarter"] : ""}
                          onChange={(event) => handleChange(event, "quarter")}
                          invalid={errors["quarter"] ? true : false}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">
                            {quarters.length === 0 ? "Loading..." : "-Select-"}
                          </option>
                          {quarters.map((quarter, index) => {
                            return (
                              <option
                                key={`key-quarter${index}`}
                                value={quarter}
                              >
                                {`Quarter ${quarter}`}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors["quarter"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label for="file">Attach File</Label>
                        <Input
                          type="file"
                          name="file"
                          id="file"
                          accept=".jpg, .jpeg, .png, .xlsx, .xls, .csv, .doc, .docx, .pdf"
                          onChange={handleFileUpload}
                          invalid={errors["file"] ? true : false}
                        />
                        <FormFeedback>{errors["file"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>

            {activeTabs == "1" && (
              <div>
                {reports != null && reports.length > 0 && (
                  <>
                    <h5>Uploaded Files</h5>
                  </>
                )}

                <Row className="mt-3" style={{ minHeight: "150px" }}>
                  {loader ? (
                    <div className="text-center">
                      <Spinner style={{ width: 50, height: 50 }} />
                    </div>
                  ) : reports != null && reports.length > 0 ? (
                    reports.map((report, i) => (
                      <Col md={3} key={i}>
                        <div className="quarterly-reports-box quarterly-reports-box-secondary">
                          <Card>
                            <CardBody>
                              {report.file_ext === "jpg" && (
                                <FaFileImage size={100} />
                              )}
                              {report.file_ext === "png" && (
                                <FaFileImage size={100} />
                              )}
                              {report.file_ext === "pdf" && (
                                <FaFilePdf size={100} />
                              )}
                              {report.file_ext === "csv" && (
                                <FaFileCsv size={100} />
                              )}
                              {report.file_ext === "xlsx" && (
                                <FaFileExcel size={100} />
                              )}
                              {report.file_ext === "xls" && (
                                <FaFileExcel size={100} />
                              )}
                              {report.file_ext === "docx" && (
                                <FaFileWord size={100} />
                              )}
                              {report.file_ext === "doc" && (
                                <BsFiletypeDoc size={100} />
                              )}
                            </CardBody>
                            <CardFooter className="text-center d-flex">
                              <div className="download-button btn btn-secondary">
                                <BsTrashFill
                                  onClick={() => deleteReport(report.id)}
                                />
                              </div>
                              <div className="download-button btn btn-secondary">
                                {report.id === loading ? (
                                  <Spinner size="sm" className="mt-2" />
                                ) : (
                                  <FaFileDownload
                                    onClick={() =>
                                      downloadFile(
                                        report.id,
                                        report.file_ext,
                                        i
                                      )
                                    }
                                    disabled={report.id === loading}
                                  />
                                )}
                              </div>
                            </CardFooter>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col key={0} md={12}>
                      <p className="text-center">Record not found.</p>
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            {activeTabs == "2" && (
              <Button
                type="submit"
                size="md"
                className="me-1 submit-Id-button"
                disabled={isloader}
              >
                {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default QuarterlyReports;
