import React, { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Spinner,
} from "reactstrap";
import CurrencyFormat from "react-currency-format";
import { GrClose } from "react-icons/gr";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import user from "../Services/user";
import LoadingOverlay from "react-loading-overlay";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import userdashboard from "../Services/userdashboard";

const Is_Ein_No = (props) => {
  const [fields, setFields] = useState(props.initialFields);
  const [granteesData, setGranteesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [einUserInput, setEinUserInput] = useState(false);
  const [selectInput, setSelectInput] = useState("");
  const [selectEinInput, setSelectEinInput] = useState("");
  const [ein, setEin] = useState([]);
  const [val, setVal] = useState(props.initialFields.support_status);
  const { errors, nominationDropdowns } = props;
  LoadingOverlay.propTypes = undefined;

  const handleInputChange = (inputValue, action) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setSelectInput(inputValue);
      setFields({
        ...fields,
        ["org_name_list"]: { label: inputValue, value: inputValue },
        ["org_name"]: inputValue,
        ["support_status"]: val,
      });
    }
    if (inputValue) {
      setFields({ ["org_name"]: inputValue, ["support_status"]: val });
    }
  };

  const handleRadioChange = (sectionId, radioValue) => {
    setVal(radioValue);
    if (radioValue == "option2") {
      setFields({ ...fields, support_queries: "", support_status: radioValue });
    } else {
      delete fields.support_queries;
      setFields({ ...fields, support_status: radioValue });
    }
  };

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
  };

  const handleStateChange = (value) => {
    setFields({
      ...fields,
      ["state_id_list"]: value,
      ["state_id"]: value.value,
    });
  };

  const handleOrgChange = (value, { action }) => {
    setEinUserInput(false);
    if (action == "clear" && !selectInput) {
      setFields({});
    } else {
      setSelectInput("");
      setFields({
        ...fields,
        ["org_name_list"]: value,
        ["org_name"]: value?.value,
      });
      let params = value?.value;
      if (Number.isInteger(params) === true) {
        setLoader(true);
        user
          .prevetted(params)
          .then((res) => {
            if (res?.data?.status) {
              let rex = res.data.data;
              setFields({
                org_name_list: { label: rex?.org_name, value: rex?.id },
                org_name: rex?.org_name,
                ein_number_list: { label: rex?.ein_number, value: rex?.id },
                ein_number: rex?.ein_number,
                city: rex?.city,
                state_id_list: {
                  label: rex?.state?.name,
                  value: rex?.state?.id,
                },
                state_id: rex?.state?.id,
                street: rex?.street,
                org_website: rex?.org_website,
                grant_amount: rex?.grant_amount,
                support_status: val,
              });
            }
            setLoader(false);
          })
          .catch(function (error) {
            console.log("error", error);
            setLoader(false);
          });
      }
    }
  };

  const handleChange = (e, field) => {
    let updatedValue = e.target.value;
  
    if (field === "grant_amount") {
      updatedValue = updatedValue.replace(/\$/g, "").replace(/,/g, "");
    }
  
    setFields({ ...fields, [field]: updatedValue });
  };

  const getGranteelist = () => {
    userdashboard
      .org_list()
      .then((res) => {
        if (res?.data?.status) {
          let grantees = [];
          res.data.data.forEach((grantee, index) => {
            grantees[index] = {
              label: grantee.org_name,
              value: grantee.id,
            };
          });
          setGranteesData(grantees);
          let fields = [];
          res.data.data.forEach((ein, index) => {
            fields[index] = {
              label: ein.org_name + " - " + ein.ein_number,
              value: ein.id,
            };
          });
          setEin(fields);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleEinInputChange = (inputValue, action) => {
    setEinUserInput(true);
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setSelectEinInput(inputValue);
      setFields({
        ...fields,
        ["ein_number_list"]: { label: inputValue, value: inputValue },
        ["ein_number"]: inputValue,
        ["support_status"]: val,
      });
    }
    if (inputValue) {
      setFields({
        ...fields,
        ["ein_number"]: inputValue,
        ["support_status"]: val,
      });
    }
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = granteesData.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      const menuEl = document.querySelector(".organisation-selection__menu");
     if(menuEl){
      if (filteredOptions.length >= 1) {
        menuEl.style.display = "block";
      } else {
        menuEl.style.display = "none";
      }
     }
      callback(filteredOptions);
    }, 0);
  };

  useEffect(() => {
    props.updateNoFields(fields, props.keyValue);
    if (einUserInput == false) {
      setSelectEinInput("");
    }
  }, [fields]);
  console.log(errors);

  useEffect(() => {
    getGranteelist();
  }, []);

  return (
    <>
      <div className="inputFields mt-3 mb-4 add-more-grants pt-4 px-3">
        {props.keyValue > 0 && (
          <span className="d-flex justify-content-end " title="Remove Fields">
            <GrClose
              onClick={() => props.removeYesBlock(props.keyValue)}
              className="close"
              style={{ cursor: "pointer" }}
            />
          </span>
        )}
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 50, width: 50, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="org_name_list">
                  Name of Organization{" "}
                  <span className="text-danger fw-bolder">*</span>
                </Label>
                <AsyncCreatableSelect
                  loadOptions={loadOptions}
                  isClearable={true}
                  defaultOptions={granteesData}
                  formatCreateLabel={() => undefined}
                  onInputChange={handleInputChange}
                  inputValue={selectInput}
                  id="org_name_list"
                  name="org_name_list"
                  placeholder={<div>Write Organization name</div>}
                  value={fields["org_name_list"] ? fields["org_name_list"] : []}
                  onChange={handleOrgChange}
                  classNamePrefix="organisation-selection"
                  className="react_dropdown"
                />
                {errors[`ein_no.${props.keyValue}.org_name`] && (
                  <small className="fa-1x text-danger">
                    {errors[`ein_no.${props.keyValue}.org_name`]}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="city">
                  City <span className="text-danger fw-bolder">*</span>
                </Label>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={fields["city"] ? fields["city"] : []}
                  onChange={(event) => handleChange(event, "city")}
                  invalid={
                    errors[`ein_no.${props.keyValue}.city`] ? true : false
                  }
                />
                <FormFeedback>
                  {errors[`ein_no.${props.keyValue}.city`]}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="state_id_list">
                  State <span className="text-danger fw-bolder">*</span>
                </Label>
                <Select
                  name="state_id_list"
                  id="state_id_list"
                  placeholder={<div>Select State...</div>}
                  value={fields["state_id_list"] ? fields["state_id_list"] : []}
                  options={props.states}
                  classNamePrefix="select"
                  onChange={handleStateChange}
                />
                {errors[`ein_no.${props.keyValue}.state_id`] && (
                  <small className="fa-1x text-danger">
                    {errors[`ein_no.${props.keyValue}.state_id`]}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="street">Street </Label>
                <Input
                  id="street"
                  name="street"
                  type="text"
                  value={fields["street"] ? fields["street"] : []}
                  onChange={(event) => handleChange(event, "street")}
                  invalid={
                    errors[`ein_no.${props.keyValue}.street`] ? true : false
                  }
                />
                <FormFeedback>
                  {errors[`ein_no.${props.keyValue}.street`]}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="org_website">
                  Organization's Website{" "}
                  <span className="text-danger fw-bolder">*</span>
                </Label>
                <Input
                  id="org_website"
                  name="org_website"
                  type="text"
                  placeholder="http or https://example.com"
                  value={fields["org_website"] ? fields["org_website"] : []}
                  onChange={(event) => handleChange(event, "org_website")}
                />
                 <small className="text-secondary">
                  NOTE : Use http or https for Website url <br/> (Ex: https://example.com)
             </small>
             <br/>
                {errors[`ein_no.${props.keyValue}.org_website`] && (
                  <small className="fa-1x text-danger">
                    {errors[`ein_no.${props.keyValue}.org_website`]}
                  </small>
                )}
             
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="initial_fund">
                <Label for="grant_amount">
                  Grant Amount <span className="text-danger fw-bolder">*</span>
                </Label>
                <CurrencyFormat
                  name="grant_amount"
                  placeholder="$0.00"
                  inputmode="numeric"
                  className="form-control"
                  value={fields["grant_amount"] ? fields["grant_amount"] : ""}
                  thousandSeparator={true}
                  prefix={"$"}
                  invalid={
                    errors[`ein_no.${props.keyValue}.grant_amount`]
                      ? true
                      : false
                  }
                  onChange={(event) => handleChange(event, "grant_amount")}
                />
                {errors[`ein_no.${props.keyValue}.grant_amount`] && (
                  <small className="fa-1x text-danger">
                    {errors[`ein_no.${props.keyValue}.grant_amount`]}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="hide-autoselect-icons">
                <Label>
                  EIN Number (To search EIN number{" "}
                  <a
                    href="https://www.guidestar.org/search/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#E26A38" }}
                  >
                    click here
                  </a>
                  )
                </Label>
                <div className="ein_select"> 
                <AsyncCreatableSelect
                  isClearable={true}
                  id="ein_number_list"
                  name="ein_number_list"
                  placeholder={<div>Write EIN number</div>}
                  formatCreateLabel={() => undefined}
                  value={
                    fields["ein_number_list"] ? fields["ein_number_list"] : []
                  }
                  classNamePrefix="select"
                  inputValue={selectEinInput}
                  onInputChange={handleEinInputChange}
                />
                </div>
                {errors[`ein_no.${props.keyValue}.ein_number`] && (
                  <small className="fa-1x text-danger">
                    {errors[`ein_no.${props.keyValue}.ein_number`]}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
        </LoadingOverlay>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="recognition_id">
                Would you like Donor Recognition or Anonymity?{" "}
                <span className="text-danger fw-bolder">*</span>
              </Label>
              <Input
                type="select"
                name="recognition_id"
                style={{cursor:"pointer"}}
                value={fields["recognition_id"] ? fields["recognition_id"] : []}
                onChange={(event) => handleChange(event, "recognition_id")}
                invalid={
                  errors[`ein_no.${props.keyValue}.recognition_id`]
                    ? true
                    : false
                }
              >
                <option value="" selected disabled>
                  {nominationDropdowns.length === 0 ? "Loading..." : "-Select-"}
                </option>
                {nominationDropdowns.length > 0 &&
                  nominationDropdowns.map((dropdowns, i) => (
                    <option value={dropdowns.id} key={`key-dropdowns-${i}`}>
                      {dropdowns.category_name}
                    </option>
                  ))}
              </Input>
              <FormFeedback>
                {errors[`ein_no.${props.keyValue}.recognition_id`]}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="org_contact_name">Organization Contact Name:</Label>
              <Input
                id="org_contact_name"
                name="org_contact_name"
                type="text"
                value={
                  fields["org_contact_name"] ? fields["org_contact_name"] : []
                }
                onChange={(event) => handleChange(event, "org_contact_name")}
              />
              {errors[`ein_no.${props.keyValue}.org_contact_name`] && (
                <small className="fa-1x text-danger">
                  {errors[`ein_no.${props.keyValue}.org_contact_name`]}
                </small>
              )}
              <FormFeedback>
                {errors[`ein_no.${props.keyValue}.org_contact_name`]}
              </FormFeedback>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="org_contact_email">Organization Email:</Label>
              <Input
                id="org_contact_email"
                name="org_contact_email"
                type="text"
                value={
                  fields["org_contact_email"] ? fields["org_contact_email"] : []
                }
                onChange={(event) => handleChange(event, "org_contact_email")}
              />
              {errors[`ein_no.${props.keyValue}.org_contact_email`] && (
                <small className="fa-1x text-danger">
                  {errors[`ein_no.${props.keyValue}.org_contact_email`]}
                </small>
              )}
              <FormFeedback>
                {errors[`ein_no.${props.keyValue}.org_contact_email`]}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="org_phone">Organization's Phone Number:</Label>

              <PhoneInput
                countryCodeEditable={false}
                onlyCountries={["us"]}
                value={fields["org_phone"] ? fields["org_phone"] : ""}
                onChange={(phone) => handlePhone(phone, "org_phone")}
                country="us"
              />

              {errors[`ein_no.${props.keyValue}.org_phone`] && (
                <small className="fa-1x text-danger">
                  {errors[`ein_no.${props.keyValue}.org_phone`]}
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Row>
            <Label>
              Is this for general operating support or project specific support?{" "}
            </Label>

            <Col md={6}>
              <FormGroup check inline>
                <Label>
                  <Input
                    type="radio"
                    checked={val == "option1"}
                    onChange={() =>
                      handleRadioChange(props.keyValue, "option1")
                    }
                  />
                  Operating support
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label>
                  <Input
                    type="radio"
                    checked={val == "option2"}
                    onChange={() =>
                      handleRadioChange(props.keyValue, "option2")
                    }
                  />
                  Project support
                </Label>
              </FormGroup>
            </Col>
          </Row>
          {val == "option2" && (
            <Input
              type="textarea"
              value={fields["support_queries"]}
              onChange={(e) => handleChange(e, "support_queries")}
            />
          )}
          {val == "option2" &&
            errors[`ein_no.${props.keyValue}.support_queries`] && (
              <small className="fa-1x text-danger">
                {errors[`ein_no.${props.keyValue}.support_queries`]}
              </small>
            )}
        </FormGroup>
      </div>
    </>
  );
};

export default Is_Ein_No;
