import http from "./http";

const dmf = {

  add: (data) => http.post("/dmf-submissions", data),
  newsadd: (data) => http.post("/add-subscriber", data),
  list: (param) => http.get("/admin/dmf-submissions/list", { params: param }),
  recent_donors: (param) => http.get("/admin/dmf-submissions/recent-dmf-donors", { params: param }),
  donor: (data) => http.post("/admin/dmf-submissions/move-to-donor", data),
  dmf_dropdowns: (param) => http.get("/dmf-dropdowns", { params: param }),
  states: (param) => http.get("/us-states", { params: param }),
  delete: (param) => http.delete(`/admin/dmf-submissions/${param}`),
  getOne: (param) => http.get(`/admin/dmf-submissions/${param}`),
  update: (data) => http.patch(`/admin/dmf-submissions/${data.id}`, data),
  preview: (param) => http.get("admin/dmf-submissions/email-preview", { params: param }),
  getExcel: (param) => http.get("/admin/dmf-submissions/export", { params: param, responseType: 'blob'}),
  PdfDownload: (param) => http.get("/admin/dmf-submissions/download-signed-doc", { params: param, responseType: 'blob'}),
  getDonor: (param) => http.get(`/admin/dmf-submissions/${param}`),
  sendContractSignEmail: (data) => http.post("/admin/dmf-submissions/send-contract-sign-email", data),
  sendContractIntakeFormEmail: (data) => http.post("/admin/dmf-submissions/send-contract-intake-form-email", data), 
  reminder: (data) => http.post("/admin/dmf-submissions/send-signature-reminder", data), 
  
  update_dmf_details: (data) => http.post("/update-dmf-details", data),
  
  dmfToken: (param) => http.get("/get-dmf-token-status", { params: param }),

  dmf_intake_update: (data) =>
    http.post("/admin/dmf-submissions/update-dmf-legal-info", data),


};




export default dmf;



