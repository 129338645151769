import React, { useState, useEffect, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row, Button, FormGroup, Spinner } from "reactstrap";
import EditPrevettedGranteeModal from "./EditPrevettedGranteeModal";
import ConfirmationModal from "./ConfirmationModal";
import userGrantee from "../../../Services/userGrantee";
import { Link } from "react-router-dom";
const PrevettedGrantee = () => {
  const [fields, setFields] = useState({});
  const [loader, setLoader] = useState(false);
  const [prevettedOpen, setPrevettedOpen] = useState(false);
  const [prevettedId, setPrevettedId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalId, setModalId] = useState("");

  LoadingOverlay.propTypes = undefined;

  const getPrevettedGrantee = (id) => {
    let params = id;
    setLoader(true);
    userGrantee
      .list(params)
      .then((res) => {
        if (res?.data?.status) {
          setFields(res?.data?.data?.data[0]);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const PrevettedModal = (prevettedId) => {
    setPrevettedOpen(true);
    setPrevettedId(prevettedId);
  };

  const hanldleModal = (modalId) => {
    setModalOpen(true);
    setModalId(modalId);
  };

  useEffect(() => {
    getPrevettedGrantee();
  }, []);
  return (
    <LoadingOverlay
      active={loader}
      spinner={<Spinner style={{ height: 60, width: 60, color: "#00344B" }} />}
      fadeSpeed={100}
      classNamePrefix="mitiz"
    >
      <div className="mt-4 mb-5 userAdminArticle">
        <div className="py-3 userAdminArticleHeading">
          <Row>
            <Col md={6} sm={12}>
              {" "}
              <h4 className="mb-0" style={{ color: "#0F354C" }}>
                Recommended Grantee
              </h4>
            </Col>
            <Col md={6} sm={12} className="text-end">
              <Row>
                <Col sm={12} md={6}>
                  {" "}
                  {fields?.update_request_data?.length > 0 &&
                    fields?.update_request_data[0].status === "PENDING" && (
                      <Button
                        size="md"
                        style={{
                          backgroundColor: "#E9494E",
                          borderColor: "#E9494E",
                        }}
                        className="mt-2 mt-md-0 text-white"
                        block
                        onClick={() => hanldleModal()}
                      >
                        Cancel Update Request
                      </Button>
                    )}
                </Col>
                <Col sm={12} md={6}>
                  <Button
                    size="md"
                    block
                    color="success"
                    className="mt-2 mt-md-0"
                    style={{
                      backgroundColor: "#5FC089",
                      borderColor: "#5FC089",
                    }}
                    onClick={() => PrevettedModal(fields?.id)}
                  >
                    Edit My Information
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="mt-4 mb-3">
          <Col md={6}>
            <div className="nomination-box">
              <strong>Organization Name :</strong>{" "}
              {fields?.org_name ? fields?.org_name : "N/A"}
            </div>
          </Col>
          <Col md={6}>
            <div className="nomination-box">
              <strong>EIN Number :</strong>{" "}
              {fields?.ein_number ? fields?.ein_number : "N/A"}
            </div>
          </Col>
        </Row>
        <FormGroup row>
          <Col md={12}>
            <div className="nomination-box">
              <strong>Organization Website :</strong>{" "}
              {/* {fields?.org_website ? fields?.org_website : "N/A"} */}
              {fields?.org_name ? (
                <Link to={fields?.org_website} target="_blank">
                  {fields?.org_website}
                </Link>
              ) : (
                "N/A"
              )}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={12}>
            <div className="nomination-box">
              <strong>Mission :</strong>{" "}
              {fields?.mission ? fields?.mission : "N/A"}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Street :</strong>{" "}
              {fields?.street ? fields?.street : "N/A"}
            </div>
          </Col>
          <Col md={6}>
            <div className="nomination-box">
              <strong>City :</strong> {fields?.city ? fields?.city : "N/A"}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>State :</strong>{" "}
              {fields?.state?.name ? fields?.state?.name : "N/A"}
            </div>
          </Col>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Zip :</strong> {fields?.zip ? fields?.zip : "N/A"}
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md={12}>
            <div className="nomination-box">
              <strong>Priority Population :</strong>{" "}
              {fields?.priority_population
                ? fields?.priority_population
                : "N/A"}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Contact Name :</strong>{" "}
              {fields?.org_contact_name ? fields?.org_contact_name : "N/A"}
            </div>
          </Col>
          <Col md={6}>
            <div className="nomination-box">
              <strong>Contact Email :</strong>{" "}
              {fields?.org_contact_email ? fields?.org_contact_email : "N/A"}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={12}>
            <div className="nomination-box">
              <strong>Geography : </strong> {fields?.geography}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={12}>
            <div className="nomination-box d-flex">
              <strong>Prevetted Categories : </strong>{" "}
              {fields?.prevetted_categories?.map((res, index) => (
                <Fragment key={index}>
                  {(index ? ", " : "") + res.category_name}
                </Fragment>
              ))}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={6}>
            {fields?.logo ? (
              <div className="p-2">
                <img
                  alt="Card"
                  src={fields?.logo}
                  className="img-thumbnail"
                  style={{ width: "130px" }}
                />
              </div>
            ) : (
              <div className="p-2">
                <img
                  alt="Card"
                  src="/assets/no-logo.png"
                  className="img-thumbnail"
                  style={{ width: "130px" }}
                />
              </div>
            )}
          </Col>
        </FormGroup>

        <fieldset className="custom-fieldset">
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>
                  {" "}
                  <a
                    href="https://www.bill.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#E26A38" }}
                  >
                    Bill.com
                  </a>{" "}
                  Vendor Name :
                </strong>{" "}
                {fields?.vendor_name ? fields?.vendor_name : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <div className="nomination-box">
                <strong>
                  {" "}
                  <a
                    href="https://www.bill.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#E26A38" }}
                  >
                    Bill.com
                  </a>{" "}
                  Payment Network ID (PNI) :
                </strong>{" "}
                {fields?.account_number ? fields?.account_number : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                <strong> Additional payment instructions : </strong>{" "}
                {fields?.payment_remarks ? fields?.payment_remarks : "N/A"}
              </div>
            </Col>
          </FormGroup>
        </fieldset>

        {prevettedOpen && (
          <EditPrevettedGranteeModal
            prevettedOpen={prevettedOpen}
            handleClose={() => {
              setPrevettedOpen();
              setPrevettedId(null);
            }}
            prevettedId={fields?.id}
            getPrevettedGrantee={getPrevettedGrantee}
          />
        )}
        {modalOpen && (
          <ConfirmationModal
            modalOpen={modalOpen}
            handleClose={() => {
              setModalOpen();
              setModalId(null);
            }}
            prevettedId={fields?.update_request_data[0]?.id}
            getPrevettedGrantee={getPrevettedGrantee}
          />
        )}
      </div>
    </LoadingOverlay>
  );
};

export default PrevettedGrantee;
