import React, { useState } from "react";
import { Fragment } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Button, Spinner } from "reactstrap";

const Index = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [isloader, setIsloader] = useState(false);

  const exportRecord = () => {
    setIsloader(true);
    setDisabled(true);
    let params =  {
      ...props.fields,
    };
    if(props?.tabChange === false){
      params = {
        ...props.fields, is_prevetted : 1
      };  
    }
    let services = props.service;
    if (services) {
      services.getExcel(params).then((res) => {
        setIsloader(false);
        if (res.data) {
          try {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${props.fileName ? props.fileName : "example"}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            setDisabled(false);
          } catch (error) {
            setIsloader(false);
            setDisabled(false);
            console.log(error);
          }
        }
      });
    } else {
      console.log("NO SERVICES FOUND IN PROPS");
    }
  };

  return (
    <Fragment>
      {props.exportExcel && (
        <Button
          style={{ borderRadius: '30px' }}
          // color="outline-info"
          disabled={disabled ? disabled : props.isdisabled}
          onClick={exportRecord}
          className={`d-block w-100 export_button ${props.className}`}
        >
          {isloader ? (
            <Spinner size="sm" className="mx-1" />
          ) : (
            <FaFileDownload className="me-1" />
          )}
          Export
        </Button>
      )}
    </Fragment>
  );
};

export default Index;
