import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { Helmet } from "react-helmet";
import NominationForm from "../../Common/NominationForm";

const NomineeForm = () => {
  return (
    <>
      <Helmet>
        <title>Nomination : seeding-Justice</title>
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <NominationForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NomineeForm;
