import React, { Fragment, useState } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import PrevettedDetailsModal from "./PrevettedDetailsModal";
import { BiChevronRight, BiGlobe } from "react-icons/bi";
const PrevettedData = (props) => {
  const sjGrantee = props.sjgrantee;
  const { city, logo, org_name, id, org_website, state, zip, mission } =
    sjGrantee;

  const [open, setOpen] = useState(false);
  const [prevettedId, setPrevettedId] = useState(false);

  const prevettedModal = (id) => {
    setOpen(true);
    setPrevettedId(id);
  };

  return (
    <Fragment>
      <Col md={12}>
        <Card className="overflow-hidden card_prevetted">
          <Row className="p-0 m-0">
            <Col md={2} className="d-flex align-items-center p-2">
              {logo ? (
                <div className="p-2 card_prevetted_left">
                  <img alt="Card" src={logo} className="img-fluid" />
                </div>
              ) : (
                <div className="p-2 card_prevetted_left">
                  <img
                    alt="Card"
                    src="./assets/no-logo.png"
                    className="img-fluid"
                  />
                </div>
              )}
            </Col>
            <Col md={7} className="border-1 pt-3 pb-3 position-relative">
              <div className="cultural-preservation">
                <h5 style={{ textAlign: "initial" }}>{org_name}</h5>
                <hr />
                <div className="d-flex flex-column ">
                  <div className="content-align">
                    <span>
                      <BiChevronRight />
                    </span>
                    {mission}
                  </div>
                  <div className="content-align">
                    <span>
                      <BiChevronRight />
                    </span>
                    {city}, {state?.name}, {zip}
                  </div>
                  <a
                    href={org_website}
                    target="_blank"
                    rel="noreferrer"
                    className="content-align"
                  >
                    <span>
                      <BiGlobe />
                    </span>
                    {org_website}
                  </a>
                </div>
              </div>
            </Col>
            <Col
              md={3}
              className="d-flex align-items-center text-end"
            >
              <div className="card_prevetted_right p-4">
                <Button
                  block
                  className="btn btn-sm m-auto orange-button"
                  color="warning"
                  onClick={() => prevettedModal(id)}
                >
                  Submit Grant Now
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      {open && (
        <PrevettedDetailsModal
          sjGrantee={sjGrantee}
          close={setOpen}
          open={open}
          prevettedId={prevettedId}
        />
      )}
    </Fragment>
  );
};

export default PrevettedData;
