import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import dmf from "../../Services/dmfsubmission";

const HelloSignConfirmation = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);

  const sendRequest = () => {
    let params = fields;
    params["id"] = props.SignRequestId;
    params[props.modalData.action] = true;
    setIsloader(true);
    if (props.modalData.action == "sign_contract") {
      dmf
        .sendContractSignEmail(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.handleClose();
            props.getDmfSubmission();
            setIsloader(false);
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    } else {
      dmf
        .reminder(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.handleClose();
            props.getDmfSubmission();
            setIsloader(false);
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };

  return (
    <div>
      <Modal isOpen={props.openSignRequest} size="md" className="details_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          {props.modalData.modalLabel}
        </ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <Row>
            <Col md={12}>
              <p>{props.modalData.modelText}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            size="md"
            className="me-1 cancel_modal"
            color="secondary" 
            onClick={props.handleClose}
            disabled={isloader}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            className="me-1 submit-Id-button"
            disabled={isloader}
            onClick={() => sendRequest(true)}
          >
            {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HelloSignConfirmation;
