import React from "react";
import { Card, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import {

  FaQuestionCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiTwotoneStar } from "react-icons/ai";
import { GoHome } from "react-icons/go";
import { Helmet } from "react-helmet";

const Sidebar = () => {
  const Email = useSelector((state) => state.email);
  const first_name = useSelector((state) => state?.first_name);
  const last_name = useSelector((state) => state?.last_name);

  return (
    <>
      <Helmet>
        <title>Dashboard : seeding-Justice</title>
      </Helmet>
      <Card className="userAdminAside pt-4 mb-5">
        <div className="user_details">
          <div className="profileImage_sidebar">
            <span>{`${first_name?.charAt(0)}${last_name?.charAt(0)}`}</span>
          </div>
          <h5 className="profile_font">{`${first_name} ${last_name}`}</h5>
          <p>{Email}</p>
        </div>
        <Nav tabs fill className="d-flex flex-column">
          <div>
            {/* <NavItem>
              <Link
                to="/prevetted/dashboard"
                className={
                  document.URL.search("dashboard") > 0
                    ? "active nav-link"
                    : "nav-link"
                }
              >
                <GoHome /> Dashboard
              </Link>
            </NavItem> */}
          </div>
          <div>
            <NavItem>
              <Link
                to="/prevetted/my-information"
                className={
                  document.URL.search("my-information") > 0
                    ? "active nav-link"
                    : "nav-link"
                }
              >
                <AiTwotoneStar />
                My Information
              </Link>
            </NavItem>
            <NavItem>
          <Link
            to="/prevetted/faqs"
            className={
              document.URL.search("faqs") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
             <FaQuestionCircle /> FAQs
          </Link>
        </NavItem>
          </div>
        </Nav>
      </Card>
    </>
  );
};

export default Sidebar;
