import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import DmfSubmissionForm from "../../Common/DmfSubmissionForm";
import classnames from "classnames";
import DmfIntakeFormSubmission from "../../Common/DmfIntakeFormSubmission";

const EditDmf = (props) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  const toggle = (tab) => {
    if (currentActiveTab === "1") {
      setCurrentActiveTab(tab);
    } else if (currentActiveTab === "2") {
      setCurrentActiveTab(tab);
    }
  };

  return (
    <>
      <Modal isOpen={props.open} size="lg" className="admin">
        <ModalHeader toggle={() => props.handleClose()}>
          {props.dmfId ? "Update " : "Add "}
          DMF Submission
        </ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <Nav tabs>
            <NavItem className="dmf_edit_modal">
              <NavLink
                className={classnames({
                  active: currentActiveTab === "1",
                })}
                onClick={() => {
                  toggle("1");
                }}
              >
                <span className="tab_text"> Basic Details</span>
              </NavLink>
            </NavItem>
            <NavItem className="dmf_edit_modal">
              <NavLink
                className={classnames({
                  active: currentActiveTab === "2",
                })}
                onClick={() => {
                  toggle("2");
                }}
              >
                <span className="tab_text"> Intake Form Details</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            {currentActiveTab == "1" ? (
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <DmfSubmissionForm
                      dmfId={props.dmfId}
                      handleClose={props.handleClose}
                      getDmfSubmission={props.getDmfSubmission}
                    />
                  </Col>
                </Row>
              </TabPane>
            ) : (
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <DmfIntakeFormSubmission
                      dmfId={props.dmfId}
                      handleClose={props.handleClose}
                      getDmfSubmission={props.getDmfSubmission}
                    />
                  </Col>
                </Row>
              </TabPane>
            )}
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditDmf;
