import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
  FormText,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Badge,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import nominee from "../../Services/nominee";
const ConfirmationModal = (props) => {
  const { modalData, emailLayout, handleClose, eventId, open } = props;
  const [fields, setFields] = useState({});
  const [isloader, setIsloader] = useState("");
  const [content, setContent] = useState("");
  const [editor, setEditor] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState("");

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};

    if (modalData.action === "is_distributed") {
      return true; 
    }

    if (!fields.subject) {
      errors["subject"] = "Subject can not be empty";
      formIsValid = false;
    }

    if (!content) {
      errors["content"] = "Content can not be empty";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const sendRequest = (value) => {
    if (validateForm()) {
      let params = fields;
      params["id"] = eventId;
      params[modalData.action] = value;
      params["content"] = content;
      setIsloader(value);
      nominee
        .action(params, content)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            handleClose();
            props.getNominations();
            setIsloader(false);
          } else {
            setErrors(res.data.message);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };
  const getTemplates = () => {
    setLoader(true);
    let params = fields;
    params["id"] = eventId;
    params[modalData.action] = eventId;
    nominee
      .preview(params, content)
      .then((res) => {
        if (res.data.status) {
          setContent(res.data.data.email_template);
          setFields(res.data.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  const handleContentChange = (content) => {
    setContent(content);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];

  const openEditor = () => {
    editor ? setEditor(false) : setEditor(true);
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const getEmailPreview = () => {
    return emailLayout.replace("<!--##########-->", content);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <div>
      <Modal
        isOpen={open}
        size={
          modalData.action === "is_distributed" ||
          modalData.action === "ngo_contract"
            ? "md"
            : "lg"
        }
        className="details_modal"
      >
        <ModalHeader toggle={() => handleClose()}>
          {modalData.modelText}
        </ModalHeader>
        {modalData.action === "grant_yes_no_email" ? (
          <ModalBody className="pl-4 pr-4" style={{ overflowY: "auto" }}>
            <>
              {editor ? (
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="subject">Subject</Label>
                      <Input
                        id="subject"
                        name="subject"
                        placeholder=""
                        type="text"
                        value={fields["subject"] ? fields["subject"] : ""}
                        onChange={(event) => handleChange(event, "subject")}
                        invalid={errors["subject"] ? true : false}
                      />
                      <FormFeedback>{errors["subject"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <ReactQuill
                        theme="snow"
                        name="content"
                        id="content"
                        value={content}
                        modules={modules}
                        formats={formats}
                        onChange={handleContentChange}
                        invalid={errors["content"] ? true : false}
                        style={{ minHeight: "250px" }}
                      />
                      {errors["content"] && (
                        <FormText color="danger" size="md">
                          {errors["content"]}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <Row>
                  {loader ? (
                    <div className="text-center">
                      <Spinner
                        style={{ height: 60, width: 60 }}
                        className="mx-1"
                      />
                    </div>
                  ) : (
                    <Col md={12}>
                      {editor === false && (
                        <Badge color="warning" className="text-dark mb-2">
                          Email Preview
                        </Badge>
                      )}
                      <FormGroup>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getEmailPreview(),
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              )}
            </>
          </ModalBody>
        ) : (
          ""
        )}

        <ModalFooter>
          {modalData.action === "is_distributed" ||
          modalData.action === "ngo_contract" ? (
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              disabled={isloader}
              onClick={handleClose}
            >
              Cancel
            </Button>
          ) : (
            ""
          )}

          {modalData.action === "ngo_contract" ||
          modalData.action === "is_distributed" ||
          modalData.action === "grant_yes_no_email" ? (
            <>
              <Button
                type="button"
                size="md"
                className="me-1"
                color="danger"
                disabled={isloader}
                onClick={() => (
                  modalData.action === "is_distributed" ||
                  modalData.action === "ngo_contract"
                    ? sendRequest(2)
                    : "",
                  modalData.action === "is_distributed" ||
                  modalData.action === "ngo_contract"
                    ? ""
                    : handleClose()
                )}
              >
                {isloader === 2 ? <Spinner size="sm" className="mx-1" /> : ""}
                {modalData.action === "is_distributed" ||
                modalData.action === "ngo_contract"
                  ? modalData.buttonNo
                  : "Cancel"}
              </Button>

              {modalData.action === "grant_yes_no_email" ? (
                <Button
                  type="button"
                  size="md"
                  className="me-1"
                  color="info"
                  disabled={isloader}
                  onClick={() => openEditor()}
                >
                  {editor ? "Preview" : "Edit"}
                </Button>
              ) : (
                ""
              )}

              <Button
                type="button"
                size="md"
                className="me-1 submit-Id-button"
                disabled={isloader}
                onClick={() => (
                  modalData.action === "is_distributed" ||
                  modalData.action === "ngo_contract"
                    ? sendRequest(1)
                    : "",
                  modalData.action === "grant_yes_no_email"
                    ? sendRequest(1)
                    : ""
                )}
              >
                {isloader === 1 ? <Spinner size="sm" className="mx-1" /> : ""}
                {modalData.action === "grant_yes_no_email"
                  ? "Send"
                  : modalData.buttonYes}
              </Button>
            </>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
