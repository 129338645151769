import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import prevettedsj from "../../Services/prevettedGrantees";
const PrevettedDetailsBody = (props) => {
  const [loader, setLoader] = useState(false);
  const [fields, setFields] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  let Grantee = props.sjgrantee;

  const getPrevetted = () => {
    let params = { id: Grantee.id };
    setLoader(true);
    prevettedsj
      .prevttedDetails(params)
      .then((res) => {
        if (res.data.status) {
          let fields = res.data.data;
          if (res.data.data.state !== null) {
            fields["state_id"] = {
              label: res.data.data.state.name,
              value: res.data.data.state.id,
            };
          }
          if (res.data.data.prevetted_categories !== null) {
            let categories = [];
            res.data.data.prevetted_categories.forEach((arr, index) => {
              categories[index] = {
                label: arr.category_name,
                value: arr.id,
              };
            });
            fields["categories"] = categories;
          }
          // setFields(fields);
          setLoader(false);
          setFields(fields, res.data.data);
          setLoader(false);

          if (res.data.data != null) {
            setImagePreview(res.data.data);
          }
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (Grantee.id) {
      getPrevetted(Grantee.id);
    }
  }, []);

  return (
    <Modal isOpen={props.opendetail} size="lg" className="user_modal">
      <Form>
        <ModalHeader toggle={() => props.handleClose()}>
        Recommended Grantees's Details
        </ModalHeader>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <ModalBody className="pl-4 pr-4">
            <hr />
            <div className="px-2 pt-2">
              <FormGroup row>
                <Col md={6}>
                  <div className="nomination-box">
                    <strong>Organization Name :</strong> {fields?.org_name}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="nomination-box">
                    <strong>EIN :</strong> {fields?.ein_number}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col col={12}>
                  <div className="nomination-box">
                    <strong>Website : </strong>
                    <a
                      href={fields?.org_website}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {fields?.org_website}
                    </a>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={12}>
                  <div className="nomination-box">
                    <strong>Mission : </strong> {fields?.mission}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={12}>
                  <div className="nomination-box">
                    <strong>Address :</strong>{" "}
                    {fields?.street ? fields?.street : "N/A"}, {fields?.city},{" "}
                    {fields?.state?.name}, {fields?.zip}
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col col={12}>
                  <div className="nomination-box">
                    <strong>Priority Population :</strong>{" "}
                    {fields?.priority_population}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={6}>
                  <Col md={12}>
                    <div className="nomination-box">
                      <strong>Contact Name :</strong>{" "}
                      {fields?.org_contact_name
                        ? fields?.org_contact_name
                        : "N/A"}
                    </div>
                  </Col>
                  <Col md={12} className="my-3">
                    <div className="nomination-box">
                      <strong>Contact Email :</strong>{" "}
                      {fields?.org_contact_email
                        ? fields?.org_contact_email
                        : "N/A"}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="nomination-box">
                      <strong>Geography : </strong> {fields?.geography?fields?.geography:"N/A"}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="nomination-box mt-3">
                      <strong>Prevetted Categories : </strong>{" "}
                      {fields?.prevetted_categories?.map((res, index) => (
                        <Fragment key={index}>
                          {(index ? ", " : "") + res.category_name}
                        </Fragment>
                      ))}
                    </div>
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={
                      imagePreview?.logo
                        ? imagePreview?.logo
                        : "/assets/no-logo.png"
                    }
                    className="img-thumbnail"
                    style={{ width: "200px", height: "190px" }}
                  />
                </Col>
              </FormGroup>
              <fieldset className="custom-fieldset">
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>
                  {" "}
                  <a
                    href="https://www.bill.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#E26A38" }}
                  >
                    Bill.com
                  </a>{" "}
                  Vendor Name :
                </strong>{" "}
                {fields?.vendor_name ? fields?.vendor_name : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <div className="nomination-box">
                <strong>
                  {" "}
                  <a
                    href="https://www.bill.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#E26A38" }}
                  >
                    Bill.com
                  </a>{" "}
                  Payment Network ID (PNI) :
                </strong>{" "}
                {fields?.account_number ? fields?.account_number : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                <strong> Additional payment instructions : </strong>{" "}
                {fields?.payment_remarks ? fields?.payment_remarks : "N/A"}
              </div>
            </Col>
          </FormGroup>
        </fieldset>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            className="cancel_modal"
            onClick={props.handleClose}
            size="lg"
            // disabled={isLoader}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PrevettedDetailsBody;
