import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
  Input,
  Label,
  FormFeedback,
  FormGroup,
  FormText,
  Badge,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import prevettedsj from "../../Services/prevettedGrantees";
import emailtemplate from "../../Services/emailtemplate";

const InvitationModal = (props) => {
  const [fields, setFields] = useState({});
  const [emailLayout, setEmailLayout] = useState("");
  const [content, setContent] = useState("");
  const [editor, setEditor] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState("");

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const sendRequest = (value) => {
    let params = fields;
    if (props.resend) {
      params["id"] = props.contractId;
      params["content"] = content;
      params["resend"] = true;
    } else {
      params["id"] = props.contractId;
      params["content"] = content;
    }
    setIsloader(true);
    prevettedsj
      .action(params, content)
      .then((res) => {
        setIsloader(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleClose();
          props.getPrevettedGrantees();
          setIsloader(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setErrors(res.data.message);
          setIsloader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
  };

  const handleContentChange = (content) => {
    setContent(content);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];

  const openEditor = () => {
    editor ? setEditor(false) : setEditor(true);
  };

  const getTemplates = () => {
    setLoader(true);
    let params = fields;
    params["id"] = props.contractId;
    params["preview_type"] = "org_invitation";
    prevettedsj
      .preview(params, content)
      .then((res) => {
        if (res?.data?.status) {
          setContent(res?.data?.data?.email_template);
          setFields(res?.data?.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  const layoutEmail = () => {
    emailtemplate
      .email_layout()
      .then((res) => {
        if (res.data.status) {
          setEmailLayout(res.data.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  const getEmailPreview = () => {
    return emailLayout.replace("<!--##########-->", content);
  };
  useEffect(() => {
    layoutEmail();
    getTemplates();
  }, []);

  return (
    <div>
      <Modal isOpen={props.openContract} size="lg" className="details_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          Confirmation
        </ModalHeader>
        <ModalBody className="pl-4 pr-4">
          {/* {errors ? (
            <div className="alert alert-danger" role="alert">
              {errors['subject']}
            </div>
          ) : (
            ""
          )} */}
          <Row>
            <Col md={12}>
              {editor ? (
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="subject">Subject</Label>
                      <Input
                        id="subject"
                        name="subject"
                        placeholder=""
                        type="text"
                        value={fields["subject"] ? fields["subject"] : ""}
                        onChange={(event) => handleChange(event, "subject")}
                        invalid={errors["subject"] ? true : false}
                      />
                      <FormFeedback>{errors["subject"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <ReactQuill
                        theme="snow"
                        name="content"
                        id="content"
                        value={content}
                        modules={modules}
                        formats={formats}
                        onChange={handleContentChange}
                        invalid={errors["content"] ? true : false}
                        style={{ minHeight: "250px" }}
                      />
                      {errors["content"] && (
                        <FormText color="danger" size="md">
                          {errors["content"]}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <Row>
                  {loader ? (
                    <div className="text-center">
                      <Spinner
                        style={{ height: 60, width: 60 }}
                        className="mx-1"
                      />
                    </div>
                  ) : (
                    <Col md={12}>
                      {editor === false && (
                        <Badge color="warning" className="text-dark mb-2">
                          Email Preview
                        </Badge>
                      )}
                      <FormGroup>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getEmailPreview(),
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            size="md"
            className="me-1 cancel_modal"
            disabled={isloader}
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="md"
            color="info"
            className="me-1"
            disabled={isloader}
            onClick={() => openEditor()}
          >
            {editor ? "Preview" : "Edit"}
          </Button>
          <Button
            type="button"
            size="md"
            className="me-1 submit-Id-button"
            disabled={isloader}
            onClick={() => sendRequest(true)}
          >
            {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InvitationModal;
