
import React from 'react'
import { CardBody, Col, Row } from "reactstrap";
import NominationForm from '../../../Common/NominationForm';
import Sidebar from '../Sidebar';

const Index = () => {
  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <div className="mt-4 mb-5 userAdminArticle">
            <div className="py-3 userAdminArticleHeading">
              <h4 className="mb-0">Recommend Grants</h4>
            </div>
            <CardBody className='recommend-grants-form px-2'>
              <NominationForm />
            </CardBody>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Index