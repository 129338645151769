import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import adminsetting from "../../../Services/adminsettings";
import common from "../../../Services/common";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import SettingSidebar from "../SettingSidebar";

const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [singleError, setSingleError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    let params = fields;
    setLoader(true);
    adminsetting
      .adminPassword(params)
      .then((res) => {
        setLoader(true);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          setFields({});
          setErrors({});
        } else {
          if (common.isJson(res.data.message)) {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
          } else {
            setSingleError(res.data.message);
          }
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setErrors(errors);
        setLoader(false);
      });
  };

  const toggle = (action) => {
    if (action == 1) {
      setIsPasswordVisible(!isPasswordVisible);
    }
    if (action == 2) {
      setVisible(!isVisible);
    }

    if (action == 3) {
      setIsConfirmPassVisible(!isConfirmPassVisible);
    }
  };

  return (
    <div className="mt-4" style={{ minHeight: 300 }}>
      <Row>
        <Col md={4} lg={3}>
          <SettingSidebar />
        </Col>
        <Col md={8} className="mt-2" lg={9}>
          <Card className="shadow support_aside mb-5" style={{ width: "100%" }}>
            <CardHeader className="py-3">Change Password</CardHeader>
            <CardBody>
              <form onSubmit={handlePasswordSubmit}>
                <Col md={12} className="position-relative">
                  <FormGroup>
                    <Label for="old_password">Old Password</Label>
                    <span
                      onClick={() => toggle(1)}
                      className="admin_field_icon"
                    >
                      {isPasswordVisible ? (
                        <RiEyeFill
                          className="text-dark field-icon"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <RiEyeOffFill
                          className="text-dark field-icon"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </span>
                    <Input
                      id="old_password"
                      name="old_password"
                      placeholder="Enter old password"
                      type={!isPasswordVisible ? "password" : "text"}
                      value={
                        fields["old_password"] ? fields["old_password"] : ""
                      }
                      onChange={(event) => handleChange(event, "old_password")}
                      invalid={errors["old_password"] ? true : false}
                    />

                    <FormFeedback>{errors["old_password"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12} className="position-relative">
                  <FormGroup>
                    <Label for="password">New Password</Label>
                    <span
                      onClick={() => toggle(2)}
                      className="admin_field_icon"
                    >
                      {isVisible ? (
                        <RiEyeFill
                          className="text-dark"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <RiEyeOffFill
                          className="text-dark"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </span>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Enter new password"
                      type={!isVisible ? "password" : "text"}
                      value={fields["password"] ? fields["password"] : ""}
                      onChange={(event) => handleChange(event, "password")}
                      invalid={errors["password"] ? true : false}
                    />

                    <FormFeedback>{errors["password"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12} className="position-relative">
                  <FormGroup>
                    <Label for="password_confirmation">Confirm Password</Label>
                    <span
                      onClick={() => toggle(3)}
                      className="admin_field_icon"
                    >
                      {isConfirmPassVisible ? (
                        <RiEyeFill
                          className="text-dark"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <RiEyeOffFill
                          className="text-dark"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </span>
                    <Input
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder="Enter confirm password"
                      type={!isConfirmPassVisible ? "password" : "text"}
                      value={
                        fields["password_confirmation"]
                          ? fields["password_confirmation"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "password_confirmation")
                      }
                      invalid={errors["password_confirmation"] ? true : false}
                    />

                    <FormFeedback>
                      {errors["password_confirmation"]}
                    </FormFeedback>
                  </FormGroup>{" "}
                </Col>
                {singleError != "" ? (
                  <p className="text-danger text-center mb-1">{singleError}</p>
                ) : (
                  ""
                )}

                <Col className="d-flex justify-content-start">
                  <Button
                    color="success"
                    className="submit-Id-button"
                    type="submit"
                  >
                    {loader && <Spinner size="sm" className="mx-1" />}
                    Submit
                  </Button>
                </Col>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
