import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  Col,
  Row,
  FormGroup,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
  Container,
  Alert,
  CardBody,
} from "reactstrap";
import user from "../../Services/user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
import common from "../../Services/common";
import moment from "moment";

const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(0);
  const [reload, setReload] = useState(false);
  const [emailVerification, setEmailVerification] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();
  LoadingOverlay.propTypes = undefined;
  
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleDate = (date) => {
    setFields({ ...fields, dob: date });
  };
  

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
    setErrors({ ...errors, [field_name]: (phone = "") });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      setLoader([false, false, false, true]);
      user
        .updateUser(params)
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            getProfile();
            setEmailVerification(res?.data?.data);
            setErrors({});
            localStorage.setItem("first_name", fields.first_name);
            localStorage.setItem("last_name", fields.last_name);
            let payload = {
              first_name: fields.first_name,
              last_name: fields.last_name,
            };
            dispatch({
              type: "UPDATE-FIRSTNAME",
              type: "UPDATE-LASTNAME",
              ...payload,
            });
            if (emailVerification.is_email_1_verified <= 0) {
              setReload(true);
            }
            if (emailVerification.is_email_2_verified <= 0) {
              setReload(true);
            }
            setLoader([false, false, false, false]);
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrors(errors);
            setLoader([false, false, false, false]);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          
          setLoader([false, false, false, false]);
        });
    }
  };

  const getProfile = () => {
    setLoader([false, false, true, false]);
    user
      .getOneUser()
      .then((res) => {
        if (res?.data?.status) {
          let fields = res.data.data;
          fields["dob"] =
          res?.data?.data?.dob !== "0000-00-00"
            ? new Date(moment(res?.data?.data?.dob))
            : "";
          setFields(fields);
          setEmailVerification(res?.data?.data);
          setLoader([false, false, false, false]);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
        setLoader(false);
      });
  };

  const resendEmail = (action) => {
    let params;
    let verifyEmail = user;

    if (action === 1) {
      params = { secondary_email: 1 };
      setLoader(1);
    }
    if (action === 2) {
      params = { secondary_email: 2 };
      setLoader(2);
    }
    if (action === 3) {
      setLoader(3);
      verifyEmail = verifyEmail.verifyPrimaryEmail(params);
    } else {
      verifyEmail = verifyEmail.verifySecondaryEmail(params);
    }

    verifyEmail
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoader(0);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid first name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid last name";
      formIsValid = false;
    }
    if (!fields.dob) {
      errors["dob"] = "DOB can not be empty";
      formIsValid = false;
    }
    if (fields.dob && !common.isValidAge(fields["dob"])) {
      errors["dob"] = "Age must be 18 or above";
      formIsValid = false;
    }
    if (!fields.phone) {
      errors["phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (fields.phone && !common.isValidPhone(fields["phone"])) {
      errors["phone"] = "Enter valid mobile number!";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (fields.email && fields.email === fields.secondary_email_1) {
      errors["secondary_email_1"] = "Email must be different";
      formIsValid = false;
    }
    if (fields.secondary_email_1 && !common.isValidEmail(fields["secondary_email_1"])) {
      errors["secondary_email_1"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (fields.secondary_email_2 && !common.isValidEmail(fields["secondary_email_2"])) {
      errors["secondary_email_2"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (fields.secondary_email_1 && fields.secondary_email_2 && fields.secondary_email_1 === fields.secondary_email_2) {
      errors["secondary_email_2"] = "Email must be different";
      formIsValid = false;
    }
    if (!fields.social_security_number) {
      errors["social_security_number"] =
        "Social Security number can not be empty";
      formIsValid = false;
    } else if (fields.social_security_number.length < 9) {
      errors["social_security_number"] = "Social Security must be 9 digits";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  useEffect(() => {
    getProfile();
    console.log(errors);
  }, []);

  return (
    <>
      <Helmet>
        <title>Edit Profile : seeding-Justice</title>
      </Helmet>
      <section className="homepage_banner secondary-homepage_banner">
        <Container className="px-5 pt-4 mb-5">
          <div className="col-md-6 offset-md-3" style={{ minHeight: 500 }}>
            <Card className="login login_bg" style={{width:"37rem"}}>
              <CardBody>
                <div className="text-center">
                  <h1>Edit Profile</h1>
                  <hr />
                </div>
                {emailVerification?.update_email != null ? (
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Alert color="danger" style={{ width: "80%" }}>
                <div>
                  Please verify{" "}
                  <span style={{ color: "#3200b7" }}>
                    {emailVerification.update_email}{" "}
                  </span>
                  to change your primary email. verification link has been sent
                  to your mail.
                  <span
                    className="alert-link mx-1 text-success"
                    style={{cursor:"pointer"}}
                    onClick={() => resendEmail(3)}
                  >
                    Resend
                    {loader === 3 ? <Spinner size="sm" className="ms-1" /> : ""}
                  </span>
                </div>
              </Alert>
            </div>
          ) : (
            ""
          )}

          {emailVerification?.is_email_1_verified <= 0 &&
          emailVerification?.secondary_email_1 != null ? (
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Alert color="danger" style={{ width: "80%" }}>
                {!reload ? (
                  <div>
                    Your email{" "}
                    <span className="text-success mx-1">
                      {emailVerification?.secondary_email_1}
                    </span>{" "}
                    is not verified yet. Kindly check your inbox to get
                    verified.
                    <span
                      className="alert-link mx-1 text-success"
                      onClick={() => resendEmail(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Resend
                      {loader === 1 ? (
                        <Spinner size="sm" className="ms-1" />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    Details updated successfully. Kindly check your Inbox to
                    verify the{" "}
                    <span className="text-success">
                      {emailVerification?.secondary_email_1}
                    </span>{" "}
                    <span
                      className="alert-link mx-1 text-success"
                      onClick={() => resendEmail(1)}
                      style={{cursor: "pointer"}}
                    >
                      Resend
                      {loader === 1 ? (
                        <Spinner size="sm" className="ms-1" />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )}
              </Alert>
            </div>
          ) : (
            ""
          )}

          {emailVerification?.is_email_2_verified <= 0 &&
          emailVerification?.secondary_email_2 != null ? (
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Alert color="danger" style={{ width: "80%" }}>
                {!reload ? (
                  <div>
                    Your email{" "}
                    <span className="text-success mx-1">
                      {emailVerification?.secondary_email_2}
                    </span>{" "}
                    is not verified yet. Kindly check your inbox to get
                    verified.
                    <span
                      className="alert-link mx-1 text-success"
                      onClick={() => resendEmail(2)}
                      style={{ cursor: "pointer" }}
                    >
                      Resend
                      {loader === 2 ? (
                        <Spinner size="sm" className="ms-1" />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    Details updated successfully. Kindly check your Inbox to
                    verify the{" "}
                    <span className="text-success">
                      {emailVerification?.secondary_email_2}
                    </span>{" "}
                    <span
                      className="alert-link mx-1 text-success"
                      style={{cursor: "pointer"}}
                      onClick={() => resendEmail(2)}
                    >
                      Resend
                      {loader === 2 ? (
                        <Spinner size="sm" className="ms-1" />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )}
              </Alert>
            </div>
          ) : (
            ""
          )}
                <Form onSubmit={handleSubmit}>
                  <LoadingOverlay
                    active={loader[2]}
                    spinner={
                      <Spinner
                        style={{ height: 50, width: 50, color: "#00344B" }}
                      />
                    }
                    fadeSpeed={100}
                    classNamePrefix="mitiz"
                  >
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="first_name">First Name</Label> */}
                          <Input
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            type="first_name"
                            value={
                              fields["first_name"] ? fields["first_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "first_name")
                            }
                            invalid={errors["first_name"] ? true : false}
                          />
                          <FormFeedback>{errors["first_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="last_name">Last Name</Label> */}
                          <Input
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            type="last_name"
                            value={
                              fields["last_name"] ? fields["last_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "last_name")
                            }
                            invalid={errors["last_name"] ? true : false}
                          />
                          <FormFeedback>{errors["last_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="email">Email</Label> */}
                          <Input
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="text"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            invalid={errors["email"] ? true : false}
                          />
                          <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="secondary_email_1">Secondary Email 1</Label> */}
                          <Input
                            id="secondary_email_1"
                            name="secondary_email_1"
                            placeholder="Secondary Email"
                            type="text"
                            value={
                              fields["secondary_email_1"]
                                ? fields["secondary_email_1"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "secondary_email_1")
                            }
                          />
                          {errors["secondary_email_1"] && (
                            <small className="fa-1x text-danger">
                              {errors["secondary_email_1"]}
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="secondary_email_2">Secondary Email 2</Label> */}
                          <Input
                            id="secondary_email_2"
                            name="secondary_email_2"
                            placeholder="Secondary Email 2"
                            type="text"
                            value={
                              fields["secondary_email_2"]
                                ? fields["secondary_email_2"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "secondary_email_2")
                            }
                          />
                          {errors["secondary_email_2"] && (
                            <small className="fa-1x text-danger">
                              {errors["secondary_email_2"]}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <PhoneInput
                            countryCodeEditable={false}
                            onlyCountries={["us"]}
                            value={fields["phone"] ? fields["phone"] : ""}
                            onChange={(phone) => handlePhone(phone, "phone")}
                            country="us"
                            inputProps={{
                              placeholder: "+1",
                            }}
                          />
                          {errors["phone"] && (
                            <small className="fa-1x text-danger">
                              {errors["phone"]}
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="date">DOB</Label>00 */}
                          <DatePicker 
                          selected={fields["dob"]
                                ? fields["dob"]
                                : ""} 
                              onChange={(event) =>
                                handleDate(event, "dob")
                              }
                              dateFormat="MM-dd-yyyy"
                              placeholderText="MM-DD-YYYY"
                              className="form-control"
                              />
                        
                        </FormGroup>
                        <span className="text-danger">{errors["dob"]}</span>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          {/* <Label for="social_security_number">
                            Social Security Number
                          </Label> */}
                          <Input
                            id="social_security_number"
                            name="social_security_number"
                            placeholder="Social security number"
                            type="number"
                            value={
                              fields["social_security_number"]
                                ? fields["social_security_number"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "social_security_number")
                            }
                            invalid={
                              errors["social_security_number"] ? true : false
                            }
                          />
                          <FormFeedback>
                            {errors["social_security_number"]}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-end">
                        <Button
                          color="success"
                          className="submit-button"
                          size="md"
                          type="submit"
                          disabled={loader[3]}
                        >
                          {loader[3] ? (
                            <Spinner size="sm" className="mx-1" />
                          ) : (
                            ""
                          )}
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </LoadingOverlay>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Index;
