import React, { useState } from "react";
import moment from "moment";
import { MdMarkEmailRead, MdModeEdit, MdUpdate } from "react-icons/md";
import { Link } from "react-router-dom";
import PrevettedDetailsBody from "../PrevettedSjGrantees/PrevettedDetailsBody";
import { FaEye } from "react-icons/fa";
import { BsCircle, BsXCircleFill } from "react-icons/bs";
import { Badge, Button } from "reactstrap";

import InvitationModal from "../PrevettedSjGrantees/InvitationModal";
import { AiFillCheckCircle } from "react-icons/ai";
import PrevettedUpdateModal from "./PrevettedUpdateModal";
import prevettedsj from "../../Services/prevettedGrantees";
import { toast } from "react-toastify";

const PrevettedUpdateRequest = (props) => {
  const [openContract, setOpenContract] = useState(false);
  const [opendetail, setOpenDetail] = useState(false);
  const [openUpateDetail, setOpenUpdateDetail] = useState(false);
  const [prevettedUser, setPrevettedUser] = useState({});
  const [contractId, setContractId] = useState("");
  const [detailId, setDetailId] = useState("");
  const sjgrantee = props.sjGrantee;

  const ApproveRequest = (value) => {
    let params = prevettedUser;
    params["id"] = value;
    prevettedsj
      .getPrevetted(params.id)
      .then((res) => {
        if (res.data.status) {
          setOpenUpdateDetail(true);
          setPrevettedUser(res.data.data);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <>
      <tr key={sjgrantee.id}>
        <td>{props.index + 1}</td>
        <td>{sjgrantee.org_name}</td>
        <td>{sjgrantee.ein_number}</td>
        <td>{moment(sjgrantee.updated_at).format("D MMM,  YYYY")}</td>
        <td className="text-center">
          {sjgrantee.update_request_data[0] &&
          sjgrantee.update_request_data[0]?.status === "PENDING" ? (
            <FaEye
              className="details-icon"
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => ApproveRequest(sjgrantee.id)}
            />
          ) : sjgrantee.update_request_data[0] &&
            sjgrantee.update_request_data[0]?.status === "APPROVED" ? (
            <Badge pill color="success">
              {sjgrantee?.update_request_data[0]?.status}
            </Badge>
          ) : sjgrantee.update_request_data[0] &&
            sjgrantee.update_request_data[0]?.status === "REJECTED" ? (
            <Badge pill color="danger">
              {sjgrantee?.update_request_data[0]?.status}
            </Badge>
          ) : (
            "N/A"
          )}
        </td>
      </tr>
      {opendetail && (
        <PrevettedDetailsBody
          opendetail={opendetail}
          handleClose={() => {
            setOpenDetail();
            setDetailId(null);
          }}
          sjId={detailId}
          sjgrantee={sjgrantee}
        />
      )}

      {openUpateDetail && (
        <PrevettedUpdateModal
          openUpateDetail={openUpateDetail}
          handleClose={() => {
            setOpenUpdateDetail();
            setDetailId(null);
          }}
          prevettedUser={prevettedUser}
          sjgrantee={sjgrantee}
          getPrevettedGrantees={props.getPrevettedGrantees}
        />
      )}

      {openContract && (
        <InvitationModal
          openContract={openContract}
          handleClose={() => {
            setOpenContract();
            setContractId(null);
          }}
          contractId={contractId}
          resend={sjgrantee.invitation_status === "SENT"}
          getPrevettedGrantees={props.getPrevettedGrantees}
        />
      )}
    </>
  );
};

export default PrevettedUpdateRequest;
