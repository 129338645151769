import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import { BsXCircleFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";

const Template = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const template = props.template;
  return (
    <>
      <tr key={template.id}>
        <td>{template.id}</td>
        <td>{template.template_name}</td>
        <td>{template.from_email}</td>
        <td>{template.reply_to_email}</td>
        <td className="text-center">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            color="primary"
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
              <Link
                to={`/admin/email-templates/edit/${template.id}`}
                style={{ color: "#000", textDecoration: "none" }}
              >
                <DropdownItem>
                  <MdModeEdit
                    className="me-3 edit-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Edit
                </DropdownItem>
              </Link>
              <DropdownItem onClick={() => props.deleteTemplate(template.id)}>
                <BsXCircleFill
                  className="me-3 remove-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />{" "}
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default Template;
