const common = {
  grantTypes: ["DAF"],

  getFullName(data) {
    let name = "";

    if (data !== null && data.first_name !== null) {
      name += data?.first_name + ` `;
    }

    if (data !== null && data.last_name !== null) {
      name += data?.last_name + ` `;
    }

    return name;
  },
  isValidAge: (dateString) => {
    let today = new Date();
    let userDate = new Date(dateString);

    let eighteenYearsAgo = today.getFullYear() - userDate.getFullYear();
    let monthDiff = today.getMonth() - userDate.getMonth();
    let dayDiff = today.getDate() - userDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      eighteenYearsAgo--;
    }

    return eighteenYearsAgo >= 18;
  },

  isValidEmail: (email) => {
    // let emailRule = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emailRule = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRule.test(email)) {
      return true;
    }
    return false;
  },
  isValidName: (name) => {
    let nameRule = /^[A-Za-z\s]+$/;
    if (nameRule.test(name)) {
      return true;
    }
    return false;
  },
  isValidAddress: (address) => {
    let addressRule = /^[a-zA-Z\s-]+$/;
    if (addressRule.test(address)) {
      return true;
    }
    return false;
  },

  isValidUrl: (url) => {
    let urlRule =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z0-9-]+\/?$/;
    if (urlRule.test(url)) {
      return true;
    }
    return false;
  },

  isValidPhone: (phone) => {
    let phoneRule = /^(\+?1[-.\s]?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    if (phoneRule.test(phone)) {
      return true;
    }
    return false;
  },

  // function isWhitespaceOrEmpty(str) {
  //   return !str || /^\s*$/.test(str);
  // }
  

  numberFormat: (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  isJson: (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  },

  errMsg: (str) => {
    let newStr = str.split(".");
    return newStr[2];
  },


  getSignstatuscolor: (status) => {
    switch (status) {
      case "NOT_AVAILABLE":
        return "secondary";
        break;
      case "SIGNED":
        return "success";

        break;
      case "PENDING":
        return "warning";

        break;
      case "DECLINED_BY_USER":
        return "info";

        break;
      case "CANCELLED_BY_ADMIN":
        return "danger";

        break;
      case "NOT_SENT":
        return "primary";

        break;

      default:
        break;
    }
  },
};

export default common;
