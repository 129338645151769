import About from "../Frontend/About";
import Term from "../Frontend/Term";
import Support from "../Frontend/Support";
import Signup from "../Frontend/Signup";
import Error from "../Frontend/Error";
import Login from "../Frontend/Login";
import EditProfile from "../Frontend/EditProfile";
import ForgotPassword from "../Frontend/ForgotPassword";
import ChangePassword from "../Frontend/ChangePassword";
import ResetPassword from "../Frontend/ResetPassword";
import DmfForm from "../Frontend/DmfForm";
import NominationForm from "../Frontend/NominationForm";
import SuccessNomination from "../Frontend/Dashboard/SuccessNomination";
import GivingHistory from "../Frontend/Dashboard/GivingHistory";
import PreVettedsjGrantee from "../Frontend/Dashboard/PrevettedSjGrantee/PreVettedsjGrantee";
import MakeContribution from "../Frontend/Dashboard/MakeContribution";
import RecommendGrants from "../Frontend/Dashboard/RecommendGrants";
import EmailVerification from "../Frontend/Login/EmailVerification";
import GrantRecomendedHistory from "../Frontend/Dashboard/GrantRecomendedHistory";
import SuccessDmfSubmission from "../Common/SuccessDmfSubmission";
import UpdateDmfDetails from "../Frontend/UpdateDmfDetails";
import CreatePrevettedGrantee from "../Frontend/CreatePrevettedGrantee"
import SuccessIntakeFormSubmit from "../Frontend/UpdateDmfDetails/SuccessIntakeFormSubmit"
import SuccessNewsLetter from "../Frontend/DmfForm/SuccessNewsLetter";
import SuccessPrevetted from "../Frontend/CreatePrevettedGrantee/SuccessPrevetted";
import PrevettedGrantee from "../Frontend/Role/PrevettedGrantee/PrevettedGrantee";
import DonorFaqs from "../Frontend/Dashboard/Faqs";
import Faqs from "../Frontend/Faqs";

const FrontRoutes = [
  {
    path: "/",
    name: "Dmf Form",
    element: DmfForm,
    authentication: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: MakeContribution,
    authentication: true,
  },
  {
    path: "/about-us",
    name: "About",
    element: About,
    authentication: true,
  },
  {
    path: "/term",
    name: "Term",
    element: Term,
    authentication: true,
  },
  {
    path: "/support",
    name: "Support",
    element: Support,
    authentication: true,
  },
  {
    path: "/signup",
    name: "Sign Up",
    element: Signup,
    authentication: false,
  },
  {
    path: "/login",
    name: "Login",
    element: Login,
    authentication: false,
  },
  {
    path: "/email-verification",
    name: "EmailVerification",
    element: EmailVerification,
    authentication: false,
  },
  {
    path: "/dmf-form",
    name: "Dmf Form",
    element: DmfForm,
    authentication: false,
  },
  {
    path: "/nomination-form",
    name: "Nomination Form",
    element: NominationForm,
    authentication: false,
  },
  {
    path: "/success-nomination",
    name: "Success Nomination",
    element: SuccessNomination,
    authentication: false,
  },
  {
    path: "/success-dmfsubmission",
    name: "Success dmfsubmission",
    element:SuccessDmfSubmission,
    authentication: false,
  },
  {
    path: "/success-intake-form-submit",
    name: "Success intake-form-submit",
    element:SuccessIntakeFormSubmit,
    authentication: false,
  },
  {
    path: "/newsletter-subscribed",
    name: "Newsletter subscribed",
    element: SuccessNewsLetter,
    authentication: false,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    element: ForgotPassword,
    authentication: false,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    element: ResetPassword,
    authentication: false,
  },
  {
    path: "/change-password",
    name: "Change Password",
    element: ChangePassword,
    authentication: true,
  },
  {
    path: "/make-contributions",
    name: "Make Contributions",
    element: MakeContribution,
    authentication: true,
  },
  {
    path: "/recommend-grants",
    name: "Recommend grants",
    element: RecommendGrants,
    authentication: true,
  },
  {
    path: "/grant-recommendation-history",
    name: "Grant recommendation History",
    element: GrantRecomendedHistory,
    authentication: true,
  },
  
  {
    path: "/giving-history",
    name: "Giving History",
    element: GivingHistory,
    authentication: true,
  },
  {
    path: "/prevetted-sjgrantees",
    name: "Prevetted Sjgrantees",
    element: PreVettedsjGrantee,
    authentication: true,
  },
  {
    path: "/prevetted-grantees",
    name: "Prevetted grantees",
    element: PrevettedGrantee,
    authentication: false,
  },

  {
    path: "/edit-profile",
    name: "Edit Profile",
    element: EditProfile,
    authentication: true,
  },
  {
    path: "/update-dmf-details",
    name: "UpdateDmfDetails",
    element: UpdateDmfDetails,
    authentication: false,
  },
  {
    path: "/create-prevetted-grantee",
    name: "CreatePrevettedGrantee",
    element: CreatePrevettedGrantee,
    authentication: false,
  },
  {
    path: "/success-prevetted",
    name: "Success prevetted",
    element:SuccessPrevetted,
    authentication: false,
  },
  {
    path: "/donor-faqs",
    name: "Donor Faqs",
    element: DonorFaqs,
    authentication: true,
  },
  {
    path: "/faqs",
    name: "Faqs",
    element: Faqs,
    authentication: false,
  },

  {
    path: "/error",
    name: "Error",
    element: Error,
    authentication: true,
  },
];

export default FrontRoutes;
