import React, { useState } from "react";
import moment from "moment";
import { MdMarkEmailRead, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import PrevettedDetailsBody from "../PrevettedSjGrantees/PrevettedDetailsBody";
import { FaEye } from "react-icons/fa";
import { BsCircle, BsXCircleFill } from "react-icons/bs";
import { Badge } from "reactstrap";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { FaEllipsisV } from "react-icons/fa";
import InvitationModal from "../PrevettedSjGrantees/InvitationModal";
import { AiFillCheckCircle } from "react-icons/ai";
import PrevettedUpdateModal from "./PrevettedUpdateModal";

const InvitationModule = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openContract, setOpenContract] = useState(false);
  const [opendetail, setOpenDetail] = useState(false);
  const [openUpateDetail, setOpenUpdateDetail] = useState(false);
  const [prevettedUser, setPrevettedUser] = useState({});
  const [contractId, setContractId] = useState("");
  const [detailId, setDetailId] = useState("");
  const sjgrantee = props.sjgrantee;

  const prevettedDetailsModal = (detail_id) => {
    setOpenDetail(true);
    setDetailId(detail_id);
  };

  const SendContract = (contractId) => {
    setOpenContract(true);
    setContractId(contractId);
  };

  const ApproveRequest = (contractId) => {
    setOpenUpdateDetail(true);
    setDetailId(contractId);

    if (sjgrantee?.update_request_data[0] !== null) {
      let details = JSON.parse(sjgrantee?.update_request_data[0]?.data);
      setPrevettedUser(details);
    }
  };

  return (
    <>
      <tr key={sjgrantee.id}>
        <td>{props.index + 1}</td>
        <td>
          <Link
            to={""}
            onClick={() => prevettedDetailsModal(sjgrantee.id)}
            className="text-decoration-none"
          >
            {sjgrantee?.org_name}
          </Link>
        </td>
        <td>{sjgrantee.ein_number}</td>
        <td>{moment(sjgrantee.created_at).format("D MMM,  YYYY")}</td>
        <td className="text-center">
          {sjgrantee.invitation_status === "SENT" ? (
            <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
          ) : (
            <BsCircle
              style={{ cursor: "pointer" }}
              onClick={() => SendContract(sjgrantee.id)}
              size={22}
            />
          )}
        </td>
        <td className="text-center">
          <Badge
            pill
            color={
              sjgrantee.registration_status == "NOT_REGISTERED"
                ? "warning"
                : "success"
            }
          >
            {sjgrantee?.registration_status.replace("_", " ")}
          </Badge>
        </td>
        <td className="text-center">
          {sjgrantee.update_request_data[0] &&
          sjgrantee.update_request_data[0]?.status === "PENDING" ? (
            <Badge pill color={"warning"}>
              {sjgrantee?.update_request_data[0]?.status}
            </Badge>
          ) : sjgrantee.update_request_data[0] &&
            sjgrantee.update_request_data[0]?.status === "APPROVED" ? (
            <Badge pill color={"success"} className="badge_button">
              {sjgrantee?.update_request_data[0]?.status}
            </Badge>
          ) : sjgrantee.update_request_data[0] &&
            sjgrantee.update_request_data[0]?.status === "REJECTED" ? (
            <Badge pill color="danger">
              {sjgrantee?.update_request_data[0]?.status}
            </Badge>
          ) : (
            "N/A"
          )}
        </td>
        <td className="text-center">
          {sjgrantee.status == 0 ? (
            <Badge pill color="danger">
              Inactive
            </Badge>
          ) : (
            <Badge pill color="success">
              Active
            </Badge>
          )}
        </td>
        <td className="text-center">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            color="primary"
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
              <span onClick={() => prevettedDetailsModal(sjgrantee.id)}>
                <DropdownItem>
                  <FaEye
                    className="me-3 details-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Details
                </DropdownItem>
              </span>
              <span onClick={() => props.openEditModal(sjgrantee.id)}>
                <DropdownItem>
                  <MdModeEdit
                    className="me-3 edit-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Edit
                </DropdownItem>
              </span>
              {sjgrantee.registration_status !== "REGISTERED" && (
                <span onClick={() => SendContract(sjgrantee.id)}>
                  <DropdownItem>
                    <MdMarkEmailRead
                      className="me-3 edit-icon"
                      size={20}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    Resend
                  </DropdownItem>
                </span>
              )}

              <span onClick={() => props.deletePrevetted(sjgrantee.id)}>
                <DropdownItem>
                  <BsXCircleFill
                    className="me-3 remove-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Delete
                </DropdownItem>
              </span>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      {opendetail && (
        <PrevettedDetailsBody
          opendetail={opendetail}
          handleClose={() => {
            setOpenDetail();
            setDetailId(null);
          }}
          sjId={detailId}
          sjgrantee={sjgrantee}
        />
      )}

      {openUpateDetail && (
        <PrevettedUpdateModal
          openUpateDetail={openUpateDetail}
          handleClose={() => {
            setOpenUpdateDetail();
            setDetailId(null);
          }}
          prevettedUser={prevettedUser}
          sjgrantee={sjgrantee}
          getPrevettedGrantees={props.getPrevettedGrantees}
        />
      )}

      {openContract && (
        <InvitationModal
          openContract={openContract}
          handleClose={() => {
            setOpenContract();
            setContractId(null);
          }}
          contractId={contractId}
          resend={sjgrantee.invitation_status === "SENT"}
          getPrevettedGrantees={props.getPrevettedGrantees}
        />
      )}
    </>
  );
};

export default InvitationModule;
