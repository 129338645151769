import http from "./http";

const nominee = {
  add: (data) => http.post("/user/nomination", data),
  list_nomination: (param) => http.get("/user/nomination/history", { params: param }),
  list: (param) => http.get("/admin/nomination", { params: param }),
  preview: (param) => http.get("admin/nomination/email-preview", { params: param }),
  nomination_dropdowns: (param) => http.get("/nomination-dropdowns", { params: param }),
  states: (param) => http.get("/us-states", { params: param }),
  delete: (param) => http.delete(`/admin/nomination/${param}`),
  getOne: (param) => http.get(`/admin/nomination/${param}`),
  update: (data) => http.patch(`/admin/nomination/${data.id}`, data),
  send: (data) => http.post("/admin/nomination/send-confirmation-mail", data),
  action: (data) => http.post("/admin/nomination/action", data),
  getExcel: (param) => http.get("/admin/nomination/export", { params: param, responseType: 'blob'}),

};

export default nominee;
