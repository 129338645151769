import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  Nav,
  Alert,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { Link, useLocation } from "react-router-dom";
import AddEditFaq from "./AddEditFaq";
import SettingSidebar from "../SettingSidebar";
import { HiInformationCircle } from "react-icons/hi";
import Public from "./Public";
import Donor from "./Donor";
import Grantee from "./Grantee";
import faq from "../../../Services/faq";

const Index = () => {
  const [faqId, setFaqId] = useState("");
  const [donorfaq, setDonorFaq] = useState([]);
  const [granteefaq, setGranteeFaq] = useState([]);
  const [publicfaq, setPublicFaq] = useState([]);
  const [open, setOpen] = useState(false);
  const [showTab1, setShowTab1] = useState(true);

  LoadingOverlay.propTypes = undefined;
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const getFaqs = (fields = {}) => {
    setLoader(true);
    let params = { ...fields };
    faq
      .list(params)
      .then((res) => {
        if (res?.data?.status) {
          setDonorFaq(
            res?.data?.data?.data.filter((f) => parseInt(f.donors) === 1)
          );
          setPublicFaq(
            res?.data?.data?.data.filter((f) => parseInt(f.public) === 1)
          );
          setGranteeFaq(
            res?.data?.data?.data.filter((f) => parseInt(f.grantees) === 1)
          );
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const openFaqEditModal = (faqId) => {
    setOpen(true);
    setFaqId(faqId);
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Faq : seeding justice</title>
      </Helmet>
      <Row>
        <Col md={4} lg={3}>
          <SettingSidebar />
        </Col>
        <Col md={8} className="mt-2" lg={9}>
          <Card className="shadow support_aside mb-5" style={{ width: "100%" }}>
            <CardHeader className="py-3">
              <Row>
                <Col lg={6} md={4} sm={12}>
                  Frequently Asked Questions
                </Col>
                <Col lg={6} md={4} sm={12}>
                  <Row className="no-gutters justify-content-end">
                    <Col sm={12} md={4}>
                      <Button
                        type="button"
                        onClick={() => setOpen(true)}
                        size="md"
                        block
                        className="mt-md-0 mt-2 add_button"
                        style={{ borderRadius: "30px" }}
                      >
                        Add New
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <Alert color="primary" style={{ margin: "10px" }}>
              <HiInformationCircle size={20} /> Drag & drop any question to
              change the order.
            </Alert>
            <CardBody>
              <div className="defalut-overview">
                <Nav tabs className="border-0">
                  <Col md={12} sm={12}>
                    <Row className="m-0 nomination_module">
                      <Col
                        md={3}
                        sm={6}
                        xs={6}
                        className={
                          location.pathname.includes("public")
                            ? "active_grant_tab p-1"
                            : "grant_tab p-1"
                        }
                        /* onClick={() => handleTabChange(1)} */
                      >
                        <Link
                          to="/admin/settings/faqs/public"
                          className="text-decoration-none"
                        >
                          <h5 style={{ marginLeft: "1rem" }}>For Public</h5>
                        </Link>
                      </Col>
                      <Col
                        md={3}
                        sm={6}
                        xs={6}
                        className={
                          location.pathname.includes("donors")
                            ? "active_grant_tab p-1"
                            : "grant_tab p-1"
                        }
                        /* onClick={() => handleTabChange(2)} */
                      >
                        <Link
                          to="/admin/settings/faqs/donors"
                          className="text-decoration-none"
                        >
                          <h5 style={{ marginLeft: "1rem" }}>For Donors</h5>
                        </Link>
                      </Col>
                      <Col
                        md={4}
                        sm={6}
                        xs={6}
                        className={
                          location.pathname.includes("grantee")
                            ? "active_grant_tab p-1"
                            : "grant_tab p-1"
                        }
                      >
                        <Link
                          to="/admin/settings/faqs/grantee"
                          className="text-decoration-none"
                        >
                          <h5 style={{ marginLeft: "1rem" }}>For Grantees</h5>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Nav>
              </div>

              <div
                className={
                  showTab1
                    ? "customise_table grant_customise_table"
                    : "customise_table border-r6"
                }
              >
                <TabContent>
                  <TabPane className="nomination_table">
                    {location.pathname.includes("public") && (
                      <Public
                        publicfaq={publicfaq}
                        setPublicFaq={setPublicFaq}
                        loader={loader}
                        openFaqEditModal={openFaqEditModal}
                      />
                    )}
                    {location.pathname.includes("donor") && (
                      <Donor
                        donorfaq={donorfaq}
                        setDonorFaq={setDonorFaq}
                        loader={loader}
                        openFaqEditModal={openFaqEditModal}
                      />
                    )}
                    {location.pathname.includes("grantee") && (
                      <Grantee
                        granteefaq={granteefaq}
                        setGranteeFaq={setGranteeFaq}
                        loader={loader}
                        openFaqEditModal={openFaqEditModal}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {open && (
        <AddEditFaq
          open={open}
          handleClose={() => {
            setOpen();
            setFaqId(null);
          }}
          faqId={faqId}
          getFaqs={getFaqs}
        />
      )}
    </div>
  );
};

export default Index;
