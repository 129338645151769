import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import dmf from "../../Services/dmfsubmission";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import common from "../../Services/common";

const CreateDonor = (props) => {
  const [fields, setFields] = useState({is_active:0});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [createDonor, setCreateDonor] = useState(false);
  const [alert, setAlert] = useState("");

  LoadingOverlay.propTypes = undefined;
  
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid first name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid last name";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address";
      formIsValid = false;
    }
    if (!fields.initial_funding_amount) {
      errors["initial_funding_amount"] = "Amount can not be empty";
      formIsValid = false;
    }
    if (fields.initial_funding_amount){
      const amount = fields.initial_funding_amount.replace("$", "");
      if (parseInt(amount) <= 0) {
        errors["initial_funding_amount"] = "Amount must be greater than $0";
        formIsValid = false;
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (e, field) => {
    setFields({
      ...fields,
      [field]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
    if (field == "send_reset_mail") {
      setCreateDonor(e.target.checked);
    }
  };
  console.log(createDonor);

  const getDonor = (id) => {
    let params = id;
    setLoader(true);
    dmf
      .getDonor(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setLoader(false);
        } else {
          let errors = {};
          for (let key in res.data.message) {
            errors[key] = res.data.message[key];
          }
          setErrors(errors);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsloader(true);
      let params = {
        send_reset_mail: fields.send_reset_mail,
        email: fields.email,
        initial_funding_amount: fields.initial_funding_amount
        .replace("$", "")
        .replace(/,/g, "")
      };
      dmf
        .donor(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            props.handleClose();
            props.getDmfSubmission();
            setIsloader(false);
          } else if (res.status) {
            if (res.data.message) {
              setAlert(res.data.message);
              setLoader(false);
            }
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };
  useEffect(() => {
    if (props.donorId) {
      getDonor(props.donorId);
    }
  }, []);
  return (
    <div>
      <Modal isOpen={props.open} size="md" className="admin create-donor-modal">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            Create Donor
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={
                <Spinner
                  costyle={{ height: 50, width: 50, color: "#00344B" }}
                />
              }
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="first_name">First Name</Label>
                    <Input
                      id="first_name"
                      name="first_name"
                      placeholder=""
                      type="first_name"
                      value={fields["first_name"] ? fields["first_name"] : ""}
                      onChange={(event) => handleChange(event, "first_name")}
                      invalid={errors["first_name"] ? true : false}
                    />
                    <FormFeedback>{errors["first_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="last_name">Last Name</Label>
                    <Input
                      id="last_name"
                      name="last_name"
                      placeholder=""
                      type="last_name"
                      value={fields["last_name"] ? fields["last_name"] : ""}
                      onChange={(event) => handleChange(event, "last_name")}
                      invalid={errors["last_name"] ? true : false}
                    />
                    <FormFeedback>{errors["last_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder=""
                      type="text"
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                      invalid={errors["email"] ? true : false}
                    />
                    <FormFeedback>{errors["email"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className="initial_fund">
                    <Label for="initial_funding_amount">
                      Initial Funding Amount (estimate)
                    </Label>
                    <CurrencyFormat
                      placeholder="$0.00"
                      className="form-control"
                      value={
                        fields["initial_funding_amount"]
                          ? fields["initial_funding_amount"]
                          : ""
                      }
                      inputmode="numeric"
                      thousandSeparator={true}
                      prefix={"$"}
                      invalid={errors["initial_funding_amount"] ? true : false}
                      onChange={(event) =>
                        handleChange(event, "initial_funding_amount")
                      }
                    />
                    {errors["initial_funding_amount"] && (
                      <small className="fa-1x text-danger">
                        {errors["initial_funding_amount"]}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Input
                      className="me-2"
                      id="send_reset_mail"
                      type="checkbox"
                      color="primary"
                      name="send_reset_mail"
                      onChange={(event) =>
                        handleChange(event, "send_reset_mail")
                      }
                    />
                    <Label for="send_reset_mail" check>
                      Create Donor Login
                    </Label>
                  </FormGroup>
                </Col>

                {alert ? (
                  <span className="text-danger">{alert ? alert : null}</span>
                ) : (
                  ""
                )}
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button
              color={createDonor == true ? "success" : "secondary"}
              size="md"
              type="submit"
              disabled={!createDonor || isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              Add
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateDonor;
