import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import nominee from "../Services/nominee";
// import Is_Ein_Yes from "./Is_Ein_Yes";
import Is_Ein_No from "./Is_Ein_No";

const NominationForm = () => {
  const [fields, setFields] = useState({
    ein_no: [
      {
        key: 0,
        ein_number: "",
        organization_name: "",
        city: "",
        select_state: "",
        street: "",
        organization_website: "",
        grant_amount: "",
        recognition_id: "",
        support_status: "option1",
      },
    ],
  });
  const [nominationDropdowns, setNominationDropdowns] = useState([]);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [isloader, setIsloader] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const updateNoFields = (field, index) => {
    fields["ein_no"][index] = field;
    setFields(fields);
  };

  const handleSubmit = (e) => {
    setIsloader(true);
    e.preventDefault();
    let data = {
      ein_no: fields.ein_no,
      additional_queries: fields.additional_queries,
    };
    nominee
      .add({ ...data })
      .then((res) => {
        if (res.data.status) {
          setIsloader(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/success-nomination");
          setFields({});
          setErrors({});
        } else {
          // let err, msg, errorsz;
          // errorsz = res.data.message;
          // for (let key in errorsz) {
          //   err = errorsz[key][0];
          //   msg = err.split(".");
          //   msg[2] = msg[2].replace("_", " ");
          //   errorsz[key][0] =
          //     msg[2].charAt(0).toUpperCase() + msg[2].slice(1) ?? null;
          // }
          setErrors(res.data.message);
          setIsloader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
  };

  const getDropdowns = () => {
    nominee
      .nomination_dropdowns()
      .then((res) => {
        if (res.data.status) {
          setNominationDropdowns(res.data.data.recognition);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getDropdowns();
    getStates();
  }, []);

  const addNoBlock = () => {
    let ein_no = fields.ein_no;
    ein_no.push({
      key: ein_no.length,
      ein_number: "",
      organization_name: "",
      city: "",
      select_state: "",
      street: "",
      organization_website: "",
      // grant_amount: "",
      recognition_id: "",
      support_status: "option1",
      support_queries: "",
    });

    setFields({ ...fields, [ein_no]: ein_no });
  };

  const removeNoBlock = (key) => {
    let ein_no = fields.ein_no;
    let indexToBeRemoved = ein_no.filter((ein) => ein.key === key);
    ein_no.splice(indexToBeRemoved - 1, 1);
    setFields({ ...fields, [ein_no]: ein_no });
  };

  const getStates = () => {
    nominee
      .states()
      .then((res) => {
        if (res.data.status) {
          let states = [];
          res.data.data.forEach((state, index) => {
            states[index] = {
              label: state.name,
              value: state.id,
            };
          });
          setStates(states);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="p-2">
          <Col md={12}>
            {fields.ein_no.map((nb, ybIndex) => (
              <Is_Ein_No
                nominationDropdowns={nominationDropdowns}
                keyValue={ybIndex}
                removeYesBlock={removeNoBlock}
                errors={errors}
                key={ybIndex}
                updateNoFields={updateNoFields}
                states={states}
                initialFields={nb}
                fields={fields}
              />
            ))}
            <p className="text-end">
              <Button
                type="button"
                className="btn-md add-more-button"
                // color="primary"
                onClick={() => addNoBlock()}
              >
                <span>+</span> Add More
              </Button>
            </p>
          </Col>
          <Col md={12} className="text-end">
            <Button size="md" type="submit" disabled={isloader} className="orange-button">
              {isloader && <Spinner size="sm" className="mx-1" />}
              {/* {props.nominationId ? "Update Nomination" : "Submit Nomination "} */}
              Submit Nomination
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NominationForm;
