import moment from "moment";
import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { CardBody, Card, Col, Row, Button, Table, Spinner } from "reactstrap";
import Sidebar from "../Sidebar";
import QuarterlyReportModal from "./QuartelyReportModal";
import { FaDollarSign, FaFileDownload } from "react-icons/fa";
import { BiCalendar } from "react-icons/bi";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import userdashboard from "../../../Services/userdashboard";

const Index = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [lastTransaction, setLastTransaction] = useState({});
  const [averagetransaction, setAverageTransaction] = useState("");
  const [reportId, setReportId] = useState("");
  const [reportOpen, setReportOpen] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [exportloader, setExportloader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState({});
  const [loaderMore, setLoaderMore] = useState(false);
  LoadingOverlay.propTypes = undefined;

  

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "Asc"; 
      e.target.className = "border-top-0 Asc"; 
    } else if (e.target.className.indexOf("Asc") > 0) {
      sort = column;
      direction = "Desc"; 
      e.target.className = "border-top-0 Desc"; 
    } else if (e.target.className.indexOf("Desc") > 0) {
      sort = column;
      direction = "Asc"; 
      e.target.className = "border-top-0 Asc"; 
    }
    setSort({
      sort,
      direction,
    });
  };
  


  const getTransactions = (fields={},id) => {
    setLoader(true);
    let param;
    if (id) {
      param = { ...fields,skip:skip, ...sort};
    } else {
      param = { ...fields,...sort};
    }
    
   
    userdashboard
      .donorTransactions(param)
      .then((res) => {
        setTransactions(res?.data?.data?.recent_transactions);
        setLoader(false);
        let new_tr;
        if(param?.skip){
          new_tr = [...transactions, ...res?.data?.data?.recent_transactions];
        }else{
          new_tr = res?.data?.data?.recent_transactions;
        }
        setTransactions(new_tr);
        setSkip(res?.data?.data?.skip);
        if (res?.data?.data?.next) {
          setLoaderMore(true);
        } else {
          setLoaderMore(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const getDashboardTransaction = () => {
    setIsloader(true);
    userdashboard
      .transactionsDashboard()
      .then((res) => {
        setTotalTransaction(res?.data?.data?.life_time_donation);
        setLastTransaction(res?.data?.data?.last_transaction);
        setAverageTransaction(res?.data?.data?.average_transaction);
        setIsloader(false);
      })
      .catch(function (error) {
        setIsloader(false);
        console.log("error", error);
      });
  };

  const ReportModal = (report_id) => {
    setReportOpen(true);
    setReportId(report_id);
  };
  const exportRecord = () => {
    setExportloader(true);
    userdashboard.getTransactionexcel().then((res) => {
      setExportloader(false);
      if (res.data) {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `RecentTransaction.xlsx`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          setExportloader(false);
          console.log(error);
        }
      }
    });
  };

  useEffect(() => {
    getDashboardTransaction();
  }, []);

  useEffect(() => {
    getTransactions(sort);
  }, [sort]);
  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <div className="mt-4 mb-5 userAdminArticle">
            <div className="py-3 userAdminArticleHeading">
              <h4 className="mb-0">View Your Giving History</h4>
            </div>
            <Row className="mt-4 mb-5">
              <Col lg={9}>
                <Row>
                  <Col sm={4}>
                    <Card className="history-cart status donation">
                      <CardBody>
                        <div className="dashboard-dollar-icons">
                          <FaDollarSign />
                        </div>
                        <h5>Total Donation</h5>
                        {isloader ? (
                          <Spinner size="sm" className="mx-1" />
                        ) : (
                          <p>
                            {totalTransaction ? `$${totalTransaction}` : ""}
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card className="history-cart transaction">
                      <CardBody className="pb-2">
                        <div className="dashboard-dollar-icons transaction-icon">
                          <FaDollarSign />
                        </div>
                        <h5>Latest Transaction</h5>
                        {isloader ? (
                          <Spinner size="sm" className="mx-1" />
                        ) : (
                          <>
                            {lastTransaction?.amount ? (
                              <>
                                <p>${lastTransaction?.amount} </p>
                                <p className="selected-date">
                                  <span className="history-card-date">
                                    <BiCalendar size={25} />
                                    {moment(lastTransaction?.date).format(
                                      "D MMM,  YYYY"
                                    )}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <p>$0</p>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card className="history-cart average-transaction">
                      <CardBody>
                        <div className="dashboard-dollar-icons average-transaction-icon">
                          <FaDollarSign />
                        </div>
                        <h5>Average Transaction</h5>
                        {isloader ? (
                          <Spinner size="sm" className="mx-1" />
                        ) : (
                          <p>
                            {averagetransaction ? `$${averagetransaction}` : ""}
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg={3}>
                <Row className="mt-5">
                  <Col md={12} className="text-center history_button">
                    <Link className="btn btn-grant" to="/recommend-grants">
                      Submit a Grant Nomination
                    </Link>

                    <Button
                      type="submit"
                      size="md"
                      color="link"
                      onClick={() => ReportModal()}
                    >
                      Review Your Quarterly Report
                    </Button>

                    <Link className="btn btn-success" to="/make-contributions">
                      Make a Contribution
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <hr />
                <div className="d-flex justify-content-between">
                  <h5>Recent Transactions</h5>
                  {transactions?.length > 1 && (
                    <Button
                      className="orange-button"
                      disabled={exportloader}
                      onClick={exportRecord}
                    >
                      {exportloader ? (
                        <Spinner size="sm" className="mx-1" />
                      ) : (
                        <FaFileDownload className="me-1" />
                      )}
                      Export
                    </Button>
                  )}
                </div>
                <CardBody>
                  <LoadingOverlay
                    active={Loader}
                    spinner={
                      <Spinner
                        style={{ height: 60, width: 60, color: "#00344B" }}
                      />
                    }
                    fadeSpeed={100}
                    classNamePrefix="mitiz"
                  >
                    <Table responsive className="newThemeTable table-hover">
                      <thead>
                        <tr>
                          <th className="num">S.No.</th>
                          <th>
                            <span
                              className="border-top-0 sortable sort"
                              onClick={(event) =>
                                sortRecord(event, "Date") 
                              }
                            >
                              Date
                            </span>
                          </th>
                          <th>Amount</th>
                          <th>Payment method</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length > 0
                          ? transactions.map((transaction, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  {transaction?.date
                                    ? moment(transaction?.date).format(
                                        "D MMM,  YYYY"
                                      )
                                    : ""}
                                </td>

                                <td>
                                  {transaction?.amount
                                    ? `$${transaction?.amount}`
                                    : ""}
                                </td>

                                <td>
                                  {transaction.payment_method.replace(
                                    "CreditCard",
                                    "Credit Card"
                                  )}
                                </td>
                              </tr>
                            ))
                          : !Loader &&
                            !transactions.length > 0 && (
                              <tr>
                                <td key={0} colSpan="4">
                                  <p className="text-center">
                                    No Transactions Found.
                                  </p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </Table>
                  </LoadingOverlay>
                  {loaderMore ? (
                    <div className="load_more d-flex justify-content-center align-items-center">
                      {!isloader ? (
                        <Button
                          className="orange-button"
                          onClick={() => getTransactions(false,1)}
                        >
                          Load More
                        </Button>
                      ) : (
                        <Spinner
                          style={{ width: "30px", height: "30px" }}
                          className="mx-1"
                        />
                      )}
                    </div>
                  ) : null}
                </CardBody>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {reportOpen && (
        <QuarterlyReportModal
          reportOpen={reportOpen}
          handleClose={() => {
            setReportOpen();
            setReportId(null);
          }}
          reportId={reportId}
        />
      )}
    </div>
  );
};

export default Index;
