import axios from "axios";
import http from "./http";

const prevettedsj = {
  addUpdate: (data, id) => {
    if (data && id) {
      return http.post(`/admin/prevetted-grantees/update`, data);
    } else {
      return http.post("/admin/prevetted-grantees/create", data);
    }
  },
  granteeList: (param) => http.get("/admin/prevetted-grantees/list?q=all", { params: param }),
  list: async(param) =>{
      return  await axios.all([http.get("/admin/prevetted-grantees/list?q=all", { params: param }), http.get("/admin/prevetted-grantees/list?q=update-request", { params: param })])
  },
  categorylist: (param) =>http.get("/prevetted-categories", { params: param }),

  prevttedcategorylist: (param) =>http.get("/prevetted-categories", { params: param }),
  prevttedDetails: (param) =>http.get("/admin/prevetted-grantees/get", { params: param }),
  delete: (param) => http.post(`/admin/prevetted-grantees/delete`,{id:param}),
  delete_logo: (param) => http.post(`/admin/prevetted-grantees/remove-logo`,{id:param}),
  getOne: (param) => http.get(`/admin/prevetted-grantees/get?id=${param}`),
  getPrevetted: (param) => http.get(`/admin/prevetted-grantees/get?q=update-request&id=${param}`),
  getExcel: (param) =>
    http.get("/admin/prevetted-grantees/export", {
      params: param,
      responseType: "blob",
    }),
    action: (data) => http.post("/admin/prevetted-grantees/send-invitation", data), 
  preview: (param) => http.get("admin/common/email-preview", { params: param }),
  prevettedToken: (param) => http.get("/validate-token", { params: param }),
  createprevetted: (data) => http.post("/prevetted-grantees/submit-invitation-form", data),
  sendVerification: (data) => http.post("/prevetted-grantees/send-verification-code", data),
  approveUser : (data) =>  http.post("admin/prevetted-grantees/approve-update-request", data),
};


export default prevettedsj;
