import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import LoadingOverlay from "react-loading-overlay";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import common from "../../../Services/common";
import adminsetting from "../../../Services/adminsettings";
import SettingSidebar from "../SettingSidebar";

const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [ach_details, setAchDetails] = useState("");
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);
  LoadingOverlay.propTypes = undefined;

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.dmf_notify_emails) {
      errors["dmf_notify_emails"] = "Emails can not be empty";
      formIsValid = false;
    }
    if (fields.dmf_notify_emails && fields.dmf_notify_emails.length === 0) {
      errors["dmf_notify_emails"] = "Emails can not be empty";
      formIsValid = false;
    }
    if (fields.dmf_notify_emails && fields.dmf_notify_emails.length >= 0) {
      let validEmails = 0;
      fields.dmf_notify_emails.forEach((email) => {
        if (!common.isValidEmail(email.label)) {
          validEmails++;
          errors["dmf_notify_emails"] = "Enter valid Email Address!";
          formIsValid = false;
        }
      });
    }
    // if (validEmails >= 0) {
    //   errors["dmf_notify_emails"] = "Enter valid Email Address!";
    //   formIsValid = false;
    // }
    if (!fields.admin_emails) {
      errors["admin_emails"] = "Admin email can not be empty.";
      formIsValid = false;
    }
    if (fields.admin_emails && fields.admin_emails.length === 0) {
      errors["admin_emails"] = "Emails can not be empty";
      formIsValid = false;
    }
    if (fields.admin_emails && fields.admin_emails.length >= 0) {
      let validEmails = 0;
      fields.admin_emails.forEach((email) => {
        if (!common.isValidEmail(email.label)) {
          validEmails++;
          errors["admin_emails"] = "Enter valid Email Address!";
          formIsValid = false;
        }
      });
    }
    if (!ach_details) {
      errors["ach_details"] = "ACH wire details can not be empty";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };
  const handleContentChange = (ach_details) => {
    setAchDetails(ach_details);
  };
  const handleEmailChange = (value) => {
    fields["dmf_notify_emails"] = value;
    setFields(fields);
    setErrors({ ...errors, ["dmf_notify_emails"]: "" });
  };

  const handleAdminEmailChange = (value) => {
    fields["admin_emails"] = value;
    setFields(fields);
    setErrors({ ...errors, ["admin_emails"]: "" });
  };
  const handleGranteeEmailChange = (value) => {
    fields["grantee_notify_emails"] = value;
    setFields(fields);
    setErrors({ ...errors, ["grantee_notify_emails"]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = [];
      let params2 = [];
      let params3 = [];
      fields["dmf_notify_emails"].map((x) => {
        params.push(x.label);
      });

      fields["admin_emails"].map((x) => {
        params2.push(x.label);
      });
      fields["grantee_notify_emails"].map((x) => {
        params3.push(x.label);
      });

      params = {
        dmf_notify_emails: params,
        admin_emails: params2,
        grantee_notify_emails: params3,
        ach_details: ach_details,
      };

      setIsloader(true);
      adminsetting.addEmail(params).then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsloader(false);
          getEmails();
          // setErrors({});
        } else {
          let errors = {};
          for (let key in res.data.message) {
            errors[key] = res.data.message[key];
          }
          setErrors(errors);
          setIsloader(false);
        }
      });
    }
  };
  const getEmails = () => {
    setLoader(true);
    adminsetting
      .getEmail()
      .then((res) => {
        if (res.data.status) {
          if (res.data.data !== null) {
            let fields = [];
            res.data.data.dmf_notify_emails.forEach((mail, index) => {
              fields[index] = {
                label: mail.email,
                value: mail.email,
              };
            });

            if (res.data.data !== null) {
              let adminEmail = [];
              res.data.data.admin_emails.forEach((mail, index) => {
                adminEmail[index] = {
                  label: mail.email,
                  value: mail.email,
                };
              });
              fields["admin_emails"] = adminEmail;
            }
            if (res.data.data !== null) {
              let granteeEmail = [];
              res.data.data.grantee_notify_emails.forEach((mail, index) => {
                granteeEmail[index] = {
                  label: mail.email,
                  value: mail.email,
                };
              });
              fields["grantee_notify_emails"] = granteeEmail;
            }

            fields["dmf_notify_emails"] = fields;
            setAchDetails(res.data.data.ach_details);
            setFields(fields);
            setLoader(false);
          }
        }
      })

      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];

  useEffect(() => {
    getEmails();
  }, []);
  return (
    <div className="mt-4" style={{ minHeight: 300 }}>
      <Row>
        <Col md={4} lg={3}>
          <SettingSidebar />
        </Col>
        <Col md={8} lg={9}className="mt-2">
          <Card className="shadow support_aside mb-5" style={{ width: "100%" }}>
            <CardHeader className="py-3">Global Configs</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <LoadingOverlay
                  active={loader}
                  spinner={
                    <Spinner
                      style={{ height: 60, width: 60, color: "#00344B" }}
                    />
                  }
                  fadeSpeed={100}
                  classNamePrefix="mitiz"
                >
                  <FormGroup>
                    <Label for="dmf_notify_emails">
                      {" "}
                      DMF Submission Recipients
                    </Label>
                    <CreatableSelect
                      id="dmf_notify_emails"
                      name="dmf_notify_emails"
                      placeholder={<div>Write your emails...</div>}
                      isMulti
                      value={
                        fields["dmf_notify_emails"]
                          ? fields["dmf_notify_emails"]
                          : []
                      }
                      options={fields}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleEmailChange}
                    />
                    {errors["dmf_notify_emails"] && (
                      <small className="fa-1x text-danger">
                        {errors["dmf_notify_emails"]}
                      </small>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label for="admin_emails">Admin Emails</Label>
                    <CreatableSelect
                      id="admin_emails"
                      name="admin_emails"
                      placeholder={<div>Write your emails...</div>}
                      isMulti
                      value={
                        fields["admin_emails"] ? fields["admin_emails"] : []
                      }
                      options={fields}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleAdminEmailChange}
                    />
                    {errors["admin_emails"] && (
                      <small className="fa-1x text-danger">
                        {errors["admin_emails"]}
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="grantee_notify_emails">
                      Grantee Email Notification
                    </Label>
                    <CreatableSelect
                      id="grantee_notify_emails"
                      name="grantee_notify_emails"
                      placeholder={<div>Write your emails...</div>}
                      isMulti
                      value={
                        fields["grantee_notify_emails"]
                          ? fields["grantee_notify_emails"]
                          : []
                      }
                      options={fields}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleGranteeEmailChange}
                    />
                    {errors["grantee_notify_emails"] && (
                      <small className="fa-1x text-danger">
                        {errors["grantee_notify_emails"]}
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="ach_details">ACH/ Wire Transfer details</Label>
                    <ReactQuill
                      theme="snow"
                      name="ach_details"
                      id="ach_details"
                      value={ach_details}
                      modules={modules}
                      formats={formats}
                      onChange={handleContentChange}
                      invalid={errors["ach_details"] ? true : false}
                      style={{ minHeight: "250px" }}
                    />
                    {errors["ach_details"] && (
                      <FormText color="danger" size="md">
                        +{errors["ach_details"]}
                      </FormText>
                    )}
                  </FormGroup>
                  <Col className="d-flex justify-content-start">
                    <Button
                      color="success"
                      type="submit"
                      className="submit-Id-button"
                      disabled={isloader}
                    >
                      {isloader && <Spinner size="sm" className="mx-1" />}
                      Submit
                    </Button>
                  </Col>
                </LoadingOverlay>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
