import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  FormGroup,
  Input,
  Card,
  CardBody,
  Button,
  CardFooter,
} from "reactstrap";
import {
  FaFilePdf,
  FaFileWord,
  FaFileImage,
  FaFileExcel,
  FaFileCsv,
  FaDownload,
} from "react-icons/fa";
import LoadingOverlay from "react-loading-overlay";
import userdashboard from "../../../Services/userdashboard";
import { BsFiletypeDoc } from "react-icons/bs";

LoadingOverlay.propTypes = undefined;

const QuarterlyReportModal = (props) => {
  const [fields, setFields] = useState({
    report_year: new Date().getFullYear(),
    quarter: "1",
  });

  const [reports, setReports] = useState([]);
  const [isloader, setIsloader] = useState(false);
  const [Loader, setLoader] = useState(false);
  const year = new Date().getFullYear();
  const years = Array.from(new Array(5), (val, index) => year - index);

  const handleChange = (e, field) => {
    console.log(field);
    setFields({ ...fields, [field]: e.target.value });
  };

  const reviewQuarterReports = () => {
    setIsloader(true);
    userdashboard
      .reportList({ ...fields })
      .then((res) => {
        if (res.data.status) {
          setReports(res.data.data);
        }
        setIsloader(false);
      })
      .catch(function (error) {
        setIsloader(false);
        console.log("error", error);
      });
  };

  const downloadFile = (id, file_ext,year) => {
    console.log(year);
    setLoader(id);
    let params = id;
    userdashboard.download(params).then((res) => {
      setLoader(false);
      if (res.data) {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Quarterly-${fields.quarter}-Report-File-${year}.${file_ext}`
          );
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          setLoader(false);
          console.log(error);
        }
      }
    });
  };

  useEffect(() => {
    reviewQuarterReports();
  }, [fields]);

  return (
    <div>
      <Modal isOpen={props.reportOpen} size="lg" className="user_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          Quarterly Reports
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Input
                    className="orange-border"
                    type="select"
                    name="report_year"
                    value={fields["report_year"] ? fields["report_year"] : ""}
                    onChange={(event) => handleChange(event, "report_year")}
                    style={{ cursor: "pointer" }}
                  >
                    {years.map((year, index) => {
                      return (
                        <option key={`key-year${index}`} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="form-group mt-3">
                  <input
                    type="radio"
                    className="btn-check"
                    name="quarter"
                    id="info-outlined"
                    onChange={(event) => handleChange(event, "quarter")}
                    value="1"
                    checked={fields.quarter === "1"}
                  />
                  <label
                    className="quarter-box btn btn-outline-info me-2"
                    for="info-outlined"
                  >
                    Quarter 1
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="quarter"
                    id="info-outlined2"
                    onChange={(event) => handleChange(event, "quarter")}
                    value="2"
                    checked={fields.quarter === "2"}
                  />
                  <label
                    className="quarter-box btn btn-outline-info me-2"
                    for="info-outlined2"
                  >
                    Quarter 2
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="quarter"
                    id="info-outlined3"
                    onChange={(event) => handleChange(event, "quarter")}
                    value="3"
                    checked={fields.quarter === "3"}
                  />
                  <label
                    className="quarter-box btn btn-outline-info me-2"
                    for="info-outlined3"
                  >
                    Quarter 3
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="quarter"
                    id="info-outlined4"
                    onChange={(event) => handleChange(event, "quarter")}
                    value="4"
                    checked={fields.quarter === "4"}
                  />
                  <label
                    className="quarter-box btn btn-outline-info"
                    for="info-outlined4"
                  >
                    Quarter 4
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {reports.length > 0 && <h5>Reports</h5>}
            <LoadingOverlay
              active={isloader}
              spinner={
                <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
              }
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row style={{ minHeight: "150px" }}>
                {reports.length > 0
                  ? reports.map((report, i) => (
                      <Col md={3} sm={4} className="mb-2" key={i}>
                        <div className="quarterly-reports-box">
                          <Card key={i} className="mt-2 mb-0 report_card">
                            <CardBody>
                            {report.file_ext === "jpg" && (
                            <FaFileImage size={100} />
                          )}
                              {
                                (report.file_ext === "png" && (
                                  <FaFileImage size={100} />
                                ))}
                              {report.file_ext === "pdf" && (
                                <FaFilePdf size={100} />
                              )}
                              {report.file_ext === "csv" && (
                                <FaFileCsv size={100} />
                              )}
                              {report.file_ext === "xlsx" && (
                                <FaFileExcel size={100} />
                              )}
                              {report.file_ext === "xls" && (
                            <FaFileExcel size={100} />
                          )}
                              {report.file_ext === "docx" && (
                                <FaFileWord size={100} />
                              )}
                              {report.file_ext === "doc" && (
                            <BsFiletypeDoc size={100} />
                          )}
                            </CardBody>

                            <CardFooter className="text-center">
                              <Button
                                className="btn-lg download-button"
                                onClick={() =>
                                  downloadFile(report.id, report.file_ext,report.report_year)
                                }
                                disabled={report.id === Loader}
                              >
                                {report.id === Loader ? (
                                  <Spinner size="sm" className="me-1 mt-1" />
                                ) : (
                                  <FaDownload />
                                )}
                              </Button>
                              {/* <h6 className="mt-2"> Download </h6> */}
                            </CardFooter>
                          </Card>
                        </div>
                      </Col>
                    ))
                  : !isloader &&
                    !reports.length > 0 && (
                      <div className="quarterly-report">
                        <p key={0}>Record not found</p>
                      </div>
                    )}
              </Row>
            </LoadingOverlay>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};

export default QuarterlyReportModal;
