import React from "react";
import { Container } from "reactstrap";

const SuccessIntakeFormSubmit = () => {
  return (
    <Container>
      <div
        className="d-flex h-100 w-100 align-items-center justify-content-center pb-2"
        style={{ minHeight: "450px" }}
      >
        <div className="text-center">
          <div className="wrapper">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
          <h2>Thank you for submitting your DMF Intake Form Submission!</h2>
          <p>
            You will receive email updates from us throughout this process. If
            you have any questions please reach out to{" "}
            <a href="mailto:info@seedingjustice.org" style={{ color: '#e26a38' }}>info@seedingjustice.org</a>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SuccessIntakeFormSubmit;
