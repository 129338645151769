import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Helmet } from "react-helmet";
import DmfSubmissionForm from "../../Common/DmfSubmissionForm";
import { AiOutlineMail } from "react-icons/ai";
import {
  BsReverseLayoutTextWindowReverse,
  BsCurrencyDollar,
  BsFillForwardFill,
} from "react-icons/bs";
import common from "../../Services/common";
import { toast } from "react-toastify";
import dmf from "../../Services/dmfsubmission";
import { useNavigate } from "react-router-dom";

const DmfForm = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};

    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid first name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid last name";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      setLoader(true);
      dmf
        .newsadd(params)
        .then((res) => {
          setLoader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            navigate("/newsletter-subscribed");
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
    }
  };
  
  return (
    <>
      <Helmet>
        <title>Dmf : seeding-Justice</title>
      </Helmet>
      <section className="homepage_banner">
        <Container>
          <Row>
            <Col md={6} className="d-flex">
              <div className="homepage-content">
                <h1 className="mb-3">Open Your Donor-In-Movement Fund</h1>
                <hr />
                <div className="stepping">
                  <div className="mainWrapper">
                    <div className="statusBar">
                      <span className="pBar"></span>
                      <div className="node done nConfirm0">
                        <div className="stepping-icon">
                          <BsReverseLayoutTextWindowReverse />
                        </div>
                        <div className="stepping-details">
                          <span className="text-heading done nConfirm0">
                            <h5>Step 1</h5>
                          </span>
                          <div className="main done done nConfirm0"></div>
                          <div className="d-flex">
                            <span className="text done nConfirm0">
                              Fill in your information.
                            </span>
                            <BsFillForwardFill
                              style={{ color: "#E26A38" }}
                              size={20}
                              className="ms-2 d-none d-md-block"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="node nConfirm1">
                        <div className="stepping-icon">
                          <AiOutlineMail />
                        </div>
                        <div className="stepping-details">
                          <span className="text-heading done nConfirm0">
                            <h5>Step 2</h5>
                          </span>
                          <div className="main done done nConfirm0"></div>
                          <span className="text nConfirm1">
                            We will contact you within two business days to
                            continue setting up your Donor-In-Movement Fund.
                          </span>
                        </div>
                      </div>
                      <div className="node nConfirm2">
                        <div className="stepping-icon">
                          <BsCurrencyDollar />
                        </div>
                        <div className="stepping-details">
                          <span className="text-heading done nConfirm0">
                            <h5>Step 3</h5>
                          </span>
                          <div className="main done done nConfirm0"></div>
                          <span className="text nConfirm2">
                            Sign your contract and seed your Fund!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="homepage-card">
                <Card className="login">
                  <CardBody>
                    <div className="text-left">
                      <h5>
                        <span>Step 1</span> Fill in the Donor Information form
                        below
                      </h5>
                    </div>
                    <DmfSubmissionForm />
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="donor-movement">
        <Container>
          <Row>
            <Col md={7}>
              <div className="donor-content">
                <h3>
                  How our <span>Donor-in-Movement</span> Funds are different
                </h3>
                <hr />
                <p>
                  We know there are people who want to use their wealth to
                  advance social justice movements and don't want to participate
                  in an extractive, wealth-centered DAF system. This is why we
                  created Donor-in-Movement Funds.
                </p>
                <p>
                  Our Donor-in-Movement Funds are an alternative to traditional
                  donor-advised funds, and are set up for donors with vision who
                  want to ensure their giving is in alignment with their
                  progressive values. They were created to make actionable and
                  long-lasting change within Oregon’s social justice movements,
                  and built to spend down donations, not hold them in
                  perpetuity. In addition, Donor-in-Movement Fund advisors know
                  that Seeding Justice is uniquely suited to connect donors with
                  the emerging, grassroots movements in Oregon whose work moves
                  the needle toward a more just, democratic, and equitable
                  world.
                </p>
                <ul>
                  <li>
                    Each year, 50% of a DMF is allocated to our grassroots
                    Grantmaking Committee. Their expertise within and knowledge
                    of the grassroots, social justice landscape ensures these
                    DMF funds will reach the groups and projects working to make
                    the biggest impact across the state.
                  </li>
                  <li>
                    Another 40% of the funds in a DMF is distributed to groups
                    of the donor’s choosing that are in alignment with Seeding
                    Justice’s values and are led by the communities most
                    impacted — Black and Indigenous folks and people of color
                    (BIPOC); women and LGBTQ+ folks; people with disabilities;
                    people living on low incomes; youth; and immigrants and
                    refugees.
                  </li>
                  <li>
                    And 10% goes to Seeding Justice to provide operational
                    support and keep us on the forefront of seeding justice in
                    Oregon.
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={5}>
              <div className="donor-movement_image"></div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="newsLetter">
        <Container>
          <Row>
            <Col md={12}>
              <div className="newsLetter_content text-center">
                <h3>
                  Sign up for our <span>Newsletter</span>
                </h3>
                <p>Stay connected with us</p>
                <hr />
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-group">
                    <Input
                      id="enter_your_email_id"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      invalid={errors["email"] ? true : false}
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                    />
                    <FormFeedback className="text-start">
                      {errors["email"]}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Input
                      id="first_name"
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      invalid={errors["first_name"] ? true : false}
                      value={fields["first_name"] ? fields["first_name"] : ""}
                      onChange={(event) => handleChange(event, "first_name")}
                    />
                    <FormFeedback className="text-start">
                      {errors["first_name"]}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Input
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      invalid={errors["last_name"] ? true : false}
                      value={fields["last_name"] ? fields["last_name"] : ""}
                      onChange={(event) => handleChange(event, "last_name")}
                    />
                    <FormFeedback className="text-start">
                      {errors["last_name"]}
                    </FormFeedback>
                  </FormGroup>

                  <Button
                    size="lg"
                    type="submit"
                    disabled={loader}
                    className="submit-button"
                  >
                    {loader ? <Spinner size="sm" className="mx-1" /> : ""}{" "}
                    Subscribe
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DmfForm;
