import React, { Suspense } from "react";
import "./App.css";
import "../src/Frontend/scss/frontendStyle.scss";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminRoutes from "./Routes/AdminRoutes";
import FrontRoutes from "./Routes/FrontRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "./Frontend/Error";
import ScrollToTop from "./Services/scrollTop";
import PrevettedRoutes from "./Routes/PrevettedRoutes";
const AdminLayout = React.lazy(() => import("../src/Layouts/AdminLayout"));
const FrontLayout = React.lazy(() => import("../src/Layouts/FrontLayout"));
const PrevettedLayout = React.lazy(() =>
  import("./Frontend/Role/Layouts/PrevettedLayout")
);

function App() {
  const token = useSelector((state) => state.token);
  const checkAuth = (route) => {
    if (route.authentication) {
      if (token === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  return (
    <BrowserRouter>
      <ScrollToTop>
        <ToastContainer />
        <Suspense
          fallback={
            <div className="vh-100 d-flex align-items-center justify-content-center">
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path="/admin" element={<AdminLayout />}>
              {AdminRoutes.map((route, index) => (
                <Route
                  key={`admin-key-${index}`}
                  path={route.path}
                  element={
                    checkAuth(route) ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
            </Route>
            <Route path="/prevetted" element={<PrevettedLayout />}>
              {PrevettedRoutes.map((route, index) => (
                <Route
                  key={`prevatted-key-${index}`}
                  path={route.path}
                  element={
                    checkAuth(route) ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
            </Route>
            <Route path="/" element={<FrontLayout />}>
              {FrontRoutes.map((route, index) => (
                <Route
                  key={`user-key-${index}`}
                  path={route.path}
                  element={
                    checkAuth(route) ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
