import React, { useState } from "react";
import moment from "moment";

import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Badge,
  Tooltip,
} from "reactstrap";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import common from "../../../Services/common";
import RecentDonorsDetails from "./RecentDonorsDetails";
import { AiFillCheckCircle } from "react-icons/ai";

const RecentDonors = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [donorId, setDonorId] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const user = props.user;

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const toggleDetailsModal = (id) => {
    setOpenDetailsModal(true);
    setDonorId(id);
  };

  return (
    <>
      <tr key={user?.id}>
        <td>
          <span className="recent_donor_font">{common.getFullName(user)}</span>
          <Badge
            pill
            id="badge_font"
            color={common.getSignstatuscolor(user?.dmf_contract_status)}
          >
            {user?.dmf_contract_status.replace("_", " ")}
          </Badge>
          <p className="m-0">{user?.email}</p>
        </td>
        <td className="align-bottom">
          <span className="recent_donor_font">
            {" "}
            {user?.initial_funding_amount
              ? `$${user?.initial_funding_amount}`
              : "N/A"}
          </span>
        </td>
        <td className="align-bottom">
          {user.is_contract_sent === 1 && (
            <>
              <AiFillCheckCircle
                style={{ color: "#00bf87", cursor: "pointer" }}
                size={25}
                id="checkIcon"
                onClick={toggleTooltip}
              />
              <Tooltip
                target="checkIcon"
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
              >
                Contract Sent
              </Tooltip>
            </>
          )}
        </td>

        <td className="align-bottom">
          <span className="recent_dmf_date">
            {moment(user?.created_at).format("D MMM,  YYYY")}
          </span>
        </td>

        <td className="text-center align-bottom">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            color="primary"
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
              <DropdownItem onClick={() => toggleDetailsModal(user.id)}>
                <FaEye
                  className="me-3 details-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />
                Details
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      <RecentDonorsDetails
        openModal={openDetailsModal}
        user={user}
        donorId={donorId}
        handleClose={() => {
          setOpenDetailsModal();
          setDonorId(null);
        }}
      />
    </>
  );
};

export default RecentDonors;
