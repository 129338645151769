import http from "./http";

const adminsetting = {
  adminPassword: (data) =>
    http.post("admin/settings/change-admin-password", data),

  updateEmail: (data) => http.post("/admin/settings/change-admin-email", data),
  addEmail: (data) => http.post("admin/settings/global-config", data),
  getEmail: (param) =>
    http.get("admin/settings/global-config", { params: param }),
};

export default adminsetting;
