import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import common from "../../Services/common";

const Index = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [singleError, setSingleError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
    setSingleError("")
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};

    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if (validateForm()) {
      let params = fields;
      setLoader(true);
      user
        .forgetpassword(params)
        .then((res) => {
          setLoader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setLoader(false);
          } else {
            if (common.isJson(res.data.message)) {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
            } else {
              setSingleError(res.data.message);
            }
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password : seeding-Justice</title>
      </Helmet>
      <div className="login_section homepage_banner">
        <Container>
          <Row>
            <Col className="col-md-6 offset-md-3">
              <Card className="login login_bg">
                <CardBody>
                  <div className="text-center">
                    <h1>Our Community Garden</h1>
                    {/* <p>
                      Welcome to Our Community Garden, the secure portal to manage
                      your Seeding Justice Donor-in-Movement Fund. Please note:
                      password and security questions are case sensitive.
                    </p> */}
                    <p>
                      If you have not set up a Community Garden account yet, please
                      contact us at  <a href="mailto:XYZ@seedingjustice.com" className="orange-text">XYZ@seedingjustice.com. </a>If you are interested in
                      opening a Donor-in-Movement Fund, please fill out this <Link to="/dmf-form" className="orange-text"> form </Link>
                      with the new donor advised fund account.
                    </p>
                    <h4  className="orange-text">Forgot Password</h4>
                  </div>
                  <Form onSubmit={handleSubmit} className="login-form mt-2">
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            type="email"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            invalid={errors["email"] ? true : false}
                          />
                          <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      {singleError !== "" ? (
                        <p className="text-danger mb-1">{singleError}</p>
                      ) : (
                        ""
                      )}
                      <Col md={12}>
                        <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                          className="submit-button"
                        >
                          {loader ? <Spinner size="sm" className="mx-1" /> : ""}
                          Submit
                        </Button>
                      </Col>
                      <Col md={12} className="mt-2 text-center text-dark">
                        Register an account? <Link to="/signup" className="orange-text">Sign up</Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
