import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import user from "../../Services/user";
import LoadingOverlay from "react-loading-overlay";
import common from "../../Services/common";

const EditUser = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  LoadingOverlay.propTypes = undefined;

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
  };
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const getUser = (id) => {
    let params = id;
    setLoader(true);
    user
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9\b]+$/.test(keyValue);
    if (!isValidInput) {
      event.preventDefault();
    }
  };
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid first name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid last name";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.phone) {
      errors["phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (fields.secondary_email_1 && !common.isValidEmail(fields["secondary_email_1"])) {
      errors["secondary_email_1"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (fields.secondary_email_2 && !common.isValidEmail(fields["secondary_email_2"])) {
      errors["secondary_email_2"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (fields.phone && !common.isValidPhone(fields["phone"])) {
      errors["phone"] = "Enter valid mobile number!";
      formIsValid = false;
    }
    if (fields.dob && !common.isValidAge(fields["dob"])) {
      errors["dob"] = "Age must be 18 or above";
      formIsValid = false;
    }
    if (!fields.social_security_number) {
      errors["social_security_number"] =
        "Social Security number can not be empty";
      formIsValid = false;
    } else if (fields.social_security_number.length < 9) {
      errors["social_security_number"] = "Social Security must be 9 digits";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
    let params = fields;
    setIsloader(true);
    user
      .update(params)
      .then((res) => {
        setIsloader(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFields({});
          setIsloader(true);
          props.handleClose();
          props.getUsers();
        } else {
          let errors = {};
          for (let key in res.data.message) {
            errors[key] = res.data.message[key];
          }
          setErrors(errors);
          setIsloader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
    }
  };
  useEffect(() => {
    if (props.userId) {
      getUser(props.userId);
    }
  }, []);
  return (
    <>
      <Modal isOpen={props.open} className='admin recent-donor-modal' size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            {props.userId ? "Update " : "Add "}User
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={<Spinner style={{ color: "#00344B" }} />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="first_name">First Name</Label>
                    <Input
                      id="first_name"
                      name="first_name"
                      placeholder=""
                      type="first_name"
                      value={fields["first_name"] ? fields["first_name"] : ""}
                      onChange={(event) => handleChange(event, "first_name")}
                      invalid={errors["first_name"] ? true : false}
                    />
                    <FormFeedback>{errors["first_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="last_name">Last Name</Label>
                    <Input
                      id="last_name"
                      name="last_name"
                      placeholder=""
                      type="last_name"
                      value={fields["last_name"] ? fields["last_name"] : ""}
                      onChange={(event) => handleChange(event, "last_name")}
                      invalid={errors["last_name"] ? true : false}
                    />
                    <FormFeedback>{errors["last_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder=""
                      type="text"
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                      invalid={errors["email"] ? true : false}
                    />
                    <FormFeedback>{errors["email"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="secondary_email_1">Secondary Email 1</Label>
                    <Input
                      id="secondary_email_1"
                      name="secondary_email_1"
                      placeholder=""
                      type="text"
                      value={
                        fields["secondary_email_1"]
                          ? fields["secondary_email_1"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "secondary_email_1")
                      }
                      invalid={errors["secondary_email_1"] ? true : false}

                    />
                    <FormFeedback>{errors["secondary_email_1"]}</FormFeedback>

                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="secondary_email_2">Secondary Email 2</Label>
                    <Input
                      id="secondary_email_2"
                      name="secondary_email_2"
                      placeholder=""
                      type="text"
                      value={
                        fields["secondary_email_2"]
                          ? fields["secondary_email_2"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "secondary_email_2")
                      }
                      invalid={errors["secondary_email_2"] ? true : false}

                      />
                      <FormFeedback>{errors["secondary_email_2"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      value={fields["phone"] ? fields["phone"] : "+1"}
                      onChange={(phone) => handlePhone(phone, "phone")}
                      country="us"
                      inputStyle={{ width: '100%' }}
                    />
                    {errors["phone"] && (
                      <small className="fa-1x text-danger">
                        {errors["phone"]}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="dob">DOB</Label>
                    <Input
                      id="dob"
                      name="dob"
                      placeholder=""
                      type="date"
                      value={fields["dob"] ? fields["dob"] : ""}
                      onChange={(event) => handleChange(event, "dob")}
                      invalid={errors["dob"] ? true : false}
                    />
                    <FormFeedback>{errors["dob"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="social_security_number">
                      Social Security Number
                    </Label>
                    <Input
                      id="social_security_number"
                      name="social_security_number"
                      placeholder=""
                      type="text"
                      maxLength={9}
                      onKeyPress={(event) =>
                        handleKeyPress(event, "social_security_number")
                      }
                      value={
                        fields["social_security_number"]
                          ? fields["social_security_number"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "social_security_number")
                      }
                      invalid={errors["social_security_number"] ? true : false}
                    />
                    <FormFeedback>
                      {errors["social_security_number"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button size="md"   className={props.userId ? "submit-Id-button":"submit-button"} type="submit" disabled={isloader}>
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              {props.userId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default EditUser;
