import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import nominee from "../../../Services/nominee";
import { useSelector } from "react-redux";

const PrevettedDetailsModal = ({ sjGrantee, open, close, prevettedId }) => {
  const {
    city,
    logo,
    ein_number,
    mission,
    org_name,
    org_contact_name,
    org_contact_email,
    geography,
    priority_population,
    street,
    org_website,
    state,
    zip,
  } = sjGrantee;

  const [nominationDropdowns, setNominationDropdowns] = useState([]);
  const [loader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const first_name = useSelector((state) => state.first_name);
  const last_name = useSelector((state) => state.last_name);
  const email = useSelector((state) => state.email);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleClose = () => {
    close(false);
  };

  const getDropdowns = () => {
    nominee
      .nomination_dropdowns()
      .then((res) => {
        if (res.data.status) {
          setNominationDropdowns(res.data.data.recognition);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields?.recognition_id) {
      errors["recognition_id"] = "The recognition id field is required";
      formIsValid = false;
    }
    if (!fields?.grant_amount) {
      errors["grant_amount"] = "The grant amount field is required";
      formIsValid = false;
    }
    if (fields.grant_amount) {
      let amount = fields.grant_amount.replace("$", "");
      amount = amount.replace(",", "");
      if (parseInt(amount) < 1000) {
        errors["grant_amount"] = "The grant amount must be atleast $1000";
        formIsValid = false;
      }
    }
    if (fields.additional_queries) {
      if (fields.additional_queries.length > 255) {
        errors["additional_queries"] =
          "The additional queries must not be greater than 255 characters";
        formIsValid = true;
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoader(true);
      let params = { ...fields };
      params["prevetted_id"] = prevettedId;
      params["grant_amount"] = fields.grant_amount
        .replace("$", "")
        .replace(/,/g, "");

      nominee
        .add(params)
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setErrors({});
            setIsLoader(false);
            handleClose();
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsLoader(false);
        });
    }
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <Modal isOpen={open} size="lg" className="user_modal">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleClose}>Grantee's Details</ModalHeader>
        {loader ? (
          <Spinner />
        ) : (
          <ModalBody className="pl-4 pr-4">
            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">
                Organization's and Donor's Details
              </div>
              <div className="pt-2">
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Donor's Name :</strong>{" "}
                        {`${first_name} ${last_name}`}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong> Donor's Email :</strong> {email}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Organization Name :</strong> {org_name}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>EIN :</strong> {ein_number}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col col={12}>
                      <div className="nomination-box">
                        <strong>Website : </strong>
                        <a
                          href={org_website}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          {org_website}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={12}>
                      <div className="nomination-box">
                        <strong>Mission : </strong> {mission && mission}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={12}>
                      <div className="nomination-box">
                        <strong>Address :</strong> {street ? street : "N/A"},{" "}
                        {city}, {state?.name}, {zip}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col col={12}>
                      <div className="nomination-box">
                        <strong>Priority Population :</strong>{" "}
                        {priority_population}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <Col md={12}>
                        <div className="nomination-box">
                          <strong>Contact Name :</strong>{" "}
                          {org_contact_name ? org_contact_name : "N/A"}
                        </div>
                      </Col>
                      <Col md={12} className="my-3">
                        <div className="nomination-box">
                          <strong>Contact Email :</strong>{" "}
                          {org_contact_email ? org_contact_email : "N/A"}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="nomination-box">
                          <strong>Geography : </strong> {geography}
                        </div>
                      </Col>
                    </Col>
                    <Col col={6} className="text-end pe-5">
                      {logo ? (
                        <div className="p-2">
                          <img
                            alt="Card"
                            src={logo}
                            style={{ maxWidth: "70%", borderRadius: "2px" }}
                          />
                        </div>
                      ) : (
                        <div className="p-2">
                          <img
                            alt="Card"
                            src="/assets/no-logo.png"
                            className="img-thumbnail"
                            style={{ width: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row></Row>
                </FormGroup>
              </div>
            </div>
            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">Submit Grant</div>
              <div className="pt-4">
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={12}>
                      <Label for="recognition_id">
                        Would you like Donor Recognition or Anonymity?{" "}
                        <span className="text-danger fw-bolder">*</span>
                      </Label>
                      <Input
                        type="select"
                        style={{ cursor: "pointer" }}
                        name="recognition_id"
                        value={
                          fields["recognition_id"]
                            ? fields["recognition_id"]
                            : ""
                        }
                        onChange={(event) =>
                          handleChange(event, "recognition_id")
                        }
                        invalid={errors["recognition_id"] ? true : false}
                      >
                        <option
                          value=""
                          className="recognition_dropdown"
                          disabled
                        >
                          {nominationDropdowns.length === 0
                            ? "Loading..."
                            : "Would you like Donor Recognition or Anonymity?"}
                        </option>
                        {nominationDropdowns.length > 0 &&
                          nominationDropdowns.map((dropdowns, i) => (
                            <option
                              value={dropdowns.id}
                              key={`key-dropdowns-${i}`}
                            >
                              {dropdowns.category_name}
                            </option>
                          ))}
                      </Input>
                      <FormFeedback>{errors["recognition_id"]}</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={12}>
                      <Label for="grant_amount">
                        Grant Amount{" "}
                        <span className="text-danger fw-bolder">*</span>
                      </Label>
                      <CurrencyFormat
                        name="grant_amount"
                        placeholder="Grant Amount $0.00"
                        className="form-control"
                        inputmode="numeric"
                        value={
                          fields["grant_amount"] ? fields["grant_amount"] : ""
                        }
                        thousandSeparator={true}
                        invalid={errors["grant_amount"] ? true : false}
                        prefix={"$"}
                        onChange={(event) =>
                          handleChange(event, "grant_amount")
                        }
                      />
                      {errors["grant_amount"] && (
                        <small className="fa-1x text-danger">
                          {errors["grant_amount"]}
                        </small>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={12}>
                      <Input
                        placeholder="Anything else you would like us to know?"
                        name="additional_queries"
                        type="textarea"
                        value={
                          fields["additional_queries"]
                            ? fields["additional_queries"]
                            : ""
                        }
                        invalid={errors["additional_queries"] ? true : false}
                        onChange={(event) =>
                          handleChange(event, "additional_queries")
                        }
                      />
                      <p
                        className={`${
                          fields?.additional_queries &&
                          fields?.additional_queries.length <= 255
                            ? "text-success"
                            : "text-danger"
                        } text-end me-3 mt-2`}
                      >
                        {" "}
                        {fields?.additional_queries ? (
                          fields?.additional_queries.length > 255 ? (
                            <span>Extra Characters: </span>
                          ) : (
                            <span>Characters Remaining: </span>
                          )
                        ) : (
                          ""
                        )}{" "}
                        {fields?.additional_queries
                          ? `${255 - fields?.additional_queries.length}`
                          : ""}
                      </p>
                      <FormFeedback style={{ marginTop: "-38px" }}>
                        {errors["additional_queries"]}
                      </FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            color="secondary"
            className="cancel_modal"
            onClick={handleClose}
            size="lg"
            disabled={isLoader}
          >
            Cancel
          </Button>
          <Button
            className="orange-button"
            type="submit"
            size="lg"
            disabled={isLoader}
          >
            {isLoader ? <Spinner size="sm" className="mx-1" /> : ""}
            Submit Nomination
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PrevettedDetailsModal;
