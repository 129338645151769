import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Button,
  FormText,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoadingOverlay from "react-loading-overlay";
import emailtemplate from "../../Services/emailtemplate";
import { useParams, useNavigate, Link } from "react-router-dom";
import common from "../../Services/common";

const AddEditTemplate = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [content, setContent] = useState("");
  const [loader, setLoader] = useState(false);
  const [placeHolders, setPlaceholders] = useState({});
  const [isloader, setIsloader] = useState(false);
  let { id } = useParams();
  const navigate = useNavigate();
  LoadingOverlay.propTypes = undefined;

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.template_name) {
      errors["template_name"] = "Template name can not be empty";
      formIsValid = false;
    }
    if (fields.template_name && !common.isValidName(fields["template_name"])) {
      errors["template_name"] = "Enter valid Template name";
      formIsValid = false;
    }
    if (!fields.subject) {
      errors["subject"] = "Subject can not be empty";
      formIsValid = false;
    }
    if (!fields.from_email) {
      errors["from_email"] = "From Email can not be empty";
      formIsValid = false;
    }
    if (fields.from_email && !common.isValidEmail(fields["from_email"])) {
      errors["from_email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.reply_to_email) {
      errors["reply_to_email"] = "Reply to Email can not be empty";
      formIsValid = false;
    }
    if (
      fields.reply_to_email &&
      !common.isValidEmail(fields["reply_to_email"])
    ) {
      errors["reply_to_email"] = "Enter valid Email Address!";
      formIsValid = false;
    }

    if (!fields.from_label) {
      errors["from_label"] = "From label can not be empty";
      formIsValid = false;
    }
    // Remove HTML tags
    let contentText = content.replace(/<[^>]*>/g, "");
    contentText = contentText.replace(/\s+/g, "");

    if (!content || contentText.length <= 0) {
      errors["content"] = "Content can not be empty";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const getEmailTemplate = (id) => {
    let params = id;
    setLoader(true);
    emailtemplate
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setContent(res.data.data.content);
          setLoader(false);
          if (res.data?.data?.placeholders) {
            let placeholders = JSON.parse(res.data?.data?.placeholders);
            setPlaceholders(placeholders);
          }
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };
  const handleContentChange = (content) => {
    setContent(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsloader(true);
      let params = { ...fields, content };
      if (id) {
        let params = {
          ...fields,
          content,
        };
        emailtemplate
          .update(params)
          .then((res) => {
            setIsloader(false);
            if (res.data.status) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/admin/email-templates");
            } else {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
              setIsloader(false);
            }
          })
          .catch(function (error) {
            setIsloader(false);
            console.log("error", error);
            setIsloader(false);
          });
      } else {
        emailtemplate
          .add(params)
          .then((res) => {
            setIsloader(true);
            if (res.data.status) {
              setIsloader(false);
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/admin/email-templates");
            } else {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
            }
          })
          .catch(function (error) {
            setIsloader(false);
            console.log("error", error);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      getEmailTemplate(id);
    }
  }, []);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];
  return (
    <>
      <Row>
        <Col xl={12}>
          <Row>
            <Col>
              <h3 className="title-font">
                {id ? "Update " : "Add "}
                Email Template
              </h3>
            </Col>
            <hr />
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <LoadingOverlay
                  active={loader}
                  spinner={
                    <Spinner
                      style={{ height: 60, width: 60, color: "#00344B" }}
                    />
                  }
                  fadeSpeed={100}
                  classNamePrefix="mitiz"
                >
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="template_name">Template Name</Label>
                        <Input
                          id="template_name"
                          name="template_name"
                          placeholder=""
                          type="text"
                          value={
                            fields["template_name"]
                              ? fields["template_name"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "template_name")
                          }
                          invalid={errors["template_name"] ? true : false}
                        />
                        <FormFeedback>{errors["template_name"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Input
                          id="subject"
                          name="subject"
                          placeholder=""
                          type="text"
                          value={fields["subject"] ? fields["subject"] : ""}
                          onChange={(event) => handleChange(event, "subject")}
                          invalid={errors["subject"] ? true : false}
                        />
                        <FormFeedback>{errors["subject"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="from_email">From Email</Label>
                        <Input
                          id="from_email"
                          name="from_email"
                          placeholder=""
                          type="text"
                          value={
                            fields["from_email"] ? fields["from_email"] : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "from_email")
                          }
                          invalid={errors["from_email"] ? true : false}
                        />
                        <FormFeedback>{errors["from_email"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="from_label">From Label</Label>
                        <Input
                          id="from_label"
                          name="from_label"
                          placeholder=""
                          type="text"
                          value={
                            fields["from_label"] ? fields["from_label"] : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "from_label")
                          }
                          invalid={errors["from_label"] ? true : false}
                        />
                        <FormFeedback>{errors["from_label"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="reply_to_email">Reply To Email</Label>
                        <Input
                          id="reply_to_email"
                          name="reply_to_email"
                          placeholder=""
                          type="text"
                          value={
                            fields["reply_to_email"]
                              ? fields["reply_to_email"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "reply_to_email")
                          }
                          invalid={errors["reply_to_email"] ? true : false}
                        />
                        <FormFeedback>{errors["reply_to_email"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="content">Content</Label>

                        <ReactQuill
                          theme="snow"
                          name="content"
                          id="content"
                          value={content}
                          modules={modules}
                          formats={formats}
                          onChange={handleContentChange}
                          invalid={errors["content"] ? true : false}
                          style={{ minHeight: "250px" }}
                        />
                        {errors["content"] && (
                          <FormText color="danger" size="md">
                            {errors["content"]}
                          </FormText>
                        )}
                      </FormGroup>
                      <h5>
                        <strong>
                          {Object.keys(placeHolders).length > 0 &&
                            "Placeholders"}
                        </strong>
                      </h5>
                      {Object.keys(placeHolders).map((key) => {
                        return (
                          <p>
                            <strong>[{key}]</strong> = {placeHolders[key]}
                          </p>
                        );
                      })}
                    </Col>
                    <Col></Col>

                    <Col md={12} className="d-flex justify-content-end">
                      <Link
                        type="button"
                        size="md"
                        className="btn btn-outline-secondary me-2"
                        to={"/admin/email-templates"}
                        disabled={isloader}
                      >
                        Cancel
                      </Link>
                      <Button
                        color="success"
                        className="submit-Id-button"
                        size="md"
                        type="submit"
                        disabled={isloader}
                      >
                        {isloader ? <Spinner size="sm" className="mx-1" /> : ""}

                        {id ? "Update" : "Add"}
                      </Button>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddEditTemplate;
