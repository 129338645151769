import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Sidebar from "../Sidebar";
import faq from "../../../Services/faq";
import { AiOutlineDown } from "react-icons/ai";
import LoadingOverlay from "react-loading-overlay";
import "react-quill/dist/quill.snow.css";

const Index = () => {
  const [faqs, setFaqs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [collapse, setCollapse] = useState(0);

  const toggle = (e) => {
    let event = e.target.dataset.event;
    setCollapse((prevCollapse) =>
      prevCollapse === Number(event) ? 0 : Number(event)
    );
  };

  const getFaqs = () => {
    setLoader(true);
    faq
      .faq_list()
      .then((res) => {
        if (res?.data?.status) {
          setFaqs(
            res?.data?.data?.data.filter((f) => parseInt(f.grantees) === 1)
          );

          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <div className="userAdminPanel">
      <Row className="customised-row">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <div className="mt-4 mb-5 userAdminArticle">
            <div className="py-3 userAdminArticleHeading">
              <h4 className="mb-0">Frequently Asked Questions</h4>
            </div>
            <LoadingOverlay
              active={loader}
              spinner={
                <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
              }
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <CardBody className="recommend-grants-form px-2">
                <div style={{ margin: "3rem 0" }}>
                  <div className="custom_accordian">
                    {faqs.map((faq, index) => (
                      <Container key={index}>
                        <Card
                          style={{ marginBottom: "5px" }}
                          className="overflow-hidden"
                        >
                          <CardHeader
                            onClick={toggle}
                            data-event={index}
                            style={{ backgroundColor: "white" }}
                          >
                            <span>{index + 1}.</span> {faq.question}{" "}
                            <AiOutlineDown style={{ float: "right" }} />
                          </CardHeader>
                          <Collapse
                            isOpen={collapse === index}
                            style={{ backgroundColor: "#f6f7f9" }}
                          >
                            <CardBody
                              style={{ backgroundColor: "rgb(255 250 255)" }}
                            >
                              <div
                              className="ql-editor"
                                dangerouslySetInnerHTML={{
                                  __html: faq.content,
                                }}
                              />
                            </CardBody>
                          </Collapse>
                        </Card>
                      </Container>
                    ))}
                  </div>
                </div>
                <div className="grantee_faqs">
                  <h3 style={{ color: "#305266" }}>
                    Still have a question that needs an answer?
                  </h3>
                  <p>
                    If you have a question that wasn't answered in our FAQs,
                    email us at{" "}
                    <a
                      href="xyz@seedingjustice.com"
                      className="text-decoration-none"
                    >
                      xyz@seedingjustice.com
                    </a>{" "}
                  </p>
                </div>
              </CardBody>
            </LoadingOverlay>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
