import { createStore } from 'redux'

let apiUrl, baseUrl;
if(document.URL.includes("localhost")){
  apiUrl = "https://dmf.seedingjustice.org/sj/public/api";
  baseUrl = "https://dmf.seedingjustice.org/sj/public/api";
}
else{
  apiUrl = "https://dmf.seedingjustice.org/sj/public/api";
  baseUrl ="https://dmf.seedingjustice.org/sj/public/api";
}
let initialState = {
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  
};
if (localStorage.getItem("token")) {
  initialState = { ...initialState, token: localStorage.getItem("token") };
}
if (localStorage.getItem("first_name")) {
  initialState = {
    ...initialState,
    first_name: localStorage.getItem("first_name")
  };
}

if (localStorage.getItem("last_name")) {
    initialState = {
      ...initialState,
      last_name: localStorage.getItem("last_name")
    };
  }
if (localStorage.getItem("isAdmin")) {
  initialState = {
    ...initialState,
    isAdmin: localStorage.getItem("isAdmin")
};
}
if (localStorage.getItem("email")) {
  initialState = {
    ...initialState,
    email: localStorage.getItem("email")
  };
}
if (localStorage.getItem("user_id")) {
  initialState = {
    ...initialState,
    user_id: localStorage.getItem("user_id")
  };
}
if (localStorage.getItem("email_verified")) {
  initialState = {
    ...initialState,
    email_verified: localStorage.getItem("email_verified")
  };
}
if (localStorage.getItem("prevetted_grantee_id")) {
  initialState = {
    ...initialState,
    prevetted_grantee_id: localStorage.getItem("prevetted_grantee_id")
  };
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'UPDATE-EMAIL':
      return {...state, ...rest }
      case 'UPDATE-FIRSTNAME':
      return {...state, ...rest }
      case 'UPDATE-LASTNAME':
        return {...state, ...rest }
    case 'login':
      return {...state, ...rest }
    case 'logout':
    localStorage.clear();
      const initialState = {
        apiUrl: apiUrl,
        baseUrl: baseUrl
      };
      return (state = initialState);
    default:
      return state
  }
}

const store = createStore(changeState, window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_())
store.subscribe(() => {
  console.log(store);
});
export default store;