import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button, Card, CardBody } from "reactstrap";
import Search from "../Search";
import User from "./User";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import user from "../../Services/user";
import {
  BsSearch,
} from "react-icons/bs";
import EditUser from "./EditUser";
import Export from "../Export";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Index = (props) => {
  const [users, setUsers] = useState({});
  const [userId, setUserId] = useState("");
  const [searchValue, setSearchValue] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [sort, setSort] = useState({});
  const [searchOpen, setsearchOpen] = useState(false);
  const [exportFields, setExportFields] = useState({});
  const [searchFields, setSearchFields] = useState([
    { label: "First Name", name: "first_name", type: "text", col: 4 },
    { label: "Last Name", name: "last_name", type: "text", col: 4 },
    { label: "Email", name: "email", type: "text", col: 4 },
    { label: "Mobile", name: "phone", type: "number", col: 4 },
  ]);
  
  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const openEditModal = (user_id) => {
    setOpen(true);
    setUserId(user_id);
  };

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    console.log(page);
    setLoader(true);
    setPage(page + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.selected+1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`
    });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getUsers = (fields = {}) => {
    setLoader(true);
    let params;
    if(Object.keys(searchValue).length > 0){
      params = {
        ...fields, ...searchValue, ...sort, page
      } 
    }else{
      params = { ...fields, ...sort, page };
    }
    user
      .list(params)
      .then((res) => {
        if (res.data.status) {
          setUsers(res.data.data.data);
          if (!searchValue) {
            setPage(res.data.data.current_page);
          }
          setTotalPages(res.data.data.total / res.data.data.per_page);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const deleteUser = (id) => {
    if (window.confirm("Are you sure to delete this donor?")) {
      user.userDelete(id).then((res) => {
        if (res.data.status) {
          const newUser = users.filter((item) => item.id !== id);
          setUsers(newUser);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const getSearchFields = (fields = {}) => {
    setExportFields(fields);
    if (Object.keys(searchValue).length === 0 && Object.keys(fields).length === 0 || page ==1) {
      getUsers(fields)
    }else {
      setPage(1)
    }
    setTotalPages(totalPages / 10);
  };

  useEffect(() => {
    getUsers(page, sort);
  }, [page, sort]);

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Donors : seeding justice</title>
      </Helmet>
      <Row>
        <Col md={8} sm={12}>
          <h4 className="mb-0 title-font">Donors</h4>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "#e26a38" }}>
                  <p className="mb-0">Dashboard</p>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <p className="mb-0">Donors</p>
              </li>
            </ol>
          </nav>
        </Col>
        <Col md={4} sm={12} className="text-end">
          <Row>
            <Col sm={12} md={6}>
              <Export
                service={user}
                fileName={"Donor"}
                exportExcel={true}
                fields={exportFields}
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                color="light"
                type="button"
                onClick={toggleSearch}
                className="mt-2 mt-md-0 search_button"
                size="md"
                block
                style={{ borderRadius: "30px",backgroundColor:"#f6f1eb" }}
              >
                <BsSearch className="me-1" />
                Search
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Search
        isOpen={searchOpen}
        searchFields={searchFields}
        service={user}
        fileName={"Donor"}
        exportExcel={true}
        heading="Search Donor"
        onSubmit={getSearchFields}
        setFields={setSearchValue}
        fields={searchValue}
      />
      <Card className="mt-4">
        <CardBody>
          <LoadingOverlay
            active={loader}
            spinner={
              <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
            }
            fadeSpeed={100}
            classNamePrefix="mitiz"
          >
            <Table responsive className="newThemeTable">
              <thead>
                <tr>
                  <th scope="col" className="border-top-0" width={7 + "%"}>
                    S.No.
                  </th>
                  <th scope="col">
                    <span
                      className="border-top-0 sortable sort"
                      onClick={(event) => sortRecord(event, "first_name")}
                    >
                      Name
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      className="border-top-0 sortable sort"
                      onClick={(event) => sortRecord(event, "email")}
                    >
                      Email
                    </span>
                  </th>
                  <th scope="col" className="border-top-0">
                    Phone
                  </th>
                  <th scope="col">
                    <span
                      className="border-top-0 sortable sort"
                      onClick={(event) => sortRecord(event, "created_at")}
                    >
                      Added On
                    </span>
                  </th>
                  <th scope="col" className="border-top-0 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0
                  ? users.map((user, index) => (
                      <User
                        user={user}
                        key={`key-user-${index}`}
                        openEditModal={openEditModal}
                        deleteUser={deleteUser}
                      />
                    ))
                  : !loader && (
                      <tr>
                        <td key={0} colSpan="6">
                          <p className="text-center">User not found.</p>
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </LoadingOverlay>
        </CardBody>
      </Card>
      <tr style={{ display: "flex" }}>
        <td colSpan="6" style={{ margin: "auto" }}>
          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </td>
      </tr>
      {open && (
        <EditUser
          open={open}
          handleClose={() => {
            setOpen();
            setUserId(null);
          }}
          userId={userId}
          getUsers={getUsers}
        />
      )}
    </div>
  );
};

export default Index;
