import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import user from "../../Services/user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
import common from "../../Services/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function Signup(props) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const [inputType, setInputType] = useState('text');
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const maxDate = today.toISOString().split("T")[0];
  const [loader] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleDate = (date) => {
    setFields({ ...fields, dob: date });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;

      setIsloader(true);
      user
        .signup(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/login");
            setFields({});
            setIsloader(true);
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };

  const handleFocus = ()=>{
    setInputType('date')
  }

  const handleBlur = () => {
    setInputType('text');
  };

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
    setErrors({ ...errors, [field_name]: (phone = "") });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid first name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid last name";
      formIsValid = false;
    }
    if (!fields.phone) {
      errors["phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (fields.phone && !common.isValidPhone(fields["phone"])) {
      errors["phone"] = "Enter valid mobile number!";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.dob) {
      errors["dob"] = "DOB can not be empty";
      formIsValid = false;
    }
    if (fields.dob && !common.isValidAge(fields["dob"])) {
      errors["dob"] = "Age must be 18 or above";
      formIsValid = false;
    }
    if (!fields.password) {
      errors["password"] = "Password can not be empty";
      formIsValid = false;
    } else if (fields.password.length < 8) {
      errors["password"] = "Password must be 8 character";
      formIsValid = false;
    } else if (fields.password_confirmation !== fields.password) {
      errors["password_confirmation"] = "Confirm password did not match";
      formIsValid = false;
    }
    if (!fields.social_security_number) {
      errors["social_security_number"] =
        "Social Security number can not be empty";
      formIsValid = false;
    } else if (fields.social_security_number.length!== 9) {
      errors["social_security_number"] = "Social Security must be 9 digits";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9\b]+$/.test(keyValue);
    if (!isValidInput) {
      event.preventDefault();
    }
  };

  if (localStorage.getItem("token")) {
    return <Navigate to="/make-contributions" />;
  }
  return (
    <>
      <Helmet>
        <title>Signup : seeding-Justice</title>
      </Helmet>
      <div className="homepage_banner">
        <Container>
          <Row>
            <Col md={6} className="d-flex">
              <div className="homepage-content">
                <h2>Our Community Garden</h2>
                <p style={{ textAlign: "justify" }}>
                  Welcome to Our Community Garden, the secure portal to manage
                  your Seeding Justice Donor-in-Movement Fund.
                </p>
                <p>
                  If you have not set up a Community Garden account yet, please
                  contact us at{" "}
                  <a
                    href="mailto:XYZ@seedingjustice.com"
                    className="orange-text"
                  >
                    XYZ@seedingjustice.com{" "}
                  </a>
                  . If you are interested in opening a Donor-in-Movement Fund,
                  please fill out this{" "}
                  <Link to="/dmf-form" className="orange-text">
                    form{" "}
                  </Link>
                  with the new donor advised fund account.
                </p>
              </div>
            </Col>

            <Col md={6}>
              <Card className="login">
                <CardBody>
                  <div className="text-center">
                    <h2>Sign Up</h2>
                  </div>
                  <Form onSubmit={handleSubmit} className="signup-form mt-5">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            type="first_name"
                            value={
                              fields["first_name"] ? fields["first_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "first_name")
                            }
                            invalid={errors["first_name"] ? true : false}
                          />
                          <FormFeedback>{errors["first_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            type="last_name"
                            value={
                              fields["last_name"] ? fields["last_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "last_name")
                            }
                            invalid={errors["last_name"] ? true : false}
                          />
                          <FormFeedback>{errors["last_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="text"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            invalid={errors["email"] ? true : false}
                          />
                          <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <PhoneInput
                            countryCodeEditable={false}
                            onlyCountries={["us"]}
                            value={fields["phone"] ? fields["phone"] : ""}
                            onChange={(phone) => handlePhone(phone, "phone")}
                            country="us"
                          />
                          {errors["phone"] && (
                            <small className="fa-1x text-danger">
                              {errors["phone"]}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="password"
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={fields["password"] ? fields["password"] : ""}
                            onChange={(event) =>
                              handleChange(event, "password")
                            }
                            invalid={errors["password"] ? true : false}
                          />
                          <FormFeedback>{errors["password"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder="Confirm Password"
                            type="password"
                            value={
                              fields["password_confirmation"]
                                ? fields["password_confirmation"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "password_confirmation")
                            }
                            invalid={
                              errors["password_confirmation"] ? true : false
                            }
                          />
                          <FormFeedback>
                            {errors["password_confirmation"]}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                        <DatePicker 
                          selected={fields["dob"]
                                ? fields["dob"]
                                : ""} 
                              onChange={(event) =>
                                handleDate(event, "dob")
                              }
                              dateFormat="MM-dd-yyyy"
                              placeholderText="MM-DD-YYYY"
                              className="form-control"
                              // invalid={errors["dob"] ? true : false}
                              />
                        </FormGroup>
                          <span className="text-danger">{errors["dob"]}</span>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <Input
                            id="social_security_number"
                            name="social_security_number"
                            placeholder="Social security number"
                            type="number"
                            maxLength={9}
                            onKeyPress={(event) =>
                              handleKeyPress(event, "social_security_number")
                            }
                            value={
                              fields["social_security_number"]
                                ? fields["social_security_number"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "social_security_number")
                            }
                            invalid={
                              errors["social_security_number"] ? true : false
                            }
                          />
                          <FormFeedback>
                            {errors["social_security_number"]}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-end">
                        <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                          className="submit-button"
                        >
                          {isloader ? (
                            <Spinner size="sm" className="mx-1" />
                          ) : (
                            ""
                          )}
                          Sign Up
                        </Button>
                      </Col>
                      <Col md={12} className="text-dark text-center mt-4">
                        Already have an account?{" "}
                        <Link to="/login" className="orange-text">
                          Sign In
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Signup;
