import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import userdashboard from "../../../Services/userdashboard"
import Sidebar from "../Sidebar";
import { AiFillGift, AiOutlineGlobal, AiFillCreditCard } from "react-icons/ai";

const Index = () => {
  const [modelText, setModalText] = useState("");
  const [fields, setFields] = useState({});
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [contributeId, setContributeId] = useState("");

  const getMakeContributions = () => {
    setLoader(true);
    userdashboard
      .getContribution()
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data.ach_details);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const EventModal = (contributeId) => {
    if (contributeId === 1) {
      setModalText("ACH /Wire Transfer");
    } else if (contributeId === 2) {
      setModalText("Donate by Check");
    } else {
      setModalText("Gift of Stock");
    }
    setOpen(true);
    setContributeId(contributeId);
  };

  useEffect(() => {
    getMakeContributions();
  }, []);
  return (
    <>
      <div className="userAdminPanel">
        <Row className="customised-row">
          <Col md={3}>
            <Sidebar />
          </Col>
          <Col md={9}>
            <div className="mt-4 mb-5 userAdminArticle">
              <div className="py-3 userAdminArticleHeading">
                <h4 className="mb-0">Contribute to your DMF</h4>
                <p className="mb-0">
                  Seeding Justice accepts donations through the options below.
                  Please contact us info@seedingjustice.org if you have any
                  questions.
                </p>
              </div>
              <CardBody className="contribute-dmf-bottom">
                <Row className="mt-4 mb-5">
                  <Col lg={4}>
                    <div className="donation_type">
                      <Button
                        color="info"
                        size="md"
                        className="eventModalButton wire text-white"
                        block
                        onClick={() => EventModal(1)}
                      >
                        <span><AiOutlineGlobal /></span>ACH /Wire Transfer
                      </Button>
                      <Button
                        // color="warning"
                        size="md"
                        className="eventModalButton donate mt-4 text-white"
                        block
                        onClick={() => EventModal(2)}
                      >
                        <span><AiFillCreditCard /></span> Donate by Check
                      </Button>
                      <Button
                        // color="primary"
                        size="md"
                        className="eventModalButton gift mt-4 mb-3 text-white"
                        block
                        onClick={() => EventModal(3)}
                      >
                        <span><AiFillGift /></span> Gift of Stock
                      </Button>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="collect_donation">
                      <div givebutter-element-id="p710aL"></div>
                      <iframe
                        src="https://givebutter.com/elements/forms/YApxIBkQViZg7cmO/donate/REOVQB?GBA_GB.element.id=2496&amp;GBA_GB.element.type=giving-form&amp;GBA_GB.element.url=http%3A%2F%2Flocalhost%3A3000%2Fmake-contributions"
                        scrolling="yes"
                        seamless=""
                        allowpaymentrequest=""
                        title="mitiz"
                        name="givebutter"
                        id="iFrameResizer0"
                        // title="mitiztechnologies"
                        style={{
                          border: "0",
                          width: "100%",
                          // Height: "600px",
                          // maxWidth: "800px",
                          scrollMarginTop: "20px",
                          overflow: "scroll",
                        }}
                      ></iframe>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Col>
        </Row>
      </div>
      <Modal isOpen={open} size="md" className="user_modal">
        <ModalHeader toggle={() => setOpen(false)}>{modelText}</ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <Row>
            <Col md={12}>
              {contributeId === 1 && (
                <div
                className="ach_container"
                  dangerouslySetInnerHTML={{
                    __html: fields,
                  }}
                />
              )}
              {contributeId === 2 && (
                <div>
                  <p>
                    {" "}
                    Make your check out to Seeding Justice and mail it to our
                    office at 3625 N Mississippi Ave Portland, OR 97227
                  </p>
                </div>
              )}
              {contributeId === 3 && (
                <div>
                  <strong>
                    {" "}
                    Donations of stock or mutual funds come with tax advantages
                    -- and when you donate securities to Seeding Justice, you
                    can make those tax advantages work for justice. Gifts of
                    stock or mutual funds must be received into Seeding
                    Justice’s account in the calendar year for which you intend
                    to take the tax deduction (in other words, by December
                    31st). We recommend allowing up to six weeks for the
                    transfer.
                  </strong>
                  <p className="mt-3">
                    To donate stock or mutual funds to Seeding Justice:
                  </p>
                  <ul>
                    <li>
                      Complete and return our{" "}
                      <a
                        href="https://www.seedingjustice.org/wp-content/uploads/2021/12/Seeding-Justice-Stock-Gift-Instructions.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        Stock Gift Instructions form
                      </a>
                      , as stock gifts come through to our account without donor
                      information attached. Your completing and returning this
                      form allows us to ensure that you are properly credited
                      and acknowledged for your gift.
                    </li>
                    <li>
                      Then, send a{" "}
                      <a
                        href="https://www.seedingjustice.org/wp-content/uploads/2021/07/Sample_Transfer_Letter_for_Stock.doc"
                        className="text-decoration-none"
                      >
                        letter of instruction
                      </a>{" "}
                      to your broker with the information for our account at
                      Charles Schwab:
                    </li>
                  </ul>
                  <ul>
                    <li className="list-unstyled">Seeding Justice</li>
                    <li className="list-unstyled">
                      Schwab account #: 6112-9067
                    </li>
                    <li className="list-unstyled">DTC #: 0164</li>
                    <li className="list-unstyled">
                      Seeding Justice Tax ID #: 93-0691187
                    </li>
                  </ul>

                  <p>
                    {" "}
                    Please Note: The information provided here should not be
                    considered legal or tax advice. If you’re considering a
                    stock gift, be sure to consult with your tax and financial
                    advisors.
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            size="md"
            className="me-1 cancel_modal"
            color="secondary"
            onClick={() => setOpen(false)}
          // disabled={isloader}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Index;
