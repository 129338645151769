import React, { Fragment, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { Col, Label, Row, Spinner } from "reactstrap";
import prevettedsj from "../../../Services/prevettedGrantees";
import userdashboard from "../../../Services/userdashboard";
import PrevettedData from "./PrevettedData";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";

const PreVettedsjGrantee = () => {
  const [granteesData, setGranteesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState({});

  LoadingOverlay.propTypes = undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const handleAreasChange = (value) => {
    categories["categories"] = value;
    if(page == 1){
      getGranteelist(page);
    }
    setIsLoader(true);
    setCategories(categories);
    setPage(1)
    setTotalPages(totalPages/10)
  };

  const getGranteelist = (page) => {
    setIsLoader(true);
    let catg_id = categories["categories"]?.value;
    let params = {};
    if (catg_id) {
      params = {
        page: page,
        category_id: catg_id,
      };
    } else {
      params = {
        page: page,
      };
    }
    userdashboard
      .listsjgrantees(params)
      .then((res) => {
        if (res?.data?.status) {
          setGranteesData(res?.data?.data?.data);
          setPage(res?.data?.data.current_page);
          setTotalPages(Math.ceil(res?.data?.data.total / res?.data?.data?.per_page));
          setIsLoader(false);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handlePageChange = (page) => {
    setIsLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsLoader(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.selected+1);
    const newSearch = searchParams.toString();

    navigate({
      pathname: location.pathname,
      search: `?${newSearch}`
    });
  };

  const getCategories = () => {
    setIsLoader(false);
    prevettedsj
      .prevttedcategorylist()
      .then((res) => {
        if (res.data.status) {
          let categories = [];
          res.data.data.forEach((category, index) => {
            categories[index] = {
              label: category.category_name,
              value: category.id,
            };
          });
          setCategories(categories);
        }
        setIsLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };

  useEffect(() => {
    getGranteelist(page);
  }, [page]);

  useEffect(() => {
    setLoader(true);
    getCategories();
  }, []);

  return (
    <>
      <div className="userAdminPanel">
        <Row className="customised-row">
          <Col md={3}>
            <Sidebar />
          </Col>
          <Col md={9}>
            <div
              className="mt-4 mb-5 userAdminArticle"
              style={{ minHeight: "350px" }}
            >
              <div className="pt-3 userAdminArticleHeading">
                <h4 className="mb-0">Recommended Grantees</h4>
                <hr />
                <p>
                  We prioritize funding for small, emergent, and grassroots
                  organizations and those that are led by Black and Indigenous
                  people and other communities of color, especially those that
                  identify as having other intersecting identities, such as
                  LGBTQIA2S+, immigrants and refugees, folks living with
                  disabilities, people living with low incomes, folks that are
                  currently or formerly incarcerated, houseless people, those
                  living in rural communities, and others. Check out our
                  database below.
                </p>
                <div className="funding_bg">
                  <Label>What grant areas are you interested in funding?</Label>
                  <Select
                    id="categories"
                    name="categories"
                    placeholder={<div>Select Grantee Areas</div>}
                    value={
                      categories["categories"] ? categories["categories"] : []
                    }
                    options={categories}
                    classNamePrefix="select"
                    isClearable={true}
                    onChange={handleAreasChange}
                  />
                </div>
              </div>
              <Fragment>
                <Fragment>
                  {loader ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "250px" }}
                    >
                      <Spinner
                        style={{
                          height: "60px",
                          width: "60px",
                        }}
                      />
                    </div>
                  ) : (
                    <Row className="ps-3 pe-3">
                      <div className="PrevettedData">
                        <h5 className="mt-3 mb-3">
                          {categories["categories"]?.label}
                        </h5>
                        {granteesData?.length > 0 ? (
                          <LoadingOverlay
                            active={isLoader}
                            spinner={
                              <Spinner
                                style={{
                                  height: 50,
                                  width: 50,
                                  color: "#00344B",
                                }}
                              />
                            }
                            fadeSpeed={100}
                            classNamePrefix="mitiz"
                          >
                            <div className="mt-3 mb-3">
                              {granteesData?.map((sjgrantee, index) => (
                                <PrevettedData
                                  key={index}
                                  sjgrantee={sjgrantee}
                                  isLoader={isLoader}
                                />
                              ))}
                            </div>
                          </LoadingOverlay>
                        ) : (
                          !loader && (
                            <div>
                              <div key={0} colSpan="6" className="text-center">
                                <p className="text-center">Record not found.</p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Row>
                  )}
                </Fragment>
              </Fragment>
              <Fragment>
                {totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                )}
              </Fragment>
              {granteesData?.length > 0 ? (
                <div className="recommend-wrapper">
                  {/* <Row> */}
                    {/* <Col md={6} className="text-center">
                      <div className="recommend-image">

                        <img src="./assets/give.png" className="img-fluid" alt="assets_image" />
                      </div>
                    </Col> */}
                    {/* <Col md={6} className="offset-md-4"> */}
                      {/* <div className="recommend-details-wrapper"> */}
                        <div className="recommend-details text-center">
                          <h2>Can't find what you want?</h2>
                          <div className="text-center">
                            <Link
                              type="button"
                              id="recommend_grant_button"
                              className="btn btn-success btn-lg text-white my-3"
                              to={`/recommend-grants`}
                            >
                              Recommend a grant
                            </Link>
                          </div>
                        </div>
                      {/* </div> */}
                    {/* </Col> */}
                  {/* </Row> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PreVettedsjGrantee;
