import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import dmf from "../../../Services/dmfsubmission";
import prevettedsj from "../../../Services/prevettedGrantees";
import common from "../../../Services/common";
import Select from "react-select";
import userGrantee from "../../../Services/userGrantee";
import { Link } from "react-router-dom";
const EditPrevettedGranteeModal = (props) => {
  const [fields, setFields] = useState({ status: 1 });
  const [states, setStates] = useState({});
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [categories, setCategories] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);

  LoadingOverlay.propTypes = undefined;
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleStateChange = (value) => {
    fields["state_id"] = value;
    setFields(fields);
    setErrors({ ...errors, ["state_id"]: "" });
  };

  const handleNumberKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[a-zA-Z0-9]$/.test(keyValue);
    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const handleCategoryChange = (value, { action }) => {
    if (action == "clear") {
      fields["categories"] = "";
    } else {
      fields["categories"] = value;
    }
    setFields(fields);
    setErrors({ ...errors, ["categories"]: "" });
  };
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.org_name) {
      errors["org_name"] = "Organization Name can not be empty";
      formIsValid = false;
    }
    if (!fields.ein_number) {
      errors["ein_number"] = "EIN can not be empty";
      formIsValid = false;
    }
    if (!fields.org_website) {
      errors["org_website"] = "Website link can not be empty";
      formIsValid = false;
    }
    if (fields.org_website && !common.isValidUrl(fields["org_website"])) {
      errors["org_website"] = " Website link must be a valid URL";
      formIsValid = false;
    }
    if (!fields.mission) {
      errors["mission"] = "Mission can not be empty";
      formIsValid = false;
    }
    if (!fields.geography) {
      errors["geography"] = "Geography can not be empty";
      formIsValid = false;
    }
    if (!fields.street) {
      errors["street"] = "Street can not be empty";
      formIsValid = false;
    }
    if (!fields.city) {
      errors["city"] = "City can not be empty";
      formIsValid = false;
    }
    if (!fields.state_id) {
      errors["state_id"] = "State can not be empty";
      formIsValid = false;
    }
    if (!fields.zip) {
      errors["zip"] = "Zip can not be empty";
      formIsValid = false;
    }
    if (fields?.zip?.length < 5) {
      errors["zip"] = "Zip must be at least 5 digits";
      formIsValid = false;
    }
    if (
      fields.org_contact_email &&
      !common.isValidEmail(fields["org_contact_email"])
    ) {
      errors["org_contact_email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.priority_population) {
      errors["priority_population"] = "Priority population can not be empty";
      formIsValid = false;
    }
    if (!fields.categories) {
      errors["categories"] = "Category can not be empty";
      formIsValid = false;
    }
    // if (!logo) {
    //   errors["logo"] = "Please Choose file";
    //   formIsValid = false;
    // }
    setErrors(errors);
    return formIsValid;
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  };
  const handleEinPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9\b]+$/.test(keyValue);
    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const getStates = () => {
    dmf
      .states()
      .then((res) => {
        if (res.data.status) {
          let states = [];
          res.data.data.forEach((state, index) => {
            states[index] = {
              label: state.name,
              value: state.id,
            };
          });
          setStates(states);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const getCategories = () => {
    setLoader(true);
    prevettedsj
      .categorylist()
      .then((res) => {
        if (res.data.status) {
          let categories = [];
          res.data.data.forEach((category, index) => {
            categories[index] = {
              label: category.category_name,
              value: category.id,
            };
          });
          setCategories(categories);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };

  const getPrevetted = () => {
    let params = props.prevettedId;
    setLoader(true);
    userGrantee
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          let fields = res.data.data;
          if (res.data.data.state !== null) {
            fields["state_id"] = {
              label: res.data.data.state.name,
              value: res.data.data.state.id,
            };
          }
          if (res.data.data.prevetted_categories !== null) {
            let categories = [];
            res.data.data.prevetted_categories.forEach((arr, index) => {
              categories[index] = {
                label: arr.category_name,
                value: arr.id,
              };
            });
            fields["categories"] = categories;
          }
          // setFields(fields);
          setLoader(false);
          setFields(fields, res.data.data);
          setLoader(false);

          if (res.data.data.logo != null) {
            setImagePreview(res.data.data.logo);
          }
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsloader(true);
      const formData = new FormData();
      if (file || imagePreview) {
        formData.append("file", file || null);
      }
      formData.append("logo",imagePreview);
      formData.append("org_name", fields.org_name);
      formData.append("status", fields.status);
      formData.append("ein_number", fields.ein_number);
      formData.append("org_website", fields.org_website);
      formData.append("mission", fields.mission);
      formData.append("geography", fields.geography);
      formData.append("street", fields.street);
      formData.append("city", fields.city);
      formData.append("state_id", fields.state_id.value);
      formData.append("zip", fields.zip);
      formData.append("id", props.prevettedId);
      formData.append("vendor_name", fields.vendor_name);
      formData.append("account_number", fields.account_number);
      formData.append("payment_remarks", fields.payment_remarks);
      formData.append(
        "org_contact_name",
        fields.org_contact_name != null ? fields.org_contact_name : ""
      );
      formData.append(
        "org_contact_email",
        fields.org_contact_email != null ? fields.org_contact_email : ""
      );
      formData.append("priority_population", fields?.priority_population);
      formData.append(
        "categories",
        fields?.categories?.map((res) => res?.value)
      );

      userGrantee
        .update(formData)
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsloader(false);
            props.getPrevettedGrantee();
            props.handleClose();
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsloader(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          setIsloader(false);
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    if (props.prevettedId) {
      getPrevetted(props.prevettedId);
    }
    getStates();
    getCategories();
  }, []);
  return (
    <div>
      <Modal
        isOpen={props.prevettedOpen}
        size="lg"
        className="admin new-grantee-modal"
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={() => props.handleClose()}>
            {props.prevettedId && "Edit "}Recommended Grantee 
          </ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoadingOverlay
              active={loader}
              spinner={<Spinner style={{ color: "#00344B" }} />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="org_name">Organization Name</Label>
                    <Input
                      id="org_name"
                      name="org_name"
                      placeholder=""
                      type="text"
                      value={fields["org_name"] ? fields["org_name"] : ""}
                      onChange={(event) => handleChange(event, "org_name")}
                      invalid={errors["org_name"] ? true : false}
                    />
                    <FormFeedback>{errors["org_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="ein_number">EIN Number</Label>
                    <Input
                      id="ein_number"
                      name="ein_number"
                      placeholder=""
                      type="text"
                      min={0}
                      value={fields["ein_number"] ? fields["ein_number"] : ""}
                      onChange={(event) => handleChange(event, "ein_number")}
                      invalid={errors["ein_number"] ? true : false}
                    />
                    <FormFeedback>{errors["ein_number"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="org_website">Website Link</Label>
                    <Input
                      id="org_website"
                      name="org_website"
                      placeholder=""
                      type="text"
                      value={fields["org_website"] ? fields["org_website"] : ""}
                      onChange={(event) => handleChange(event, "org_website")}
                      invalid={errors["org_website"] ? true : false}
                    />
                    <FormFeedback>{errors["org_website"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="mission">Mission</Label>
                    <Input
                      id="mission"
                      name="mission"
                      placeholder=""
                      type="text"
                      value={fields["mission"] ? fields["mission"] : ""}
                      onChange={(event) => handleChange(event, "mission")}
                      invalid={errors["mission"] ? true : false}
                    />
                    <FormFeedback>{errors["mission"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="geography">Geography</Label>
                    <Input
                      id="geography"
                      name="geography"
                      placeholder=""
                      type="text"
                      value={fields["geography"] ? fields["geography"] : ""}
                      onChange={(event) => handleChange(event, "geography")}
                      invalid={errors["geography"] ? true : false}
                    />
                    <FormFeedback>{errors["geography"]}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="street">Street</Label>
                    <Input
                      id="street"
                      name="street"
                      placeholder=""
                      type="text"
                      value={fields["street"] ? fields["street"] : ""}
                      onChange={(event) => handleChange(event, "street")}
                      invalid={errors["street"] ? true : false}
                    />
                    <FormFeedback>{errors["street"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="city">City</Label>
                    <Input
                      style={{ height: 44 }}
                      id="city"
                      name="city"
                      placeholder=""
                      type="text"
                      value={fields["city"] ? fields["city"] : ""}
                      onChange={(event) => handleChange(event, "city")}
                      invalid={errors["city"] ? true : false}
                    />
                    <FormFeedback>{errors["city"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="state_id">State</Label>
                    <Select
                      name="state_id"
                      id="state_id"
                      placeholder={<div>Select State...</div>}
                      value={fields["state_id"] ? fields["state_id"] : []}
                      options={states}
                      classNamePrefix="select"
                      onChange={handleStateChange}
                    />

                    {errors["state_id"] && (
                      <small className="fa-1x text-danger">
                        {errors["state_id"]}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="zip">Zip</Label>
                    <Input
                      id="zip"
                      name="zip"
                      placeholder=""
                      type="text"
                      maxLength={5}
                      onKeyPress={(event) =>
                        handleEinPress(event, "zip")
                      }
                      valu
                      value={fields["zip"] ? fields["zip"] : ""}
                      onChange={(event) => handleChange(event, "zip")}
                      invalid={errors["zip"] ? true : false}
                    />
                    <FormFeedback>{errors["zip"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="org_contact_name">Contact Name</Label>
                    <Input
                      name="org_contact_name"
                      placeholder=""
                      type="text"
                      value={
                        fields["org_contact_name"]
                          ? fields["org_contact_name"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "org_contact_name")
                      }
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="org_contact_email">Contact Email</Label>
                    <Input
                      name="org_contact_email"
                      placeholder=""
                      type="text"
                      value={
                        fields["org_contact_email"]
                          ? fields["org_contact_email"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "org_contact_email")
                      }
                      invalid={errors["org_contact_email"] ? true : false}
                    />
                    <FormFeedback>{errors["org_contact_email"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="priority_population">
                      Priority population(s)
                    </Label>
                    <Input
                      id="priority_population"
                      name="priority_population"
                      placeholder=""
                      type="text"
                      value={
                        fields["priority_population"]
                          ? fields["priority_population"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "priority_population")
                      }
                      invalid={errors["priority_population"] ? true : false}
                    />
                    <FormFeedback>{errors["priority_population"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="categories">Categories</Label>
                    <Select
                      id="categories"
                      name="categories"
                      placeholder={<div>Select categories...</div>}
                      isMulti
                      value={fields["categories"] ? fields["categories"] : []}
                      options={categories}
                      classNamePrefix="select"
                      onChange={handleCategoryChange}
                    />
                    {errors["categories"] && (
                      <small className="fa-1x text-danger">
                        {errors["categories"]}
                      </small>
                    )}
                    <FormFeedback>{errors["categories"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="file">Logo</Label>
                      <Input
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleFileUpload}
                        invalid={errors["file"] ? true : false}
                      />
                      <FormFeedback>{errors["file"]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    {imagePreview !== null ? (
                      <FormGroup className="text-center">
                        <img
                          src={imagePreview}
                          style={{ maxHeight: "120px", maxWidth: "120px" }}
                          className="img-thumbnail"
                          alt="seeding-justice"
                        />
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <fieldset className="custom-fieldset">
                  <FormGroup row>
                    <Col md={6}>
                      <div>
                        <strong>
                          {" "}
                          <a
                            href="https://www.bill.com/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#E26A38" }}
                          >
                            Bill.com
                          </a>{" "}
                          Vendor Name
                        </strong>{" "}
                        <Input
                          name="vendor_name"
                          placeholder=""
                          type="text"
                          value={
                            fields["vendor_name"] ? fields["vendor_name"] : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "vendor_name")
                          }
                          invalid={errors["vendor_name"] ? true : false}
                        />
                        <FormFeedback>{errors["vendor_name"]}</FormFeedback>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <strong>
                          {" "}
                          <a
                            href="https://www.bill.com/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#E26A38" }}
                          >
                            Bill.com
                          </a>{" "}
                          Payment Network ID (PNI)
                        </strong>{" "}
                        <Input
                          name="account_number"
                          placeholder=""
                          type="text"
                          maxLength={16}
                          onKeyPress={(event) =>
                            handleNumberKeyPress(event, "account_number")
                          }
                          value={
                            fields["account_number"]
                              ? fields["account_number"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "account_number")
                          }
                          invalid={errors["account_number"] ? true : false}
                        />
                        <FormFeedback>{errors["account_number"]}</FormFeedback>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={12}>
                      <div>
                        <strong> Additional payment instructions </strong>{" "}
                        <Input
                          name="payment_remarks"
                          placeholder=""
                          type="textarea"
                          value={
                            fields["payment_remarks"]
                              ? fields["payment_remarks"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "payment_remarks")
                          }
                          invalid={errors["payment_remarks"] ? true : false}
                        />
                        <FormFeedback>{errors["payment_remarks"]}</FormFeedback>
                      </div>
                    </Col>
                  </FormGroup>
                </fieldset>
              </Row>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              onClick={props.handleClose}
              disabled={isloader}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="submit-Id-button"
              size="md"
              type="submit"
              disabled={isloader}
            >
              {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
              {props.prevettedId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default EditPrevettedGranteeModal;
