import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalHeader, Spinner, Button, ModalFooter } from "reactstrap";
import nominee from "../../Services/nominee";

const BoardApprovedModal = (props) => {
  const { modalData, handleClose, nomin, nominationId, open } = props;
  const [boardApproval, setBoardApproval] = useState(null);
  const [fields] = useState({});
  const [isloader, setIsloader] = useState("");
  const [errors, setErrors] = useState("");

  const sendRequest = (value) => {
    let params = fields;
    params["id"] = nominationId;
    params[modalData.action] = value;
    setIsloader(value);
    nominee
      .action(params)
      .then((res) => {
        setIsloader(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
          props.getNominations();
          setIsloader(false);
        } else {
          setErrors(res.data.message);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsloader(false);
      });
  };

  return (
    <div>
      <Modal isOpen={open} size="md" className="details_modal">
        <ModalHeader toggle={() => handleClose()}>
          {boardApproval ? "Board Approval Confirmation" : modalData.modelText}
        </ModalHeader>

        {boardApproval ? (
          <div className="text-center">
            <p className="pt-2" style={{ color: "#db0033" }}>
              Are you sure you want to mark this to
              <span className="text-capitalize"> '{boardApproval}'?</span>
            </p>
            <ModalFooter>
              <Button
                className="me-2 cancel_modal"
                disabled={isloader}
                onClick={() => setBoardApproval(null)}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="submit-Id-button"
                disabled={isloader}
                onClick={() => sendRequest(boardApproval === "yes" ? 1 : 2)}
              >
                {isloader && <Spinner size="sm" className="mx-1" />}
                Confirm
              </Button>
            </ModalFooter>
          </div>
        ) : (
          ""
        )}
        {boardApproval === null ? (
          <ModalFooter>
            <Button
              type="button"
              size="md"
              className="me-1 cancel_modal"
              disabled={isloader}
              onClick={handleClose}
            >
              Cancel
            </Button>

            {modalData.action === "is_board_approved" ||
            nomin.is_board_approved >= 1 ? (
              <>
                <Button
                  type="button"
                  size="md"
                  className="me-1 modal_no_button"
                  color="danger"
                  disabled={isloader}
                  onClick={() =>
                    modalData.action === "is_board_approved"
                      ? setBoardApproval("no")
                      : ""
                  }
                >
                  {isloader === 2 ? <Spinner size="sm" className="mx-1" /> : ""}
                  {modalData.action === "is_board_approved" &&
                    modalData.buttonNo}
                </Button>

                <Button
                  type="button"
                  size="md"
                  className="me-1 submit-Id-button"
                  disabled={isloader}
                  onClick={() =>
                    modalData.action === "is_board_approved"
                      ? setBoardApproval("yes")
                      : ""
                  }
                >
                  {isloader == 1 ? <Spinner size="sm" className="mx-1" /> : ""}
                  {modalData.buttonYes}
                </Button>
              </>
            ) : (
              ""
            )}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default BoardApprovedModal;
