import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import user from "../../Services/user";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BsPersonFill, BsFillKeyFill } from "react-icons/bs";
  
function Login() {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false });
  const [loader, setLoader] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggle = () => {
    setVisible(!isVisible);
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let params = fields;
    user
      .login(params)
      .then((data) => {
        let res = data.data;
        if (res.status) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("email_verified", res.data.email_verified);
          localStorage.setItem("isAdmin", res.data.user.is_admin);
          localStorage.setItem("first_name", res.data.user.first_name);
          localStorage.setItem("last_name", res.data.user.last_name);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("user_id", JSON.stringify(res.data.user.id));
          localStorage.setItem("prevetted_grantee_id", JSON.stringify(res.data.user.prevetted_grantee_id));
          let payload = {
            token: res.data.token,
            email_verified: res.data.email_verified,
            user_id: res.data.user.id,
            email: res.data.user.email,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            isAdmin: res.data.user.is_admin,
            prevetted_grantee_id:res.data.user.prevetted_grantee_id
          };
          dispatch({ type: "login", ...payload });
          setLoader(false);
          if (parseInt(res.data.user.is_admin) === 1) {
            navigate("/admin/dashboard",{ replace: true });
          } 
          else if(res.data.user.prevetted_grantee_id !== null){
            navigate("/prevetted/dashboard",{ replace: true });
          } 
          else {
            if (res.data.user.id && res.data.email_verified === true) {
              navigate("/giving-history",{ replace: true });
            } else {
              navigate("/email-verification");
            }
          }
        } else if (res.status === false) {
          if (res.message) {
            setAlert({ show: true, message: res.message });
            setLoader(false);
          }
        }
        else {
          let errors = {};
          for (let key in res.message) {
            errors[key] = res.message[key];
          }
          setErrors(errors);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Login : seeding-Justice</title>
      </Helmet>
      <section className="homepage_banner secondary-homepage_banner">
        <Container>
          <Row>
            <Col className="col-md-6 offset-md-3">
              <Card className="login login_bg">
                <CardBody>
                  <div className="text-center">
                    <h1>Welcome</h1>
                    <p>
                      Welcome to Donorview, the secure portal to manage your
                      Seeding Justice Donor in Movement Fund. Please provide
                      your email and password below. To request online
                      access to your fund, or for more information about opening
                      a fund, please contact us.
                    </p>
                    <h6>Create a New Donor-Advised Fund Account</h6>
                  </div>
                  <Form
                    onSubmit={handleSubmit}
                    className="login-form signin-form mt-5"
                  >
                    <Row>
                      <Col md={12}>
                        <FormGroup className="form-group">
                          <BsPersonFill className="icon" />
                          <Input
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            type="text"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            style={{ paddingLeft: "40px" }}
                          />
                        </FormGroup>
                        {alert.show ? (
                          <span className="text-danger login_validation">
                            {alert.message?.email}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-group">
                          <BsFillKeyFill className="icon key" />
                          <Input
                            id="password"
                            name="password"
                            placeholder="Password"
                            type={!isVisible ? "password" : "text"}
                            value={fields["password"] ? fields["password"] : ""}
                            style={{ paddingLeft: "40px", cursor: "pointer" }}
                            onChange={(event) =>
                              handleChange(event, "password")
                            }
                          />
                          <span onClick={toggle}>
                            {isVisible ? (
                              <RiEyeFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <RiEyeOffFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </span>
                        </FormGroup>
                        {alert.show ? (
                          <span className="text-danger login_validation">
                            {alert.message?.password}
                          </span>
                        ) : (
                          ""
                        )}
                        {alert.show &&
                        !alert.message?.password &&
                        !alert.message?.email ? (
                          <span className="text-danger">
                            {alert.message ? alert.message : null}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={12}>
                        <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                          className="submit-button"
                        >
                          {loader ? <Spinner size="sm" className="mx-1" /> : ""}
                          Login
                        </Button>
                      </Col>
                      <Col md={12} className="text-center mt-4 mb-2">
                        <Link to="/forgot-password" className="orange-text">
                          Forgot Password?{" "}
                        </Link>
                      </Col>
                      <Col md={12} className="mt-2 text-center text-dark">
                        Register an account?{" "}
                        <Link to="/signup" className="orange-text">
                          {" "}
                          Sign Up
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Login;
