import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import dmf from "../Services/dmfsubmission";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import common from "../Services/common";

const DmfSubmissionForm = (props) => {
  const [fields, setFields] = useState({
    interested_area_id: [],
  });
  const [iamn, setIamn] = useState([]);
  const [intrestedAreas, setIntrestedAreas] = useState([]);
  const [recognition, setRecognition] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [states, setStates] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  LoadingOverlay.propTypes = undefined;

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleStateChange = (value) => {
    fields["select_state"] = value;
    setFields(fields);
    setErrors({ ...errors, ["select_state"]: "" });
  };

  const handleAreasChange = (e) => {
    const isChecked = e.target.checked;
    const checkValue = e.target.value;
    let arr = fields.interested_area_id;
    if (isChecked) {
      setFields({
        ...fields,
        interested_area_id: [...fields.interested_area_id, checkValue],
      });
    } else {
      setFields({
        ...fields,
        interested_area_id: arr.filter((x) => x != checkValue),
      });
    }
    setErrors({ ...errors, ["interested_area_id"]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (fields.first_name && !common.isValidName(fields["first_name"])) {
      errors["first_name"] = "Enter valid First Name";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (fields.last_name && !common.isValidName(fields["last_name"])) {
      errors["last_name"] = "Enter valid Last Name";
      formIsValid = false;
    }
    if (!fields.phone) {
      errors["phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (fields.phone && !common.isValidPhone(fields["phone"])) {
      errors["phone"] = "Enter valid mobile number!";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.email && !common.isValidEmail(fields["email"])) {
      errors["email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.select_state) {
      errors["select_state"] = "State can not be empty";
      formIsValid = false;
    }
    if (!fields.city) {
      errors["city"] = "City can not be empty";
      formIsValid = false;
    }
    if (fields.city && !common.isValidAddress(fields["city"])) {
      errors["city"] = "Enter valid City name";
      formIsValid = false;
    }
    if (!fields.prefix) {
      errors["prefix"] = "Prefix can not be empty";
      formIsValid = false;
    }

    if (fields.interested_area_id.length < 1) {
      errors["interested_area_id"] = "Please choose Interested areas field";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
    setErrors({ ...errors, [field_name]: (phone = "") });
  };

  const getStates = () => {
    dmf
      .states()
      .then((res) => {
        if (res.data.status) {
          let states = [];
          res?.data?.data.forEach((state, index) => {
            states[index] = {
              label: state?.name,
              value: state?.id,
            };
          });
          setStates(states);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const getDmfSubmission = (id) => {
    let params = id;
    setLoader(true);
    dmf
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          let fields = res?.data?.data;
          if (res?.data?.data?.state !== null) {
            fields["select_state"] = {
              label: res?.data?.data?.state?.name,
              value: res?.data?.data?.state?.id,
            };
          }
          if (res.data.data.interested_areas != null) {
            let interested_area_control_arr = [];
            res.data.data.interested_areas.forEach((arr, index) => {
              interested_area_control_arr[index] = arr.id;
            });
            fields["interested_area_id"] = interested_area_control_arr;
          }
          setFields(fields);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const dmfDropdowns = () => {
    setLoader(true);
    dmf
      .dmf_dropdowns()
      .then((res) => {
        if (res?.data?.status) {
          setIamn(res?.data?.data?.iaman);
          setIntrestedAreas(res?.data?.data?.interested_areas);
          setRecognition(res?.data?.data?.recognition);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoader(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      fields["state_id"] = params?.select_state?.value;
      setIsloader(true);
      if (props.dmfId) {
        dmf
          .update(params)
          .then((res) => {
            setIsloader(false);
            if (res.data.status) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });

              setFields({});
              props.handleClose();
              props.getDmfSubmission();
              setIsloader(false);
            } else {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
              setIsloader(false);
            }
          })
          .catch(function (error) {
            console.log("error", error);
            setIsloader(false);
          });
      } else {
        dmf
          .add(params)
          .then((res) => {
            setIsloader(false);
            if (res.data.status) {
              // toast.success(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              navigate("/success-dmfsubmission");
              setFields({});
              setErrors({});
              setIsloader(false);
            } else {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
              setIsloader(false);
            }
          })
          .catch(function (error) {
            console.log("error", error);
            setIsloader(false);
          });
      }
    }
  };

  const checkInterestedArea = (id) => {
    if (fields.interested_areas) {
      let hasId = fields.interested_areas.filter(
        (ia) => parseInt(ia.id) === parseInt(id)
      );
      if (hasId.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (props.dmfId) {
      getDmfSubmission(props.dmfId);
    }
    getStates();
    dmfDropdowns();
  }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit} className="signup-form mt-4">
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <Row>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="first_name">First Name</Label>}
                <Input
                  id="first_name"
                  name="first_name"
                  placeholder={!props.dmfId ? "First Name" : ""}
                  type="text"
                  value={fields["first_name"] ? fields["first_name"] : ""}
                  onChange={(event) => handleChange(event, "first_name")}
                  invalid={errors["first_name"] ? true : false}
                />
                <FormFeedback>{errors["first_name"]}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="last_name">Last Name</Label>}
                <Input
                  id="last_name"
                  name="last_name"
                  placeholder={!props.dmfId ? "Last Name" : ""}
                  type="text"
                  value={fields["last_name"] ? fields["last_name"] : ""}
                  onChange={(event) => handleChange(event, "last_name")}
                  invalid={errors["last_name"] ? true : false}
                />
                <FormFeedback>{errors["last_name"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="prefix">Preferred pronouns</Label>}
                <Input
                  id="prefix"
                  name="prefix"
                  placeholder={!props.dmfId ? "Preferred pronouns" : ""}
                  type="text"
                  value={fields["prefix"] ? fields["prefix"] : ""}
                  onChange={(event) => handleChange(event, "prefix")}
                  invalid={errors["prefix"] ? true : false}
                />
                <FormFeedback>{errors["prefix"]}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="email">Email</Label>}

                <Input
                  id="email"
                  name="email"
                  placeholder={!props.dmfId ? "Email" : ""}
                  type="text"
                  value={fields["email"] ? fields["email"] : ""}
                  onChange={(event) => handleChange(event, "email")}
                  invalid={errors["email"] ? true : false}
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="phone">Phone</Label>}
                <PhoneInput
                  countryCodeEditable={false}
                  onlyCountries={["us"]}
                  value={fields["phone"] ? fields["phone"] : ""}
                  onChange={(phone) => handlePhone(phone, "phone")}
                  country="us"
                  className="phone-input"
                />
                {errors["phone"] && (
                  <small className="fa-1x text-danger">{errors["phone"]}</small>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="city">City</Label>}
                <Input
                  placeholder={!props.dmfId ? "City" : ""}
                  id="city"
                  name="city"
                  type="text"
                  value={fields["city"] ? fields["city"] : ""}
                  onChange={(event) => handleChange(event, "city")}
                  invalid={errors["city"] ? true : false}
                  style={{ height: 44 }}
                />
                <FormFeedback>{errors["city"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="select_state">State</Label>}
                <Select
                  name="select_state"
                  id="select_state"
                  placeholder={<div>Select State...</div>}
                  value={fields["select_state"] ? fields["select_state"] : []}
                  options={states}
                  classNamePrefix="select signup-select"
                  onChange={handleStateChange}
                />
                {errors["select_state"] && (
                  <small className="fa-1x text-danger">
                    {errors["select_state"]}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="street">Street</Label>}
                <Input
                  id="street"
                  name="street"
                  type="text"
                  placeholder={!props.dmfId ? "Street" : ""}
                  value={fields["street"] ? fields["street"] : ""}
                  onChange={(event) => handleChange(event, "street")}
                />
              </FormGroup>
            </Col>
            <Label className="text-dark" for="interested_area_control">
              What grant areas are you interested in funding?
            </Label>

            {intrestedAreas.map((ia, index) => (
              <Col md={6} key={`ia-${index}`}>
                <FormGroup check className="checkbox_form_group">
                  <Input
                    id={`ia-${index}`}
                    type="checkbox"
                    value={ia.id}
                    defaultChecked={checkInterestedArea(ia.id)}
                    onChange={(event) => handleAreasChange(event)}
                    invalid={errors["interested_area_id"] ? true : false}
                  />
                  <Label check for={`ia-${index}`} className="text-dark mt-1">
                    {ia.category_name}
                  </Label>
                </FormGroup>
              </Col>
            ))}

            {errors["interested_area_id"] && (
              <small className="fa-1x text-danger">
                {errors["interested_area_id"]}
              </small>
            )}

            <Col md={12} className="text-end" style={{ paddingTop: "20px" }}>
              {!props.dmfId ? (
                ""
              ) : (
                <Button
                  type="button"
                  size="lg"
                  className="me-1 cancel_modal"
                  disabled={isloader}
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </Button>
              )}
              <Button
                size="lg"
                type="submit"
                disabled={isloader}
                className={props.dmfId ? "submit-Id-button" : "submit-button"}
              >
                {isloader ? <Spinner size="sm" className="mx-1" /> : ""}
                {props.dmfId ? "Update" : "Submit"}
              </Button>
            </Col>
          </Row>
        </LoadingOverlay>
      </Form>
    </div>
  );
};

export default DmfSubmissionForm;
