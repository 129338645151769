import http from "./http";

const userGrantee = {
  list: (param) => http.get("/user/prevetted-grantees/list?q=grantee-user"),
  update: (data) => http.post("/user/update-prevetted-grantees/update", data),
  updateRequest: (data) => http.post("/user/update-prevetted-grantees/cancel", data),
  getOne: (param) => http.get(`/user/prevetted-grantees/get?id=${param}`),
};

export default userGrantee;
