import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Spinner,
  Label,
  Tooltip,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import { HiInformationCircle } from "react-icons/hi";
import dmf from "../Services/dmfsubmission";
import common from "../Services/common";
import LoadingOverlay from "react-loading-overlay";

const DmfIntakeFormSubmission = (props) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iamn, setIamn] = useState([]);
  const [states, setStates] = useState({});
  const [message, setMessage] = useState(null);
  const [recognition, setRecognition] = useState([]);
  const [validToken, setValidToken] = useState(false);
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleStateChange = (value) => {
    setFields({
      ...fields,
      ["spoke_state"]: value,
      // ["spoke_state"]: value.value,
    });
    setErrors({ ...errors, ["spoke_state"]: "" });
  };

  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
    setErrors({ ...errors, [field_name]: (phone = "") });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.legal_first_name) {
      errors["legal_first_name"] = "Legal First Name can not be empty";
      formIsValid = false;
    }
    if (
      fields.legal_first_name &&
      !common.isValidName(fields["legal_first_name"])
    ) {
      errors["legal_first_name"] = "Enter valid Legal first name!";
      formIsValid = false;
    }
    if (!fields.legal_last_name) {
      errors["legal_last_name"] = "Legal Last Name can not be empty";
      formIsValid = false;
    }
    if (
      fields.legal_last_name &&
      !common.isValidName(fields["legal_last_name"])
    ) {
      errors["legal_last_name"] = "Enter valid Legal last name";
      formIsValid = false;
    }
    if (!fields.initial_funding_amount) {
      errors["initial_funding_amount"] =
        "Initial funding amount can not be empty";
      formIsValid = false;
    }
    if (fields.initial_funding_amount) {
      const amount = fields.initial_funding_amount.replace("$", "");
      if (parseInt(amount) <= 0) {
        errors["initial_funding_amount"] = "Amount must be greater than $0";
        formIsValid = false;
      }
    }
    if (!fields.desired_fund_name) {
      errors["desired_fund_name"] = "Desired Fund Name can not be empty";
      formIsValid = false;
    }
    if (
      fields.desired_fund_name &&
      !common.isValidName(fields["desired_fund_name"])
    ) {
      errors["desired_fund_name"] = "Enter valid Desired Fund Name";
      formIsValid = false;
    }
    if (!fields.grantee_type_id) {
      errors["grantee_type_id"] = "I am (n): can not be empty";
      formIsValid = false;
    }

    if (!fields.recognition_id) {
      errors["recognition_id"] =
        "Donor Recognition or Anonymity can not be empty";
      formIsValid = false;
    }
    if (!fields.spoke_first_name) {
      errors["spoke_first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (
      fields.spoke_first_name &&
      !common.isValidName(fields["spoke_first_name"])
    ) {
      errors["spoke_first_name"] = "Enter valid Spoke first name";
      formIsValid = false;
    }
    if (!fields.spoke_last_name) {
      errors["spoke_last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (
      fields.spoke_last_name &&
      !common.isValidName(fields["spoke_last_name"])
    ) {
      errors["spoke_last_name"] = "Enter valid Spoke last name";
      formIsValid = false;
    }
    if (!fields.spoke_phone) {
      errors["spoke_phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (fields.spoke_phone && !common.isValidPhone(fields["spoke_phone"])) {
      errors["spoke_phone"] = "Enter valid mobile number!";
      formIsValid = false;
    }
    if (!fields.spoke_email) {
      errors["spoke_email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (fields.spoke_email && !common.isValidEmail(fields["spoke_email"])) {
      errors["spoke_email"] = "Enter valid Email Address!";
      formIsValid = false;
    }
    if (!fields.spoke_city) {
      errors["spoke_city"] = "City can not be empty";
      formIsValid = false;
    }
    if (fields.spoke_city && !common.isValidAddress(fields["spoke_city"])) {
      errors["spoke_city"] = "Enter valid city name";
      formIsValid = false;
    }
    if (fields.spoke_state.label === undefined) {
      errors["spoke_state"] = "State can not be empty";
      formIsValid = false;
    }
    if (!fields.spoke_street) {
      errors["spoke_street"] = "Street can not be empty";
      formIsValid = false;
    }
    // if (fields.spoke_street && !common.isValidStreet(fields["spoke_street"])) {
    //   errors["spoke_street"] = "Enter valid street name";
    //   formIsValid = false;
    // }
    setErrors(errors);
    return formIsValid;
  };

  const getStates = () => {
    dmf
      .states()
      .then((res) => {
        if (res.data.status) {
          let states = [];
          res.data.data.forEach((state, index) => {
            states[index] = {
              label: state?.name,
              value: state?.id,
            };
          });
          setStates(states);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const dmfDropdowns = () => {
    dmf
      .dmf_dropdowns()
      .then((res) => {
        if (res.data.status) {
          setIamn(res?.data?.data?.iaman);
          setRecognition(res?.data?.data?.recognition);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const getDmfSubmission = (id) => {
    let params = id;
    setLoading(true);
    dmf
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          let fields = res?.data?.data;
          if (res.data.data?.state !== null) {
            fields["spoke_state"] = {
              label: res.data.data?.spoke_state?.name,
              value: res.data.data?.spoke_state?.id,
            };
          }

          setFields(fields);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.dmfId) {
      getDmfSubmission(props.dmfId);
    }
    dmfDropdowns();
    getStates();
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      fields["spoke_state_id"] = params.spoke_state.value;
      fields["initial_funding_amount"] = params?.initial_funding_amount
        .replace("$", "")
        .replace(/,/g, "");

      params["token"] = token;
      setLoader(true);

      if (props.dmfId) {
        dmf
          .dmf_intake_update(params)
          .then((res) => {
            setLoader(false);
            if (res.data.status) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });

              setFields({});
              props.handleClose();
              props.getDmfSubmission();
              setLoader(false);
            } else {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
              setLoader(false);
            }
          })
          .catch(function (error) {
            console.log("error", error);
            setLoader(false);
          });
      } else {
        dmf
          .update_dmf_details(params)
          .then((res) => {
            setLoader(true);
            if (res.data.status) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoader(false);
              navigate("/success-intake-form-submit");
              setFields({});
            } else {
              setMessage(res.data.message.email[0]);
              setLoader(false);
            }
          })
          .catch(function (error) {
            console.log("error", error);
            setLoader(false);
          });
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="signup-form mt-4">
        <LoadingOverlay
          active={loading}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <Row>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && (
                  <Label for="legal_first_name">Legal First Name</Label>
                )}
                <Input
                  id="legal_first_name"
                  name="legal_first_name"
                  placeholder={!props.dmfId ? "Legal First Name" : ""}
                  type="text"
                  invalid={errors["legal_first_name"] ? true : false}
                  value={
                    fields["legal_first_name"] ? fields["legal_first_name"] : ""
                  }
                  onChange={(event) => handleChange(event, "legal_first_name")}
                />
                <FormFeedback>{errors["legal_first_name"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && (
                  <Label for="legal_last_name">Legal Last Name</Label>
                )}
                <Input
                  id="legal_last_name"
                  name="legal_last_name"
                  placeholder={!props.dmfId ? "Legal Last Name" : ""}
                  type="text"
                  invalid={errors["legal_last_name"] ? true : false}
                  value={
                    fields["legal_last_name"] ? fields["legal_last_name"] : ""
                  }
                  onChange={(event) => handleChange(event, "legal_last_name")}
                />
                <FormFeedback>{errors["legal_last_name"]}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="form-group">
                {props.dmfId && (
                  <Label for="desired_fund_name">Desired Fund Name</Label>
                )}
                <Input
                  id="desired_fund_name"
                  name="desired_fund_name"
                  placeholder={!props.dmfId ? "Desired fund name" : ""}
                  type="text"
                  invalid={errors["desired_fund_name"] ? true : false}
                  value={
                    fields["desired_fund_name"]
                      ? fields["desired_fund_name"]
                      : ""
                  }
                  onChange={(event) => handleChange(event, "desired_fund_name")}
                />
                <FormFeedback>{errors["desired_fund_name"]}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="initial_fund">
                {props.dmfId && (
                  <Label for="initial_funding_amount">
                    Initial Funding Amount (estimate)
                  </Label>
                )}
                <CurrencyFormat
                  placeholder={!props.dmfId ? "$0.00" : ""}
                  className="form-control"
                  value={
                    fields["initial_funding_amount"]
                      ? fields["initial_funding_amount"]
                      : ""
                  }
                  inputmode="numeric"
                  thousandSeparator={true}
                  prefix={"$"}
                  invalid={errors["initial_funding_amount"] ? true : false}
                  onChange={(event) =>
                    handleChange(event, "initial_funding_amount")
                  }
                />
                {errors["initial_funding_amount"] && (
                  <small className="fa-1x text-danger">
                    {errors["initial_funding_amount"]}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup className="form-group">
                {props.dmfId && <Label for="grantee_type_id">I am a(n):</Label>}

                <Input
                  placeholder={!props.dmfId ? "I am a(n):" : ""}
                  type="select"
                  name="grantee_type_id"
                  style={{ cursor: "pointer" }}
                  value={
                    fields["grantee_type_id"] ? fields["grantee_type_id"] : ""
                  }
                  onChange={(event) => handleChange(event, "grantee_type_id")}
                  invalid={errors["grantee_type_id"] ? true : false}
                >
                  <option value="" selected disabled>
                    {iamn.length === 0 ? "Loading..." : "I am a(n):"}
                  </option>
                  {iamn.map((iam, i) => (
                    <option value={iam.id} key={`key-iam-${i}`}>
                      {iam.category_name}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors["grantee_type_id"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="form-group select-customise">
                {props.dmfId && (
                  <Label for="recognition_id">
                    Would you like Donor Recognition or Anonymity?
                  </Label>
                )}
                <Input
                  placeholder={
                    !props.dmfId
                      ? "Would you like Donor Recognition or Anonymity?"
                      : ""
                  }
                  type="select"
                  style={{ cursor: "pointer"}}
                  name="recognition_id"
                  invalid={errors["recognition_id"] ? true : false}
                  value={
                    fields["recognition_id"] ? fields["recognition_id"] : ""
                  }
                  onChange={(event) => handleChange(event, "recognition_id")}
                >
                  <option value="" selected disabled>
                    {recognition.length === 0
                      ? "Loading..."
                      : "Would you like Donor Recognition or Anonymity?"}
                  </option>
                  {recognition.map((recog, i) => (
                    <option value={recog.id} key={`key-recog-${i}`}>
                      {recog.category_name}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors["recognition_id"]}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <fieldset>
            <Row>
              <Col md={12} className="spoke_details_tooltip">
                <span className="spkeperson_text">
                  Donors Spokes person Information:
                </span>
                <HiInformationCircle
                  style={{ color: "#E26A38", cursor: "pointer" }}
                  id="donor_spokeperson_id"
                />
                <Tooltip
                  isOpen={tooltipOpen}
                  delay={{ show: 100, hide: 1000 }}
                  target="donor_spokeperson_id"
                  placement="top"
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                  style={{ minWidth: "300px" }}
                >
                  Donor’s Spokes person. The person (individual or entity) that
                  the Donor identifies below (which may be the Donor) shall be
                  the Donor’s Spokes person who shall be Seeding Justice’s
                  primary contact person for all correspondence and matters
                  relating to the Fund and all Donor-in-Movement grants from the
                  Fund.
                </Tooltip>
              </Col>

              <Col md={12}>
                <hr style={{ color: "#E26A38" }} />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_first_name">
                      Spoke Person First Name
                    </Label>
                  )}
                  <Input
                    id="spoke_first_name"
                    name="spoke_first_name"
                    placeholder={!props.dmfId ? "First Name" : ""}
                    type="text"
                    invalid={errors["spoke_first_name"] ? true : false}
                    value={
                      fields["spoke_first_name"]
                        ? fields["spoke_first_name"]
                        : ""
                    }
                    onChange={(event) =>
                      handleChange(event, "spoke_first_name")
                    }
                  />
                  <FormFeedback>{errors["spoke_first_name"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_last_name">Spoke Person Last Name</Label>
                  )}
                  <Input
                    id="spoke_last_name"
                    name="spoke_last_name"
                    placeholder={!props.dmfId ? "Last Name" : ""}
                    type="text"
                    invalid={errors["spoke_last_name"] ? true : false}
                    value={
                      fields["spoke_last_name"] ? fields["spoke_last_name"] : ""
                    }
                    onChange={(event) => handleChange(event, "spoke_last_name")}
                  />
                  <FormFeedback>{errors["spoke_last_name"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_phone">Spoke Person Phone</Label>
                  )}
                  <PhoneInput
                    countryCodeEditable={false}
                    className="update"
                    onlyCountries={["us"]}
                    value={fields["spoke_phone"] ? fields["spoke_phone"] : ""}
                    invalid={errors["spoke_last_name"] ? true : false}
                    onChange={(phone) => handlePhone(phone, "spoke_phone")}
                    country="us"
                  />
                  {errors["spoke_phone"] && (
                    <small className="fa-1x text-danger">
                      {errors["spoke_phone"]}
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_email">Spoke Person Email</Label>
                  )}
                  <Input
                    id="spoke_email"
                    name="spoke_email"
                    placeholder={!props.dmfId ? "Email" : ""}
                    type="text"
                    value={fields["spoke_email"] ? fields["spoke_email"] : ""}
                    onChange={(event) => handleChange(event, "spoke_email")}
                    invalid={errors["spoke_email"] ? true : false}
                  />
                  <FormFeedback>{errors["spoke_email"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_city">Spoke Person City</Label>
                  )}

                  <Input
                    id="spoke_city"
                    name="spoke_city"
                    placeholder="City"
                    type="text"
                    value={fields["spoke_city"] ? fields["spoke_city"] : ""}
                    onChange={(event) => handleChange(event, "spoke_city")}
                    invalid={errors["spoke_city"] ? true : false}
                    style={{ height: 44 }}
                  />
                  <FormFeedback>{errors["spoke_city"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_state">Spoke Person State</Label>
                  )}
                  <Select
                  name="spoke_state"
                  id="spoke_state"
                  placeholder={<div>Select State...</div>}
                  value={fields["spoke_state"] ? fields["spoke_state"] : []}
                  options={states}
                  classNamePrefix="select"
                  onChange={handleStateChange}
                />
                {errors["spoke_state"] && (
                    <small className="fa-1x text-danger">
                      {errors["spoke_state"]}
                    </small>
                  )}
                  {/* <Select
                    name="spoke_state"
                    id="spoke_state"
                    placeholder={<div>Select State...</div>}
                    value={fields["spoke_state"] ? fields["spoke_state"] : []}
                    options={states}
                    classNamePrefix="select signup-select"
                    invalid={errors["spoke_state"] ? true : false}
                    onChange={handleStateChange}
                  />

                  {errors["spoke_state"] && (
                    <small className="fa-1x text-danger">
                      {errors["spoke_state"]}
                    </small>
                  )} */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup className="form-group">
                  {props.dmfId && (
                    <Label for="spoke_street">Spoke Person Street</Label>
                  )}
                  <Input
                    id="spoke_street"
                    name="spoke_street"
                    type="text"
                    placeholder={!props.dmfId ? "Street" : ""}
                    value={fields["spoke_street"] ? fields["spoke_street"] : ""}
                    invalid={errors["spoke_street"] ? true : false}
                    onChange={(event) => handleChange(event, "spoke_street")}
                  />
                  <FormFeedback>{errors["spoke_street"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </fieldset>

          <span className="text-danger mb-3">
            {message != null &&
              message.replace(
                message != null && message,
                "Link has been expired."
              )}
          </span>

          <Col md={12} className="text-end">
            {!props.dmfId ? (
              ""
            ) : (
              <Button
                type="button"
                size="lg"
                className="me-1 cancel_modal"
                disabled={loader}
                onClick={() => props.handleClose()}
              >
                Cancel
              </Button>
            )}
            <Button
              color="success"
              size="lg"
              type="submit"
              disabled={loader}
              className={props.dmfId ? "submit-Id-button" : "submit-button"}
            >
              {loader ? <Spinner size="sm" className="mx-1" /> : ""}
              Submit
            </Button>
          </Col>
        </LoadingOverlay>
      </Form>
    </div>
  );
};

export default DmfIntakeFormSubmission;
