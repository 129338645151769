import React, { useState, useEffect } from "react";
import faq from "../../Services/faq";
import { AiOutlineDown } from "react-icons/ai";
import LoadingOverlay from "react-loading-overlay";
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
  Spinner,
} from "reactstrap";
const Index = () => {
  const [faqs, setFaqs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [collapse, setCollapse] = useState(0);

  const toggle = (e) => {
    let event = e.target.dataset.event;
    setCollapse((prevCollapse) =>
      prevCollapse === Number(event) ? 0 : Number(event)
    );
  };

  const getFaqs = () => {
    setLoader(true);
    faq
      .faq_list()
      .then((res) => {
        if (res?.data?.status) {
          setFaqs(
            res?.data?.data?.data.filter((f) => parseInt(f.donors) === 1)
          );
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <>
      <Helmet>
        <title>FAQs : seeding-Justice</title>
      </Helmet>
      <section className="">
        <Container style={{ minHeight: "400px" }} className="mt-4">
          <Row className="faqs_list">
            <Col className="col-md-9">
              <LoadingOverlay
                active={loader}
                spinner={
                  <Spinner
                    style={{ height: 60, width: 60, color: "#00344B" }}
                  />
                }
                fadeSpeed={100}
                classNamePrefix="mitiz"
              >
                <div className="d-flex justify-content-center">
                  <h2>Frequently Asked Questions</h2>
                </div>
                <CardBody className="recommend-grants-form px-2">
                  <div style={{ margin: "3rem 0" }}>
                    <div className="custom_accordian">
                      {faqs.map((faq, index) => (
                        <Container key={index}>
                          <Card
                            style={{ marginBottom: "5px" }}
                            className="overflow-hidden"
                          >
                            <CardHeader
                              onClick={toggle}
                              data-event={index}
                              style={{ backgroundColor: "white" }}
                            >
                              <span>{index + 1}.</span> {faq.question}{" "}
                              <AiOutlineDown style={{ float: "right" }} />
                            </CardHeader>
                            <Collapse
                              isOpen={collapse === index}
                              style={{ backgroundColor: "#f6f7f9" }}
                            >
                              <CardBody
                                style={{
                                  backgroundColor: "rgb(255 250 255)",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: faq.content,
                                  }}
                                />
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Container>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Index;
