import React from "react";
import { Card, Nav, NavItem } from "reactstrap";
import {BsFillEyeFill } from "react-icons/bs";
import { VscHistory } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { FaDollarSign, FaQuestionCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiTwotoneStar } from "react-icons/ai";
import { TiStarburst } from "react-icons/ti";
import { Helmet } from "react-helmet";

const Sidebar = () => {
  const Email = useSelector((state) => state.email);
  const first_name = useSelector((state) => state?.first_name);
  const last_name = useSelector((state) => state?.last_name);
  return (
    <>
    <Helmet>
        <title>Dashboard : seeding-Justice</title>
      </Helmet>
    <Card className="userAdminAside pt-4 mb-5">
      <div className="user_details">
        <div className="profileImage_sidebar">
          <span>{`${first_name?.charAt(0)}${last_name?.charAt(0)}`}</span>
        </div>
        <h5 className="profile_font">{`${first_name} ${last_name}`}</h5>
        <p>{Email}</p>
      </div>
      <Nav tabs fill>
        <NavItem>
          <Link
            to="/giving-history"
            className={
              document.URL.search("giving-history") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
            <BsFillEyeFill /> View Your Giving History
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to="/make-contributions"
            className={
              document.URL.search("make-contributions") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
            <FaDollarSign />
            Make Contributions
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to="/prevetted-sjgrantees"
            className={
              document.URL.search("prevetted-sjgrantees") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
            <AiTwotoneStar /> Recommended Grantees
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to="/recommend-grants"
            className={
              document.URL.search("recommend-grants") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
            <TiStarburst /> Recommend Grants
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to="/grant-recommendation-history"
            className={
              document.URL.search("grant-recommendation-history") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
            <VscHistory /> Grant Nomination History
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to="/donor-faqs"
            className={
              document.URL.search("donor-faqs") > 0
                ? "active nav-link"
                : "nav-link"
            }
          >
             <FaQuestionCircle /> FAQs
          </Link>
        </NavItem>
      </Nav>
    </Card>
    </>

  );
};

export default Sidebar;
