import React, { useState } from "react";
import moment from "moment";
import common from "../../Services/common";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import QuarterlyReports from "./QuarterlyReports";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { BsXCircleFill } from "react-icons/bs";
import { TbReport } from "react-icons/tb";
import UserDetailsBody from "./UserDetailsBody";
import { Link } from "react-router-dom";

const User = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [reportId, setReportId] = useState("");
  const [usermodalOpen, setUserModalOpen] = useState(false);
  const [usermodalId, setUserModalId] = useState("");

  const ReportModal = (report_id) => {
    setReportOpen(true);
    setReportId(report_id);
  };
  const UserModal = (user_id) => {
    setUserModalOpen(true);
    setUserModalId(user_id);
  };
  const user = props.user;
  
  return (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>
        {" "}
        <Link
          to={""}
          onClick={() => UserModal(user.id)}
          className="text-decoration-none"
        >
          {common.getFullName(user)}
        </Link>
      </td>
      <td>{user.email}</td>
      <td>{user.phone}</td>
      <td>{moment(user.created_at).format("D MMM,  YYYY")}</td>
      <td className="text-center">
        <Dropdown
          toggle={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          isOpen={dropdownOpen}
          className="modal_Dropdown"
        >
          <DropdownToggle className="dropdown_btn">
            <FaEllipsisV />
          </DropdownToggle>
          <DropdownMenu container="body" right>
            <span onClick={() => props.openEditModal(user.id)}>
              <DropdownItem>
                <MdModeEdit
                  className="me-3 edit-icon"
                  size={20}
                  style={{ cursor: "pointer" }}
                />
                Edit
              </DropdownItem>
            </span>
            <DropdownItem onClick={() => ReportModal(user.id)}>
              <TbReport
                className="me-3 edit-icon"
                size={20}
                style={{ cursor: "pointer" }}
              />
              Quarterly Reports
            </DropdownItem>
            <DropdownItem onClick={() => UserModal(user.id)}>
              <FaEye
                className="me-3 details-icon"
                size={20}
                style={{ cursor: "pointer" }}
              />
              Details
            </DropdownItem>
            <span onClick={() => props.deleteUser(user.id)}>
              <DropdownItem>
                <BsXCircleFill
                  className="remove-icon me-3"
                  size={20}
                  style={{ cursor: "pointer" }}
                />
                Remove
              </DropdownItem>
            </span>
          </DropdownMenu>
        </Dropdown>
      </td>

      {reportOpen && (
        <QuarterlyReports
          reportOpen={reportOpen}
          handleClose={() => {
            setReportOpen();
            setReportId(null);
          }}
          reportId={reportId}
          id={user.id}
        />
      )}
      {usermodalOpen && (
        <UserDetailsBody
          usermodalOpen={usermodalOpen}
          handleClose={() => {
            setUserModalOpen();
            setUserModalId(null);
          }}
          usermodalId={usermodalId}
          id={user.id}
        />
      )}
    </tr>
  );
};

export default User;
