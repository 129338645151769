import http from "./http";

const user = {
  login: (data) => http.post("/auth/login", data),
  resendEmail: (data) => http.post("/email/verify/resend", data),
  signup: (data) => http.post("/auth/signup", data),
  getOneUser: (param) => http.get("user/profile/get"),
  updateUser: (data) => http.post("/user/profile/update", data),
  logout: (data) => http.post("/logout", data),
  forgetpassword: (data) => http.post("/forget-password", data),
  resetpassword: (data) => http.post("/reset-password", data),
  changepwd: (data) => http.post("/user/profile/change-password", data),
  listsjgrantees: (param) =>
    http.get("/user/prevetted-grantees/list?q=grantee-user", { params: param }),
    org_list: (param) => http.get("/user/prevetted-grantees/list?q=search-list", { params: param }),
    prevetted: (id) => http.get(`/user/prevetted-grantees/get?id=${id}`),
  verifySecondaryEmail: (data) =>
    http.post("/user/profile/secondary-email/verify/resend", data),
  verifyPrimaryEmail: (data) =>
    http.post("/user/profile/primary-email/verify/resend", data),
    getExcel: (param) =>
    http.get("/admin/users/export", { params: param, responseType: "blob" }),
    filedownload: (param) =>
    http.get(`/admin/quarterly-report/download/${param}`, {
      responseType: "blob",
    }),
  list: (param) => http.get("/admin/users", { params: param }),
  userDelete: (param) => http.delete(`/admin/users/${param}`),
  getOne: (param) => http.get(`admin/users/${param}`),
  update: (data) => http.patch(`admin/users/${data.id}`, data),
  getContribution: (param) =>
    http.get("user/profile/make-contributions", { params: param }),
};

export default user;
