import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import dashboard from "../../Services/dashboard";
import StatusCard from "./StatusCard";
import Users from "../Users";
import DashboardChart from "./DashboardChart";
import RecentDonors from "./RecentDonors/";
import MyCalendar from "./EventCalendar";

const Index = () => {
  const [chart, setChart] = useState([]);
  const [fields, setFields] = useState({});
  const [dmfData, setDmfData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [toolTipData, settoolTipData] = useState("");
  const [currentMonth, setCurrentMonth] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [currView, setCurrView] = useState("Month");
  const [dateFormat, setDateFormat] = "MM-DD-YYYY";

  const [ranges, setRange] = useState({
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Three Months": [moment().subtract(3, "month"), moment()],
    "Last Six Months": [moment().subtract(6, "month"), moment()],
  });

  const handleDateRange = (startDate = "", endDate = "") => {
    setLoader(true);
    let fields = [];
    if (startDate !== "" && endDate !== "") {
      fields["date_range"] =
        startDate.format("MM-DD-YYYY") + " to " + endDate.format("MM-DD-YYYY");
    } else {
      fields["date_range"] =
        moment().subtract(3, "month").format("MM-DD-YYYY") +
        " to " +
        moment().subtract(0, "month").format("MM-DD-YYYY");
    }

    setFields({ ...fields });
    let params = { ...fields };
    setLoader(true);
    dashboard
      .list(params)
      .then((res) => {
        if (res?.data?.status) {
          setFields(res?.data?.data);
          setChart(res?.data?.data?.chart);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const clearDateRange = () => {
    fields["date_range"] = "";
    setFields({ fields });
  };

  const calendarData = () => {
    dashboard
      .submission({
        date: currentMonth,
        view: currView,
      })
      .then((res) => {
        if (res?.data?.status) {
          // for(let i=0; i<eventData.length; i++){
          //   for(let j=i+1; j<eventData.length; j++){
          //     if(eventData[i].start == eventData[j].start){
          //       eventData[i].NewTitle = `${eventData[i].NewTitle} </br> ${eventData[j].NewTitle}`;
          //       // eventData.splice(j,1)
          //       // console.log(title);
          //     }
          //   }
          // }
          setDmfData(res?.data?.data);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    handleDateRange();
  }, []);

  useEffect(() => {
    calendarData();
  }, [currentMonth]);

  return (
    <section className="dashbord_container">
      <Row>
        <Col md={9} sm={12}>
          <h4 className="mb-0 title-font">Dashboard</h4>
          <p className="mb-0">Quick start about donor in Movement Fund</p>
        </Col>
        <Col md={3} sm={12}>
          <DateRangePicker
            initialSettings={{
              locale: {
                format: dateFormat,
                cancelLabel: "Clear",
              },
              ranges: ranges,
              autoUpdateInput: false,

              startDate: moment().format("MM-DD-YYYY"),
              endDate: moment().format("MM-DD-YYYY"),
            }}
            onCallback={handleDateRange}
            onCancel={clearDateRange}
          >
            <input
              type="text"
              placeholder={`Showing data for last 90 days`}
              className="form-control dropdown-picker"
              autoComplete="false"
              name="date_range"
              value={fields["date_range"] && fields["date_range"]}
              style={{ cursor: "pointer", textAlign: "center" }}
            />
          </DateRangePicker>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={8}>
          <div className="support_aside mb-3">
            <StatusCard fields={fields} Loader={Loader} />
          </div>
        </Col>
        <Col sm={4}>
          <div className="submission-chart">
            <DashboardChart chartData={chart} fields={fields} />
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={8}>
          <RecentDonors />
        </Col>
        <Col md={4}>
          <div className="my-1 admin-calender-customised">
            <MyCalendar
              dmfData={dmfData}
              toolTipData={toolTipData}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          </div>
          <div className="d-flex justify-content-center mt-1">
            <span className="calendar_dot_text me-2">Nominations</span>
            <span className="calendar_dot_text_purple">DMF submissions</span>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Index;
