import http from "./http";

const faq = {
  addUpdate: (data, id) => {
    if (data && id) {
      return http.post("/admin/faq/update", data);
    } else {
      return http.post(`/admin/faq/create`, data);
    }
  },
  // add: (data) => http.post("/admin/faq/create", data),
  sequence: (data) => http.post("/admin/faq/update-sequence", data),
  list: (param) => http.get("/admin/faq/list", { params: param }),
  faq_list: (param) => http.get("/faq/list", { params: param }),
  delete: (param) => http.post(`/admin/faq/delete`,{id:param}),
  getOne: (param) => http.get(`/admin/faq/get?id=${param}`),
  // update: (data) => http.post("/admin/faq/update", data),
  positionBanner: (data) => http.post('faq/change_banner_position', data),

};

export default faq;
