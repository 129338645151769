import moment from "moment";
import React, { useState } from "react";
import common from "../../Services/common";
import { FcOk } from "react-icons/fc";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { AiFillCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { FaEllipsisV} from "react-icons/fa";
import { BsCircle, BsXCircleFill } from "react-icons/bs";
import ConfirmationModal from "./ConfirmationModal";
import { Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";

const Submissions = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [eventModal, setEventModal] = useState(false);
  const [eventId, setEventId] = useState("");

  const { created_at, ngo_contract, id, confirmation_email, is_distributed } =
    props.prevettedSj;

  const showModalEvent = (eventId, actionType) => {
    if (actionType === 4) {
      setModalData({
        modelText: "Has the NGO Contract been sent?",
        action: "ngo_contract",
        modalLabel: "Ngo Contract",
        buttonNo: "NO",
        buttonYes: "YES",
      });
    } else if (actionType === 5) {
      setModalData({
        modelText:
          "Has the Grant been distributed? This operation cannot be undone.",
        action: "is_distributed",
        modalLabel: "Distributed",
        buttonNo: "NO",
        buttonYes: "YES",
      });
    }
    setEventId(eventId);
    setEventModal(true);
  };
  return (
    <>
      <tr key={id}>
        <td>
          <Link
            className="text-decoration-none"
            to={""}
            onClick={() => props.openEditModal(id)}
          >
            {common.getFullName(props.prevettedSj.user)}
          </Link>
        </td>
        <td>{props?.prevettedSj?.user?.email}</td>
        <td>{moment(created_at).format("D MMM,  YYYY")}</td>
        <td>
          {confirmation_email == 1 ? (
            <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
          ) : (
            <BsCircle size={25} />
          )}
        </td>
        <td>
          <>
            {confirmation_email > 0 ? (
              ngo_contract === 1 ? (
                <AiFillCheckCircle
                  style={{ color: "#03CD84", cursor: "pointer" }}
                  size={25}
                  onClick={() => showModalEvent(id, 4)}
                />
              ) : ngo_contract === 2 ? (
                <AiOutlineCloseCircle
                  className="text-danger"
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalEvent(id, 4)}
                />
              ) : (
                <BsCircle
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalEvent(id, 4)}
                />
              )
            ) : (
              ""
            )}
          </>
        </td>
        <td>
          <>
            {ngo_contract > 0 ? (
              is_distributed === 1 ? (
                <AiFillCheckCircle style={{ color: "#03CD84" }} size={25} />
              ) : is_distributed === 2 ? (
                <AiOutlineCloseCircle className="text-danger" size={22} />
              ) : (
                <BsCircle
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalEvent(id, 5)}
                />
              )
            ) : (
              <BsCircle
                size={25}
                style={{
                  backgroundColor: "#b3aeae",
                  borderRadius: "50%",
                  color: "#878787",
                }}
              />
            )}
          </>
        </td>

        {/* <td className="text-center">
          <FaEye
            onClick={() => props.openEditModal(id)}
            className="text-info  me-1"
            size={25}
            style={{ cursor: "pointer" }}
          />

          <MdDeleteForever
            className="text-danger"
            size={25}
            style={{ cursor: "pointer" }}
            onClick={() => props.deleteNomination(id)}
          />
        </td> */}

        <td className="text-center">
          <Dropdown
            toggle={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
            color="primary"
            className="modal_Dropdown"
          >
            <DropdownToggle className="dropdown_btn">
              <FaEllipsisV />
            </DropdownToggle>
            <DropdownMenu container="body" right>
                <span    onClick={() => props.openEditModal(id)}>
              <DropdownItem>
                  <FaEye
                    className="me-3 details-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  /> Details
              </DropdownItem>
                </span>
                <span   onClick={() => props.deleteNomination(id)}>
              <DropdownItem>
                  <BsXCircleFill
                    className="me-3 remove-icon"
                    size={20}
                    style={{ cursor: "pointer" }}
                  /> Delete
              </DropdownItem>
                </span>
            </DropdownMenu>
          </Dropdown>
        </td>

      </tr>
      {eventModal && (
        <ConfirmationModal
          open={eventModal}
          handleClose={() => {
            setEventModal();
            setEventId(null);
          }}
          eventId={eventId}
          modalData={modalData}
          getNominations={props.getNominations}
        />
      )}
    </>
  );
};

export default Submissions;
