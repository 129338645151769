import React, { useState, useEffect } from "react";
import { Card, Nav, NavItem } from "reactstrap";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaEdit, FaQuestionCircle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const SettingSidebar = () => {
  
  const [activeMenu, setActiveMenu] = useState();
  const location = useLocation();

  useEffect(() => {
    let route_name = location.pathname.split("/");
    route_name = route_name[route_name.length - 1];
    setActiveMenu(route_name);
  }, [location]);

  return (
    <>
      <Card className="shadow support_aside" style={{ width: "100%" }}>
        <Nav tabs fill>
          <NavItem>
            <Link
              id="sub-menu-font"
              to="/admin/settings/profile"
              className={
                activeMenu === "profile"
                  ? "active nav-link nav-font"
                  : "nav-link nav-font"
              }
              onClick={() => {
                setActiveMenu("settings/profile");
              }}
            >
              <FaEdit /> Profile
            </Link>
          </NavItem>

          <NavItem>
            <Link
              id="sub-menu-font"
              to="/admin/settings/change-password"
              className={
                activeMenu === "change-password"
                  ? "active nav-link nav-font"
                  : "nav-link nav-font"
              }
              onClick={() => {
                setActiveMenu("settings/change-password");
              }}
            >
              <RiLockPasswordFill /> Change Password
            </Link>
          </NavItem>

          <NavItem>
            <Link
              id="sub-menu-font"
              to="/admin/settings/global-configs"
              className={
                activeMenu === "global-configs"
                  ? "active nav-link nav-font"
                  : "nav-link nav-font"
              }
              onClick={() => {
                setActiveMenu("settings/global-configs");
              }}
            >
              <MdEmail /> Global Configs
            </Link>
          </NavItem>
          <NavItem>
            <Link
              id="sub-menu-font"
              to="/admin/settings/faqs/public"
              className={
                activeMenu === "public" || activeMenu === "donors" || activeMenu === "grantee" 
                ? "active nav-link nav-font"
                : "nav-link nav-font"
              }
              onClick={() => {
                setActiveMenu("settings/faqs/public");
              }}
            >
              <FaQuestionCircle /> FAQs
            </Link>
          </NavItem>
        </Nav>
      </Card>
    </>
  );
};

export default SettingSidebar;

