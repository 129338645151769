import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Button,
  FormText,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoadingOverlay from "react-loading-overlay";
import faq from "../../../Services/faq";

const AddEditFaq = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [content, setContent] = useState("");
  const [loader, setLoader] = useState(false);
  const [isloader, setIsloader] = useState(false);
  LoadingOverlay.propTypes = undefined;

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.question) {
      errors["question"] = "Question can not be empty";
      formIsValid = false;
    }

       let contentText = content.replace(/<[^>]*>/g, "");
    contentText = contentText.replace(/\s+/g, "");

    if (!content || contentText.length <= 0) {
      errors["content"] = "Content can not be empty";
      formIsValid = false;
    }
    if (!fields["grantees"] && !fields["donors"] && !fields["public"]) {
      errors["grantees"] = "Please select at least one option";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const getFaq = () => {
    let params = props.faqId;
    setLoader(true);
    faq
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setContent(res.data.data.content);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const handleChange = (e, field) => {
    if (
      fields.donors === undefined ||
      fields.grantees === undefined ||
      fields.public === undefined
    ) {
      fields.donors = false;
      fields.grantees = false;
      fields.public = false;
    }
    setFields({
      ...fields,
      [field]:
        e.target.type === "checkbox"
          ? e.target.checked
            ? true
            : false
          : e.target.value,
    });
    setErrors({ ...errors, [field]: "" });
  };
  const handleContentChange = (content) => {
    setContent(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsloader(true);
      let params = { ...fields, content };
      faq
        .addUpdate(params, props.faqId)
        .then((res) => {
          if (res.data.status) {
            setIsloader(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.handleClose();
            props.getFaqs();
          } else {
            let errors = {};
            for (let key in res.data.message) {
              errors[key] = res.data.message[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          setIsloader(false);
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    if (props.faqId) {
      getFaq(props.faqId);
    }
  }, []);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code-block",
  ];
  return (
    <>
      <Row>
        <Col xl={12}>
          <Row>
            <Col>
              <Modal
                isOpen={props.open}
                size="lg"
                className="admin new-faqs-modal"
              >
                <Form onSubmit={handleSubmit}>
                  <ModalHeader toggle={() => props.handleClose()}>
                    {props.faqId && "Edit "} Frequently Asked Questions
                  </ModalHeader>
                  <ModalBody className="pl-4 pr-4">
                    <LoadingOverlay
                      active={loader}
                      spinner={<Spinner style={{ color: "#00344B" }} />}
                      fadeSpeed={100}
                      classNamePrefix="mitiz"
                    >
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="question">Question</Label>
                            <Input
                              id="question"
                              name="question"
                              placeholder=""
                              type="text"
                              value={
                                fields["question"] ? fields["question"] : ""
                              }
                              onChange={(event) =>
                                handleChange(event, "question")
                              }
                              invalid={errors["question"] ? true : false}
                            />
                            <FormFeedback>{errors["question"]}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="content">Answer</Label>
                            <ReactQuill
                              theme="snow"
                              name="content"
                              id="content"
                              value={content}
                              modules={modules}
                              formats={formats}
                              onChange={handleContentChange}
                              invalid={errors["content"] ? true : false}
                              style={{ minHeight: "250px" }}
                            />
                            {errors["content"] && (
                              <FormText color="danger" size="md">
                                {errors["content"]}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="">
                        <div className="d-flex">
                          <Label for="showfor">Show For</Label>

                          <FormGroup className="ms-3">
                            <Input
                              className="me-2"
                              id="grantees"
                              type="checkbox"
                              color="primary"
                              name="grantees"
                              onChange={(event) =>
                                handleChange(event, "grantees")
                              }
                              checked={
                                fields["grantees"] == true ? true : false
                              }
                            />
                            <Label for="grantees" check>
                              Grantees
                            </Label>
                          </FormGroup>

                          <FormGroup className="ms-3">
                            <Input
                              className="me-2"
                              id="donors"
                              type="checkbox"
                              color="primary"
                              name="donors"
                              onChange={(event) =>
                                handleChange(event, "donors")
                              }
                              checked={fields["donors"] == true ? true : false}
                            />
                            <Label for="donors" check>
                              Donors
                            </Label>
                          </FormGroup>

                          <FormGroup className="ms-3">
                            <Input
                              className="me-2"
                              id="public"
                              type="checkbox"
                              color="primary"
                              name="public"
                              onChange={(event) =>
                                handleChange(event, "public")
                              }
                              checked={fields["public"] == true ? true : false}
                            />
                            <Label for="public" check>
                              Public
                            </Label>
                          </FormGroup>
                        </div>
                        {errors["grantees"] && (
                          <FormText color="danger" size="md">
                            {errors["grantees"]}
                          </FormText>
                        )}
                      </Row>
                      <Row>
                        <Col md={12} className="d-flex justify-content-end">
                          <Button
                            type="button"
                            size="md"
                            className="me-1 cancel_modal"
                            onClick={props.handleClose}
                            disabled={isloader}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="success"
                            className="submit-Id-button"
                            size="md"
                            type="submit"
                            disabled={isloader}
                          >
                            {isloader ? (
                              <Spinner size="sm" className="mx-1" />
                            ) : (
                              ""
                            )}

                            {props.faqId ? "Update" : "Add"}
                          </Button>
                        </Col>
                      </Row>
                    </LoadingOverlay>
                  </ModalBody>
                </Form>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddEditFaq;
