import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import moment from "moment";

const UserDetailsBody = (props) => {
  const [users, setUsers] = useState({});
  const [loader, setLoader] = useState(false);

  const getUser = (id) => {
    let params = id;
    setLoader(true);
    user
      .getOne(params)
      .then((res) => {
        if (res?.data?.status) {
          setUsers(res?.data?.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (props.usermodalId) {
      getUser(props.usermodalId);
    }
  }, []);
  return (
    <Modal isOpen={props.usermodalOpen} size="lg" className="details_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        {users?.first_name
          ? `${
              users?.first_name.charAt(0).toUpperCase() +
              users?.first_name?.slice(1)
            }'s Details`
          : ""}
      </ModalHeader>
      <ModalBody className="pl-4 pr-4">
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <hr />
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>First Name :</strong>{" "}
                {users?.first_name ? users?.first_name : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Last Name :</strong>{" "}
                {users?.last_name ? users.last_name : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Email :</strong> {users?.email ? users?.email : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Secondary Email 1:</strong>{" "}
                {users?.secondary_email_1 ? users?.secondary_email_1 : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Secondary Email 2:</strong>{" "}
                {users?.secondary_email_2 ? users?.secondary_email_2 : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Phone :</strong> {users?.phone ? users?.phone : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>DOB :</strong>{" "}
                {users?.dob ? moment(users?.dob).format("D MMM,  YYYY") : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Social security number :</strong>{" "}
                {users.social_security_number
                  ? users.social_security_number
                  : "N/A"}
              </div>
            </Col>
          </FormGroup>
        </LoadingOverlay>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" className="cancel_modal" onClick={props.handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserDetailsBody;
