import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import common from "../../../Services/common";
import dmf from "../../../Services/dmfsubmission";

const DmfDetailsBody = (props) => {
  const [fields, setFields] = useState({});
  const [interestAreas, setInterestAreas] = useState([]);
  const [loader, setLoader] = useState(false);
  LoadingOverlay.propTypes = undefined;

  const getDmfSubmission = () => {
    let params = props.donorId;
    setLoader(true);
    dmf
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setInterestAreas(res.data.data.interested_areas);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.donorId) {
      getDmfSubmission(props.donorId);
    }
  }, [props.donorId]);
  return (
    <Modal isOpen={props.openModal} size="lg" className="details_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        <h4 className="mb-0 title-font text-white" style={{ fontSize: "18px" }}>
          Recent DMF Donors
        </h4>
      </ModalHeader>
      <ModalBody className="pl-4 pr-4">
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <h5>Recent DMF Donor's Details</h5>
          <Row className="donor-card">
            <hr />
            {/* <Row className="dmf-card"> */}
            <FormGroup>
              <Row>
                <Col md={6}>
                  <div className="nomination-box">
                    <strong>First Name :</strong>{" "}
                    {fields?.first_name?.charAt(0)?.toUpperCase() +
                      fields?.first_name?.slice(1)}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    <strong>Last Name :</strong>{" "}
                    {fields?.last_name?.charAt(0)?.toUpperCase() +
                      fields?.last_name?.slice(1)}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <div className="nomination-box">
                    <strong>Email :</strong> {fields?.email}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    <strong>Phone :</strong> {fields?.phone}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <div className="nomination-box">
                    <strong>City :</strong> {fields?.city}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    <strong>State :</strong>{" "}
                    {fields?.state != null && fields?.state.name}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <hr />
            <div className="nomination-title py-2 px-2 mb-1">DMF Intake Form Details</div>
            <FormGroup>
              <Row>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Legal First Name :</strong>{" "}
                    {fields?.legal_first_name
                      ? fields?.legal_first_name?.charAt(0)?.toUpperCase() +
                        fields?.legal_first_name?.slice(1)
                      : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Legal Last Name :</strong>{" "}
                    {fields?.legal_last_name
                      ? fields?.legal_last_name?.charAt(0).toUpperCase() +
                        fields?.legal_last_name?.slice(1)
                      : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Street :</strong>{" "}
                    {fields.street != null ? fields.street : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>I am a(n) : </strong>{" "}
                    {fields.grantee_types != null
                      ? fields.grantee_types.category_name
                      : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Desired Fund Name :</strong>{" "}
                    {fields.desired_fund_name
                      ? fields.desired_fund_name
                      : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Initial Funding Amount (estimate) : </strong>
                    {fields.initial_funding_amount
                      ? `$${fields.initial_funding_amount}`
                      : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    <strong>
                      Would you like Donor Recognition or Anonymity? :
                    </strong>{" "}
                    {fields.recognition != null
                      ? fields.recognition.category_name
                      : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <div className="nomination-box">
                    <strong>
                      What grant areas are you interested in funding? :{" "}
                    </strong>
                    {interestAreas.map((res, index) => (
                      <>{(index ? ", " : "") + res.category_name}</>
                    ))}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <div className="nomination-box">
                    <strong>Contract Signature Status : </strong>

                    <Badge
                      color={common.getSignstatuscolor(
                        fields?.dmf_contract_status
                      )}
                    >
                      {fields?.dmf_contract_status?.replace("_", " ")}
                    </Badge>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <hr />
            <div className="nomination-title py-2 px-2 mb-1">
              Donors Spokes person Information{" "}
            </div>
            <FormGroup>
              <Row>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>First Name :</strong>{" "}
                    {fields.spoke_first_name
                      ? fields.spoke_first_name?.charAt(0)?.toUpperCase() +
                        fields?.spoke_first_name?.slice(1)
                      : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Last Name :</strong>{" "}
                    {fields.spoke_last_name
                      ? fields.spoke_last_name?.charAt(0)?.toUpperCase() +
                        fields?.spoke_last_name?.slice(1)
                      : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Email :</strong>{" "}
                    {fields.spoke_email ? fields.spoke_email : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Phone :</strong>{" "}
                    {fields.spoke_phone ? fields.spoke_phone : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>City :</strong>{" "}
                    {fields.spoke_city ? fields.spoke_city : "N/A"}
                  </div>
                </Col>
                <Col col={6}>
                  <div className="nomination-box">
                    {" "}
                    <strong>State :</strong>{" "}
                    {fields.spoke_state?.name
                      ? fields.spoke_state?.name
                      : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col col={12}>
                  <div className="nomination-box">
                    {" "}
                    <strong>Street :</strong>{" "}
                    {fields.spoke_street ? fields.spoke_street : "N/A"}
                  </div>
                </Col>
              </Row>
            </FormGroup>
          </Row>
        </LoadingOverlay>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className="cancel_modal"
          onClick={props.handleClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DmfDetailsBody;
