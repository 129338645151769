import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import common from "../../Services/common";
import dmf from "../../Services/dmfsubmission";

const DmfDetailsBody = (props) => {
  const [fields, setFields] = useState({});
  const [interestAreas, setInterestAreas] = useState([]);
  const [loader, setLoader] = useState(false);
  LoadingOverlay.propTypes = undefined;

  const getDmfSubmission = () => {
    let params = props.dmfId;
    setLoader(true);
    dmf
      .getOne(params)
      .then((res) => {
        if (res?.data?.status) {
          setFields(res?.data?.data);
          setInterestAreas(res?.data?.data?.interested_areas);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.dmfId) {
      getDmfSubmission(props.dmfId);
    }
  }, []);

  return (
    <Modal isOpen={props.opendetail} size="lg" className="details_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        {fields?.first_name
          ? `${
              fields?.first_name.charAt(0).toUpperCase() +
              fields?.first_name?.slice(1)
            }'s Details`
          : ""}
      </ModalHeader>
      <ModalBody className="pl-4 pr-4">
        <LoadingOverlay
          active={loader}
          spinner={
            <Spinner style={{ height: 60, width: 60, color: "#00344B" }} />
          }
          fadeSpeed={100}
          classNamePrefix="mitiz"
        >
          <hr />
          {/* <Row className="dmf-card"> */}
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>First Name :</strong>{" "}
                {fields?.first_name?.charAt(0).toUpperCase() +
                  fields?.first_name?.slice(1)}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Last Name :</strong>{" "}
                {fields?.last_name?.charAt(0).toUpperCase() +
                  fields?.last_name?.slice(1)}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                {" "}
                <strong>Preferred pronouns :</strong>{" "}
                {fields.prefix != null ? fields.prefix : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>Email :</strong> {fields?.email}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>Phone :</strong> {fields?.phone}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                <strong>City :</strong> {fields?.city}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                <strong>State :</strong>{" "}
                {fields?.state != null && fields?.state.name}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                {" "}
                <strong>Street :</strong>{" "}
                {fields.street != null ? fields.street : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                <strong>
                  What grant areas are you interested in funding? :{" "}
                </strong>
                {interestAreas.map((res, index) => (
                  <>{(index ? ", " : "") + res.category_name}</>
                ))}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <div className="nomination-box">
                <strong>Contract Signature Status : </strong>

                <Badge
                  color={common?.getSignstatuscolor(
                    fields?.dmf_contract_status
                  )}
                >
                  {fields?.dmf_contract_status?.replace("_", " ")}
                </Badge>
              </div>
            </Col>
          </FormGroup>
          <hr />
          <div className="nomination-title py-2 px-2 mb-3">
            DMF Intake Form Details{" "}
          </div>
          <FormGroup row>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Legal First Name :</strong>{" "}
                {fields?.legal_first_name
                  ? fields?.legal_first_name?.charAt(0).toUpperCase() +
                    fields?.legal_first_name?.slice(1)
                  : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Legal Last Name :</strong>{" "}
                {fields?.legal_last_name
                  ? fields?.legal_last_name?.charAt(0).toUpperCase() +
                    fields?.legal_last_name?.slice(1)
                  : "N/A"}
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Desired Fund Name :</strong>{" "}
                {fields.desired_fund_name ? fields.desired_fund_name : "N/A"}
              </div>
            </Col>
            <Col md={6}>
              <div className="nomination-box">
                {" "}
                <strong>Initial Funding Amount (estimate) : </strong>
                {fields.initial_funding_amount
                  ? `$${fields.initial_funding_amount}`
                  : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <div className="nomination-box">
                {" "}
                <strong>I am a(n) : </strong>{" "}
                {fields?.grantee_type?.category_name
                  ? fields?.grantee_type?.category_name
                  : "N/A"}
              </div>
            </Col>

            <Col col={6}>
              <div className="nomination-box">
                <strong>
                  Would you like Donor Recognition or Anonymity? :
                </strong>{" "}
                {fields.recognition != null
                  ? fields.recognition.category_name
                  : "N/A"}
              </div>
            </Col>
          </FormGroup>

          <hr />
          <div className="nomination-title py-2 px-2 mb-3">
            Donors Spokesperson Information{" "}
          </div>
          <FormGroup row>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>First Name :</strong>{" "}
                {fields.spoke_first_name
                  ? fields.spoke_first_name?.charAt(0).toUpperCase() +
                    fields?.spoke_first_name?.slice(1)
                  : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Last Name :</strong>{" "}
                {fields.spoke_last_name
                  ? fields.spoke_last_name?.charAt(0).toUpperCase() +
                    fields?.spoke_last_name?.slice(1)
                  : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Phone :</strong>{" "}
                {fields.spoke_phone ? fields.spoke_phone : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>Email :</strong>{" "}
                {fields.spoke_email ? fields.spoke_email : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>City :</strong>{" "}
                {fields.spoke_city ? fields.spoke_city : "N/A"}
              </div>
            </Col>
            <Col col={6}>
              <div className="nomination-box">
                {" "}
                <strong>State :</strong>{" "}
                {fields.spoke_state?.name ? fields.spoke_state?.name : "N/A"}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col col={12}>
              <div className="nomination-box">
                {" "}
                <strong>Street :</strong>{" "}
                {fields.spoke_street ? fields.spoke_street : "N/A"}
              </div>
            </Col>
          </FormGroup>
          {/* </Row> */}
        </LoadingOverlay>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" className="cancel_modal" onClick={props.handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DmfDetailsBody;
