import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import common from "../../Services/common";
import grantee from "../../Services/grantee";
const GranteeDetailsbody = (props) => {
  const [fields, setFields] = useState({});
  const [users, setUsers] = useState([]);
  const [grantees, setGrantees] = useState([]);
  const [nomination, setNomination] = useState({});
  const [loader, setLoader] = useState(false);

  const getGrantee = (id) => {
    let params = id;
    setLoader(true);
    grantee
      .getOne(params)
      .then((res) => {
        if (res.data.status) {
          setFields(res.data.data);
          setUsers(res.data.data.user);
          setGrantees(res.data.data);
          setNomination(res?.data?.data?.nomination_records);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.grantId) {
      getGrantee(props.grantId);
    }
  }, []);

  return (
    <div>
      <Modal isOpen={props.open} size="lg" className="details_modal">
        <ModalHeader toggle={() => props.handleClose()}>
          Grantee Details
        </ModalHeader>

        <ModalBody className="pl-4 pr-4">
          {loader ? (
            <div className="text-center">
              <Spinner style={{ width: 50, height: 50 }} />
            </div>
          ) : (
            <div>
              <div className="nomination-card">
                <div className="nomination-title py-2 px-2">Donor Details</div>
                <div className="px-2 pt-2">
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <div className="nomination-box">
                          <strong>First Name :</strong>{" "}
                          {users.first_name != null && users.first_name}
                        </div>
                      </Col>
                      <Col col={6}>
                        <div className="nomination-box">
                          <strong>Last Name :</strong>{" "}
                          {users.last_name != null && users.last_name}
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <div className="nomination-box">
                          <strong>Email :</strong> {users.email}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="nomination-box">
                          <strong>Phone : </strong> {users.phone}
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              </div>
              {nomination.length > 0 &&
                nomination.map((ele, index) => (
                  <div className="nomination-card">
                    <div className="nomination-title py-2 px-2">
                      {nomination.length > 1
                        ? `Nomination Details ${index + 1}`
                        : "Nomination Details"}
                    </div>
                    <div className="px-2 pt-2">
                      <FormGroup>
                        <Row key={`key-ele-${index}`}>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Name of Organization : </strong>
                              {ele?.org_name}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>City : </strong> {ele?.city}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>State : </strong>
                              {ele?.state?.name}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Street :</strong> {ele?.street}
                            </div>
                          </Col>
                        {fields?.is_prevetted ? (
                            <Col md={6}>
                              <div className="nomination-box">
                                <strong>Zip : </strong> {ele?.zip}
                              </div>
                            </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                          </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Organization's Website :</strong>{" "}
                              <a
                                href={ele?.org_website}
                                target="_blank"
                                rel="noreferrer"
                                className="text-decoration-none"
                              >
                                {ele?.org_website ? ele?.org_website : "N/A"}
                              </a>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Added on : </strong>{" "}
                              {moment(ele["created_at"]).format("D MMM,  YYYY")}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Grant Amount : </strong>$
                              {common.numberFormat(ele?.grant_amount)}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>EIN Number :</strong> {ele?.ein_number}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                      {fields?.is_prevetted ? (
                        <>
                          <FormGroup>
                          <Row>
                            <Col md={6}>
                              <div className="nomination-box">
                                <strong>Geography :</strong> {ele?.geography}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="nomination-box">
                                <strong>Priority Population : </strong>{" "}
                                {ele?.priority_population}
                              </div>
                            </Col>
                          </Row>
                          </FormGroup>

                          <FormGroup>
                           <Row>
                           <Col md={12}>
                              <div className="nomination-box">
                                <strong>Website :</strong>{" "}
                                <a
                                  href={ele?.org_website}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-decoration-none"
                                >
                                  {ele?.org_website}
                                </a>
                              </div>
                            </Col>
                           </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                            <Col md={12}>
                              <div className="nomination-box">
                                <strong>Mission :</strong>{" "}
                                {ele?.mission !== null ? ele?.mission : "N/A"}
                              </div>
                            </Col>
                            </Row>
                          </FormGroup>
                        </>
                      ) : (
                        ""
                      )}

                        <FormGroup>
                      <Row>
                          <Col md={12}>
                            <div className="nomination-box">
                              <strong>
                                Would you like Donor Recognition or Anonymity? :
                              </strong>{" "}
                              {ele?.recognition !== null
                                ? ele?.recognition.category_name
                                : "N/A"}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={12}>
                            <div className="nomination-box">
                              <strong>Organization Contact Name : </strong>{" "}
                              {ele?.org_contact_name
                                ? ele?.org_contact_name
                                : "N/A"}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Organization Email : </strong>
                              {ele?.org_contact_email
                                ? ele?.org_contact_email
                                : "N/A"}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="nomination-box">
                              <strong>Organization's Phone Number : </strong>{" "}
                              {ele?.org_phone ? ele?.org_phone : "N/A"}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                        <FormGroup>
                      <Row>
                          <Col md={12}>
                            <div className="nomination-box">
                              <strong>
                                Is this for general operating support or project
                                specific support? :{" "}
                              </strong>
                              {ele?.support_queries
                                ? ele?.support_queries
                                : "Operating Support"}
                            </div>
                          </Col>
                      </Row>
                        </FormGroup>
                    </div>
                  </div>
                ))}

              <div className="nomination-card">
                <div className="nomination-title py-2 px-2">Remarks</div>

                <div className="p-2 nomination-box">
                  <div className="nomination-box">
                    {fields?.additional_queries
                      ? fields?.additional_queries
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel_modal" onClick={props.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GranteeDetailsbody;
