import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import common from "../../Services/common";
import nominee from "../../Services/nominee";

const NominationDetailsBody = (props) => {
  console.log(props);
  const [fields, setFields] = useState({});
  const [users, setUsers] = useState([]);
  const [nomination, setNomination] = useState({});
  const [loader, setLoader] = useState(false);
  const [isloader, setIsLoader] = useState(false);

  const getNomination = (id) => {
    let params = id;
    setLoader(true);
    setIsLoader(true);
    nominee
      .getOne(params)
      .then((res) => {
        if (res?.data?.status) {
          setFields(res?.data?.data);
          setUsers(res?.data?.data?.user);
          setNomination(res?.data?.data?.nomination_records);
          setLoader(false);
          setIsLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        setIsLoader(false);

        console.log("error", error);
      });
  };
  
  useEffect(() => {
    if (props.nominationId) {
      getNomination(props.nominationId);
    }
  }, []);

  return (
    <Modal isOpen={props.open} size="lg" className="details_modal">
      <ModalHeader toggle={() => props.handleClose()}>
        {props.tabChange == 1
          ? "Grant Nominations Details"
          : " Grant Submissions Details"}
      </ModalHeader>

      <ModalBody className="pl-4 pr-4">
        {loader ? (
          <div className="text-center">
            <Spinner style={{ width: 50, height: 50 }} />
          </div>
        ) : (
          <div>
            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">Donor Details</div>
              <div className="pt-2">
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>First Name :</strong>{" "}
                        {users?.first_name ? users?.first_name : "N/A"}
                      </div>
                    </Col>
                    <Col col={6}>
                      <div className="nomination-box">
                        <strong>Last Name :</strong>{" "}
                        {users?.last_name ? users?.last_name : "N/A"}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="ms-2 me-2">
                  <Row>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Email :</strong>{" "}
                        {users?.email ? users?.email : "N/A"}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="nomination-box">
                        <strong>Phone : </strong>{" "}
                        {users?.phone ? users?.phone : "N/A"}
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </div>
            {nomination?.length > 0 &&
              nomination?.map((ele, index) => (
                <div className="nomination-card">
                  <div className="nomination-title py-2 px-2">
                    {nomination.length > 1
                      ? `Nomination Details ${index + 1}`
                      : "Nomination Details"}
                  </div>
                  <div className="pt-2">
                    <FormGroup className="ms-2 me-2">
                      <Row key={`key-ele-${index}`}>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Name of Organization : </strong>
                            {ele?.org_name ? ele?.org_name : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Organization's Website :</strong>{" "}
                            <a
                              href={ele?.org_website}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              {ele?.org_website ? ele?.org_website : "N/A"}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row key={`key-ele-${index}`}>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Geography : </strong>
                            {ele?.geography ? ele?.geography : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Priority Population : </strong>{" "}
                            {ele?.priority_population
                              ? ele?.priority_population
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Mission : </strong>
                            {ele?.mission ? ele?.mission : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>City : </strong>{" "}
                            {ele?.city ? ele?.city : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>State : </strong>
                            {ele?.state?.name ? ele?.state?.name : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Street :</strong>{" "}
                            {ele?.street ? ele?.street : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>

                   <Row>
                    <Col md={6}><FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Zip :</strong> {ele?.zip ? ele?.zip : "N/A"}
                          </div>
                        </Col>

                        
                      </Row>
                    </FormGroup>
                    
                    <FormGroup className="ms-2 me-2">
                      <Row>
                      <Col md={12}>
                          <div className="nomination-box">
                            <strong>Added on : </strong>{" "}
                            {moment(ele["created_at"]).format("D MMM,  YYYY")}
                          </div>
                        </Col>

                        
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row> 
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Grant Amount : </strong>$
                            {common.numberFormat(
                              ele?.grant_amount ? ele?.grant_amount : "N/A"
                            )}
                          </div>
                        </Col>
                        
                        
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row> 
                      <Col md={12}>
                          <div className="nomination-box">
                            <strong>EIN Number :</strong>{" "}
                            {ele?.ein_number ? ele?.ein_number : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup></Col>
                    <Col md={6}>
                      <img src={ele?.logo} alt="Seeding justice" style={{width:"70%", borderRadius:"2px"}}/>
                    </Col>
                   </Row>
                    
                    



                    {nomination?.is_prevetted ? (
                      <>
                        <FormGroup className="ms-2 me-2">
                          <Row>
                            <Col md={6}>
                              <div className="nomination-box">
                                <strong>Geography :</strong>{" "}
                                {ele?.geography ? ele?.geography : "N/A"}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="nomination-box">
                                <strong>Priority Population : </strong>{" "}
                                {ele?.priority_population
                                  ? ele?.priority_population
                                  : "N/A"}
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup className="ms-2 me-2">
                          <Row>
                            <Col md={12}>
                              <div className="nomination-box">
                                <strong>Website :</strong>{" "}
                                <a
                                  href={ele?.org_website}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-decoration-none"
                                >
                                  {ele?.org_website ? ele?.org_website : "N/A"}
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup className="ms-2 me-2">
                          <Row>
                            <Col md={12}>
                              <div className="nomination-box">
                                <strong>Mission :</strong>{" "}
                                {ele?.mission !== null ? ele?.mission : "N/A"}
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    ) : (
                      ""
                    )}

                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>
                              Would you like Donor Recognition or Anonymity? :
                            </strong>{" "}
                            {ele?.recognition !== null
                              ? ele?.recognition.category_name
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>Organization Contact Name : </strong>{" "}
                            {ele?.org_contact_name
                              ? ele?.org_contact_name
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Organization Email : </strong>
                            {ele?.org_contact_email
                              ? ele?.org_contact_email
                              : "N/A"}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="nomination-box">
                            <strong>Organization's Phone Number : </strong>{" "}
                            {ele?.org_phone ? ele?.org_phone : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="ms-2 me-2">
                      <Row>
                        <Col md={12}>
                          <div className="nomination-box">
                            <strong>
                              {ele?.support_queries &&
                              ele?.support_queries?.length > 0
                                ? "Is this for general operating support or project specific support?"
                                : "Is this for general operating support or project specific support?"}{" "}
                              :{" "}
                            </strong>

                            {ele?.support_queries
                              ? ele?.support_queries
                              : "Operating support"}
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </div>
              ))}

            <div className="nomination-card">
              <div className="nomination-title py-2 px-2">Anything else you would like us to know?</div>

              <div className="p-2 nomination-box">
                <div className="nomination-box">
                  {fields?.additional_queries
                    ? fields?.additional_queries
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button className="cancel_modal" size="lg" onClick={props.handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
  
export default NominationDetailsBody;
