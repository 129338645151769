import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Row,
  FormGroup,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
  Container,
  CardBody,
} from "reactstrap";
import { Helmet } from "react-helmet";
import user from "../../Services/user";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import common from "../../Services/common";
const UpdatePassword = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [singleError, setSingleError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.password) {
      errors["password"] = "Password can not be empty";
      formIsValid = false;
    } else if (fields.password.length < 8) {
      errors["password"] = "Password must be 8 character";
      formIsValid = false;
    } else if (fields.password_confirmation !== fields.password) {
      errors["password_confirmation"] = "Confirm password did not match";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      setLoader(true);
      user
        .changepwd(params)
        .then((res) => {
          setLoader(true);
          if (res.data.status) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoader(false);
            setFields({});
            setErrors({});
            setSingleError("");
          } else {
            if (common.isJson(res.data.message)) {
              let errors = {};
              for (let key in res.data.message) {
                errors[key] = res.data.message[key];
              }
              setErrors(errors);
            } else {
              setSingleError(res.data.message);
            }
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setErrors(errors);
          setLoader(false);
        });
    }
  };

  const toggle = (action) => {
    if (action == 1) {
      setVisible(!isVisible);
    }
    if (action == 2) {
      setIsPasswordVisible(!isPasswordVisible);
    }
    if (action == 3) {
      setIsConfirmPassVisible(!isConfirmPassVisible);
    }
  };

  return (
    <>
      <Helmet>
        <title>Change Password : seeding-Justice</title>
      </Helmet>

      <section className="homepage_banner secondary-homepage_banner">
        <Container>
          <Row>
            <Col className="col-md-6 offset-md-3">
              <Card className="login login_bg">
                <CardBody>
                  <div className="text-center">
                    <h1>Change Password</h1>
                    <hr />
                  </div>

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="form-group">
                          {/* <Label for="old_password">Old Password</Label> */}
                          <Input
                            id="old_password"
                            name="old_password"
                            placeholder="Enter old password"
                            type={!isPasswordVisible ? "password" : "text"}
                            value={
                              fields["old_password"]
                                ? fields["old_password"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "old_password")
                            }
                            invalid={errors["old_password"] ? true : false}
                          />
                          <span onClick={() => toggle(2)}>
                            {isPasswordVisible ? (
                              <RiEyeFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <RiEyeOffFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </span>
                          <FormFeedback>{errors["old_password"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-group">
                          {/* <Label for="password">New Password</Label> */}
                          <Input
                            id="password"
                            name="password"
                            placeholder="Enter new password"
                            type={!isVisible ? "password" : "text"}
                            value={fields["password"] ? fields["password"] : ""}
                            onChange={(event) =>
                              handleChange(event, "password")
                            }
                            invalid={errors["password"] ? true : false}
                          />
                          <span onClick={() => toggle(1)}>
                            {isVisible ? (
                              <RiEyeFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <RiEyeOffFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </span>
                          <FormFeedback>{errors["password"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-group">
                          {/* <Label for="password_confirmation">Confirm Password</Label> */}
                          <Input
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder="Enter confirm password"
                            type={!isConfirmPassVisible ? "password" : "text"}
                            value={
                              fields["password_confirmation"]
                                ? fields["password_confirmation"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "password_confirmation")
                            }
                            invalid={
                              errors["password_confirmation"] ? true : false
                            }
                          />
                          <span onClick={() => toggle(3)}>
                            {isConfirmPassVisible ? (
                              <RiEyeFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <RiEyeOffFill
                                className="text-dark field-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </span>
                          <FormFeedback>
                            {errors["password_confirmation"]}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      {singleError !== "" ? (
                        <p className="text-danger text-center mb-1">
                          {singleError}
                        </p>
                      ) : (
                        ""
                      )}
                      <Col md={12}>
                        <Button
                          color="success"
                          size="md"
                          type="submit"
                          className="submit-button"
                          disabled={loader}
                        >
                          {loader ? <Spinner size="sm" className="mx-1" /> : ""}
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UpdatePassword;
