import http from "./http";

const userdashboard = {
  listsjgrantees: (param) =>
    http.get("/user/prevetted-grantees/list?q=all&status=1", { params: param }),
  org_list: (param) =>
    http.get("/user/prevetted-grantees/list?q=search-list&sort=org_name&direction=asc", { params: param }),

  reportList: (param) => http.get("/user/quarterly-report", { params: param }),
  getOne: (param) => http.get(`/user/nomination/${param}`),
  getTransactionexcel: (param) =>
    http.get("/user/donor-transactions/export", {
      params: param,
      responseType: "blob",
    }),

  //Bloomerang http.get(`/user/donor-transactions?skip=${param.skip}`, {params: param.params}
  donorTransactions: (param) =>
    http.get(`/user/donor-transactions?skip=${param.skip}`, {params: param.params}),
  donorTransactions: (param) =>
  http.get("/user/donor-transactions",{params:param}),



  transactionsDashboard: (param) =>
    http.get("/user/donor-transactions/details", { params: param }),

  download: (param) =>
    http.get(`/user/quarterly-report/download/${param}`, {
      responseType: "blob",
    }),

  getContribution: (param) =>
    http.get("user/profile/make-contributions", { params: param }),
};

export default userdashboard;
